export const en = {
    "contact": "Contact",
    "callhistory": "Call History",
    "message": "Message",
    "chat": "Chat",
    "settings": "Settings",
    "searchcontact": "Search Contacts",
    "searchmessages": "Search Messages",
    "searchchats": "Search Chats",
    "addNewContact": "Add New Contact",
    "applicationSetting": "Application Settings",
    "language": "Language",
    "testConnection": "Test Connection",
    "debuggingLogs": "Debugging Logs",
    "reRegister": "Re-register",
    "privacyPolicy": "Privacy Policy",
    "about": "About",
    "Logout": "Logout",
    "logoutTitle": "Logout",
    "LogoutConfirmMsg": "Are you sure you want to logout?",
    "cancellogout": "Cancel",
    "confirmlogout": "Confirm",
    "contact1": "Contact",
    "addNewContact1": "Add New Contact",
    "firstname": "First name",
    "firstnameplaceholder": "Enter first name",
    "lastname": "Last name",
    "lastnameplaceholder": "Enter last name",
    "displayname": "Display name",
    "displaynameplaceholder": "Enter display name",
    "extensionNumber": "Extension number",
    "extensionnumberplaceholder1": "Enter extension number",
    "phoneNumber": "Phone number",
    "phoneNumberPlaceholder": "Enter phone number",
    "cellNumber": "Cell number",
    "cellNumberPlaceholder": "Enter cell number",
    "email": "Email",
    "emailPlaceholder": "Enter email",
    "save": "Save",
    "cancel": "Cancel",
    "smslist": "Select your SMS List",
    "addnewmsg": "Compose a Message",
    "addnewchat": "Add New Chat",
    "addnewmsg1": "New Message",
    "msgnote": "Note: For text message, maximum 160 characters are allowed. For media attachment, supported Formats are: GIF,JPG,JPEG,PNG,MP3,MP4,VCF,TXT and maximum file size : 1MB",
    "msgplaceholder": "Message",
    "addnewchat1": "Add New Chat",
    "chatnote": "Note: Maximum 1200 character allow For chat",
    "chatplaceholder": "Message",
    "deletethread": "Delete Thread",
    "msgnote1": "Note: For text message, maximum 160 characters are allowed. For media attachment, supported Formats are: GIF,JPG,JPEG,PNG,MP3,MP4,VCF,TXT and maximum file size : 1MB",
    "msgscreenplaceholder": "Message",
    "chatnote1": "Note: Maximum 1200 character allow For chat",
    "chatscreenplaceholder": "Message",
    "accountsetting": "Account Setting",
    "audio": "Audio",
    "audiocodec": "Audio codec",
    "video": "Video",
    "videocodec": "Video codec",
    "sip": "SIP",
    "sipusername": "SIP username",
    "sipprotocol": "SIP protocol",
    "sipport": "SIP port",
    "proxysserver": "Proxy server",
    "proxyport": "Proxy port",
    "callerid": "Caller ID",
    "chooselanguage": "Choose Language",
    "aboutTitle": "About",
    "edit": "Edit",
    "addtocontact": "Add to contact",
    "privacyPolicyTitle": "Privacy Policy",
    "reloadconf": "Re-register",
    "editcontactlabel": "Edit Contact",
    "to": "To",
    "copy": "Copy",
    "forward": "Forward",
    "delete": "Delete",
    "selectall": "Select All",
    "testConnection1": "Test Connection",
    "onhold": "On Hold",
    "ongoingcall": "Ongoing Call",
    "mute": "Mute",
    "unmute": "Unmute",
    "hold": "Hold",
    "unhold": "Unhold",
    "transfer": "Transfer",
    "blindtransfer": "Blind Transfer",
    "attendanttransfer": "Attendant Transfer",
    "hangup": "Hangup",
    "connecting": "Connecting",
    "incomingcallfrom": "Incoming call from",
    "callhistory1": "Call History",
    "callhistory2": "Call History",
    "ongoingcallconference": "Ongoing Conference Call",
    "enterpriseContact": "Enterprise Contact",
    "googleContact": "Google Contact",
    "outlookContact": "Outlook Contact",
    "googleContact1": "Google Contact",
    "enterpriseContact1": "Enterprise Contact",
    "outlookContact1": "Outlook Contact",
    "message1": "Message",
    "chat1": "Chat",
    "settings1": "Settings",
    "googleContactExist": "Google Contact doesn't exists.",
    "outlookContactExist1": "Outlook Contact doesn't exists.",
    "syncDescription": "There are no any contacts sync from<br />Your account, please sync your contacts",
    "syncContactButton": "Sync Contact",
    "desktopApp": "Desktop App",
    "otherDevices": "Other Devices",

    "version": "Version v",
    "loginToVoizcall": "Login to Voizcall",
    "loginWith": "Login with username and password",

    "debuggingLogs1": "Debugging Logs",
    "debugLogsContent": "This will allow user to download or clear all your captured logs From the storage.",
    "clearLogs": "Clear Logs",
    "downloadLogs": "Download Logs",
    "wsTest": "Websocket Test",
    "wsConnection": "Connection",
    "wsActionConnecting": "Connecting to web socket",
    "wsActionConnected": "Connected",
    "c": "Failed",
    "wsStatusConnecting": "Trying to connect",
    "wsStatusConnected": "Connected",
    "wsStatusFailed": "Failed",

    "play": "Play",
    "pause": "Pause",

    "modalcancelbutton": "Cancel",
    "modalconfirmbutton": "Confirm",

    "audio-codec": "Audio Codec",
    "video-codec": "Video Codec",

    "enablevideo": "Enable Video",
    "enabledvideostatus": "Enabled",
    "disabledvideostatus": "Disabled",

    "enableselfview": "Self View",
    "enabledselfviewstatus": "Enabled",
    "disabledselfviewstatus": "Disabled",

    "call": "Call",
    "dtmfTypeTitle": "DTMF Type",
    "dtmfType": "DTMF Type",

    "noAnswerTimeoutTitle": "No Answer Timeout",
    "noAnswerTimeout": "No Answer Timeout",

    "interval": "Register Expires",
    "interval": "Register Expires",

    "outboundCallerIdNameTitle": "Outbound Caller ID Name",

    "earlyMediaTitle": "Early Media",
    "enabledearlymediastatus": "Enabled",
    "disabledearlymediastatus": "Disabled",

    "extraHeaderTitle": "Extra Headers",

    "encryptionTitle": "Encryption",
    "encryption": "Encryption",

    "1TouchVoicemailTitle": "One Touch Voicemail Number",
    "oneTouchVoicemailMenu": "One Touch Voicemail",

    "allowHoldTitle": "Allow Hold Controls",
    "enabledallowholdstatus": "Enabled",
    "disabledallowholdstatus": "Disabled",

    "allowTransferTitle": "Allow Transfer Controls",
    "enabledallowtransferstatus": "Enabled",
    "disabledallowtransferstatus": "Disabled",

    "respectServerExpiresTitle": "Respect Server Expires",
    "enabledrespectserverexpiresstatus": "Enabled",
    "disabledrespectserverexpiresstatus": "Disabled",

    "registerExpiresTitle": "Register Expires",
    "registerExpires": "Register Expires",

    "sms": "SMS",
    "smsStatusTitle": "Enable SMS",
    "enabledsmsstatus": "Enabled",
    "disabledsmsstatus": "Disabled",

    "downloadConfiguration": "Download Latest Configuration",
    "clearhistory": "Clear History",
    "callHistoryModalConfirmMsg": "Are you sure you want to clear call history ?",

    "chatSetting": "Chat Setting",
    "chatSettings": "Chat Settings",
    "takePhoto": "Take Photo",
    "photosGallery": "Photos Gallery",
    "deleteGallery": "Delete Photo",
    "importChats": "Import Chats",
    "exportChats": "Export Chats",
    "online": "Online",
    "offline": "Offline",
    "busy": "Busy",
    "dnd": "DND",
    "away": "Away",
    "custom": "Custom",
    "newChat": "New Chat",
    "groupChat": "Group Chat",
    "confirm": "Confirm",
    "addChat": "Add Chat",

    "windowsRagistry": "Click2Call Windows Registry",

    "instantMessage": "Instant Message",
    "imStatusTitle": "IM Status",
    "enabledimstatus": "Enabled",
    "disabledimstatus": "Disabled",
    "exportChat": "Export chat",
    "btnExport": "Export",
    "exportDone": "Exported successfully",
    "browseAndImportChat": "Browse and import chat",
    "btnImport": "Import",
    "forwardChat": "Forward Chat",
    "createGroup": "Create Group",
    "groupName": "Group Name",
    "newChatAdd": "New Chat",
    "contactAdd": "Contact",
    "clearChat": "Clear Chat",
    "userInfo": "User Info",
    "clearChatUserInfo": "Clear Chat",
    "allSharedMedia": "All Shared Media",
    "importChatsLabel": "Import Chats",
    "exportChatsLabel": "Export Chats",
    "groupInfo": "Group Info",
    "exitGroup": "Exit Group",

    "getStarted": "Get Started!",
    "welcomemsg": "Level-up your unified communications with our<br> VoIP softphone",
    "callLabel": "Call",
    "newChatMessageLabel": "New Chat Message",

    "allContact": "All Contacts",
    "allContact1": "All Contacts",
    "allContact2": "All Contacts",
    "messageLabel": "Messages",
    "chats": "Chats",
    "newChatMessageLabel": "New Chat Message",
    "newChatMessageLabel1": "New Chat Message",
    "createGroupChat": "Create Group Chat",
    "noContactFound": "No Contacts Found",
    "noContactFound1": "No Contacts Found",
    "loading": "Loading..",
    "callshistory": "Calls History",
    "allCalls": "All Calls",
    "allCallsTitle": "All Calls",
    "missedcalls": "Missed Calls",
    "missedcallsTitle": "Missed Calls",

    "detailPageApplicationSetting": "Application Settings",
    "autoAnswer": "Auto Answer",
    "account": "Account",
    "dnd": "DND",

    "_autoAnswerdisabled": "Disabled",
    "_autoAnswerenabled": "Enabled",

    "_accountdisabled": "Disabled",
    "_accountenabled": "Enabled",

    "_dnddisabled": "Disabled",
    "_dndenabled": "Enabled",
    "complete": "Complete",

    "searchCallHistory": "Search Call History",
    "noLogsFound": "No Logs Found",
    "searchMissedcallHistory": "Search Missedcall History",
    "noMissedCallFound": "No Missed Calls Found",
    "clearMissedCallDescription": "Are you sure you want to clear <br /> Missedcall call history ?"

};
