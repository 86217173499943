import $ from "jquery";
// import { en } from "../locales/en";
// import { it } from "../locales/it";
// /*Language change code starts*/

let i18n;
let _geti18nLabel = (_key) => {
    return i18n[_key] != '' ? i18n[_key] : _key;
};
export const translateKey = (_key) => {
    return i18n[_key] != '' ? i18n[_key] : '';
};

let _renderLabels = () => {
    let _i18nClasses = $(
        "*[class^='__MSG__'],*[class*=' __MSG__'],*[class^='__MSGTITLE__'],*[class*=' __MSGTITLE__'],*[class^='__MSGTOGGLE'],*[class*=' __MSGTOGGLE']"
    );
    $.each(_i18nClasses, (i, element) => {
        let _classNameList = $(element).attr("class").split(" ");
        $.each(_classNameList, (j, _class) => {
            if (_class.startsWith("__MSG__")) {
                _renderi18nLabel(_class);
            } else if (_class.startsWith("__MSGTITLE__")) {
                const _key = _class.replace("__MSGTITLE__", "");
                const _classElement = $("." + _class)[0];
                $(_classElement).attr("title", _geti18nLabel(_key));
            }
        });
    });
};



export const _changePlatformLanguage = () => {
    const _lang = getLanguages();
    if (![undefined, 'undefined', '', null].includes(_lang)) {
        i18n = require(`../locales/${_lang}.js`)[_lang];
        _renderLabels()
    } else {
        localStorage.setItem('lang', 'en')
    }

};

let getLanguages = () => {
    let getSelectedLang = localStorage.getItem("lang");
    let langCode = getSelectedLang != "" ? getSelectedLang : 'en';
    return langCode;
};

// let loadScriptFile = (jsFile) => {
//     let i18nSrc = document.createElement("script");
//     i18nSrc.setAttribute("src", "public/locales/" + jsFile + ".js");
//     i18nSrc.setAttribute("type", "text/javascript");
//     i18nSrc.onload = () => {
//         _renderLabels();
//     };
//     document.getElementsByTagName("head")[0].appendChild(i18nSrc);
// };

let _renderi18nLabel = (_class) => {
    const _key = _class.replace("__MSG__", "");
    const _classElement = $("." + _class)[0];
    const _placeHolderElement = $("input").hasClass(_class);
    if ($("." + _class).attr("title")) {
        $("." + _class).attr("title", _geti18nLabel(_key));
    } else {
        if (_placeHolderElement === true) {
            // this for registrationa and unregistration toggle switch
            if (_key === "Regis") {
                $("#mrgrig").find(".toggle-on").html(_geti18nLabel("RegisOn"));
                $("#mrgrig").find(".toggle-off").html(_geti18nLabel("RegisOff"));
            } else {
                $("." + _class).attr("placeholder", _geti18nLabel(_key));
            }
        }
        if (![undefined, '', null].includes(_geti18nLabel(_key))) {
            $(_classElement).html(_geti18nLabel(_key));
        } else {

            $(_classElement).html(_classElement.innerText)
        }

    }
};



// /*Language change code ends*/