import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SidebarTab, openSidebar, toggleConnectingCall, toggleSidebar } from '../../store/themeSlice'
import { getConfigSettings } from '../../data/userSettings'
import usePhonedial from '../../hooks/usePhonedial'
import { number } from 'yup'

const SettingItembutton = ({ element }) => {
    const { Sidebar } = useSelector((state) => state.theme)
    const dispatch = useDispatch()
    const OneTouchVoiemailNumber = getConfigSettings('call.onetouchvoicemailnumber');
    const [dialPhone] = usePhonedial();

    const clickHandler = () => {
        switch (element.transKey) {
            case 'oneTouchVoicemailMenu':
                if (![null, undefined, ''].includes(OneTouchVoiemailNumber)) {
                    dialPhone(OneTouchVoiemailNumber)
                } else {
                    dialPhone('*51001');
                }
                dispatch(toggleConnectingCall(true))
                return dispatch(openSidebar({ tab: SidebarTab.home }))
            default:
                dispatch(openSidebar({ tab: SidebarTab.settings, key: element.transKey }))
                dispatch(toggleSidebar(true))
                return
        }

    }

    return (
        <>
            <button
                key={element.name.replaceAll(" ", '') + 'fghkfncjbnvgj'}
                onClick={clickHandler}

                className={`nav-link mb-4 ` + (element.name == Sidebar.openId ? 'active' : '')}
                role="tab"
                aria-controls="v-pills-application-setting" aria-selected="true">
                {element.icon}
                <span className={`__MSG__${element.transKey}`}>{element.name}</span>
            </button>
        </>
    )
}

export default SettingItembutton