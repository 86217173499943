
import React, { Suspense } from 'react';


import { SiGoogletranslate } from 'react-icons/si'
import { FaUserCog, FaInfoCircle } from 'react-icons/fa'
import { IoSpeedometer } from "react-icons/io5";
import { TbPlugConnected } from "react-icons/tb";
import { RiPagesLine } from "react-icons/ri";
import { BiReset } from "react-icons/bi";
import { MdDownloadForOffline, MdVoicemail } from "react-icons/md";
// import ApplicationSettings from '../component/Settings/ApplicationSettings';
import { v4 as uuidv4 } from 'uuid';
import FullpageLoad from '../component/Loadding/FullpageLoad';
import { BsChatText } from 'react-icons/bs';
import { getConfigSettings } from './userSettings';

const ApplicationSettings = React.lazy(() => import('../component/Settings/ApplicationSettings')); // Lazy-loaded
const Language = React.lazy(() => import('../component/Settings/Language'));
const TestConnection = React.lazy(() => import('../component/Settings/TestConnection'));
const DebuggingLogs = React.lazy(() => import('../component/Settings/DebuggingLogs'));
const ReloadConfiguration = React.lazy(() => import('../component/Settings/ReloadConfiguration'));
const PrivacyPolicy = React.lazy(() => import('../component/Settings/PrivacyPolicy'));
const AboutUs = React.lazy(() => import('../component/Settings/AboutUs'));
const ChatSetting = React.lazy(() => import('../component/Settings/ChatSetting'));





export const getSettingSidebarItem = () => {
    let sms_service_status = getConfigSettings('sms.enablesms');
    let im_service_status = getConfigSettings('im.enableim');

    const settings = [
        {
            "transKey": 'applicationSetting', //transKey used for transalte
            "name": "Application Settings",
            "icon": <FaUserCog className='sidebar_icon' />,
            "component": <Suspense fallback={<FullpageLoad />}><ApplicationSettings key="214230147021054021357401236262" /></Suspense>,
            "title": "Application Settings",
            "title_transkey": "detailPageApplicationSetting",
            'type': 'component',
            "visible": true,
        },
        {
            "transKey": 'chatSetting', //transKey used for transalte
            "name": "Chat Setting",
            "icon": <BsChatText className='sidebar_icon' />,
            "component": <Suspense fallback={<FullpageLoad />}><ChatSetting /></Suspense>,
            "title": "Chat Settings",
            "title_transkey": "chatSettings",
            'type': 'component',
            "visible": ([1, '1', true, 'true', 'TRUE'].includes(im_service_status)) ? true : false,
        },
        {
            "transKey": "language",
            "name": "Choose Language",
            "icon": <SiGoogletranslate className='sidebar_icon' />,
            "title": "Choose Language",
            "title_transkey": "chooselanguage",
            "component": <Suspense fallback={<FullpageLoad />}><Language key={uuidv4()} /></Suspense>,
            'type': 'component',
            "visible": true,
        },
        {
            "transKey": "testConnection",
            "name": "Test Connection",
            "icon": <IoSpeedometer className='sidebar_icon' />,
            "title": "Test Connection",
            "title_transkey": "testConnection1",
            "component": <Suspense fallback={<FullpageLoad />}><TestConnection key={uuidv4()} /></Suspense>,
            'type': 'component',
            "visible": true,

        },
        {
            "transKey": "debuggingLogs",
            "name": "Debugging Logs",
            "icon": <TbPlugConnected className='sidebar_icon' />,
            "title": "Debugging Logs",
            "title_transkey": "debuggingLogs1",
            "component": <Suspense fallback={<FullpageLoad />}><DebuggingLogs key={uuidv4()} /></Suspense>,
            'type': 'component',
            "visible": true,
        },
        {
            "transKey": "reRegister",
            "name": " Re-register",
            "icon": <BiReset className='sidebar_icon' />,
            "title": " Re-register",
            "title_transkey": "reloadconf",
            "component": <Suspense fallback={<FullpageLoad />}><ReloadConfiguration key={uuidv4().replaceAll('-', '')} /></Suspense>,
            'type': 'component',
            "visible": true,
        },
        {
            "transKey": "oneTouchVoicemailMenu",
            "name": "One Touch Voicemail",
            "icon": <MdVoicemail className='sidebar_icon' />,
            "title": "One Touch Voicemail",
            "title_transkey": "",
            "component": <h1>One touch valicemail</h1>,
            'type': 'action',
            "visible": true,
        },

        {
            "transKey": "privacyPolicy",
            "name": "Privacy Policy",
            "icon": <RiPagesLine className='sidebar_icon' />,
            "title": "Privacy Policy",
            "title_transkey": "privacyPolicyTitle",
            "component": <Suspense fallback={<FullpageLoad />}><PrivacyPolicy key={uuidv4()} /></Suspense>,
            'type': 'component',
            "visible": true,
        },
        {
            "transKey": "about",
            "name": "About Us",
            "icon": <FaInfoCircle className='sidebar_icon' />,
            "title": "About Us",
            "title_transkey": "aboutTitle",
            "component": <Suspense fallback={<FullpageLoad />}><AboutUs key={uuidv4()} /></Suspense>,
            'type': 'component',
            "visible": true,
        }

    ];
    return settings;
}

