
import { createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../utils/Axios";
import { getCallHistory } from "./contactSlice";

let tocken = localStorage.getItem('access_token');
let user_id = localStorage.getItem('user_id');
let callHistory = JSON.parse(localStorage.getItem('callhistory'))
export const getEnterPriseContact = createAsyncThunk(
    'directory/getConatct',
    async (access_token = null, { rejectWithValue }) => {
        try {
            if (access_token == null) {
                access_token = tocken
            }
            let res = await Axios.get('/directory/get-enterprise_directory-detail', {
                headers: {
                    'Authorization': `Barear ${access_token}`
                }
            });
            return res.data;
        } catch (error) {
            throw rejectWithValue(error.message)
        }
    });

export const call_cdr_sync = createAsyncThunk('call_cdr_sync', async (_, { rejectWithValue }) => {
    try {

        const user_id = localStorage.getItem('user_id')
        const callhistory = JSON.parse(localStorage.getItem('callhistory'))
        // console.debug('callhistory', callhistory)
        let userHistory = [];
        if (![null, '', undefined].includes(callhistory)) {
            userHistory = callHistory.filter((history) => history.user_id == user_id)
        }
        let res = {
            status: 'SUCCESS',
            data: userHistory
        }
        return res
    } catch (error) {
        throw rejectWithValue(error.message)
    }
})

export const cleare_call_history = createAsyncThunk('cdr/cleare', async (_, { rejectWithValue }) => {
    try {
        //let res = await Axios.post('/cdr/delete', {});
        let user_id = localStorage.getItem('user_id');
        const callhistory = JSON.parse(localStorage.getItem('callhistory'))
        let his = [];
        if (![null, '', undefined].includes(callhistory)) {
            let clearehistory = callHistory.filter((history) => history.user_id != user_id);
            localStorage.setItem('callhistory', JSON.stringify(clearehistory));
            his = clearehistory;
        }

        let data = { status: "SUCCESS", 'callhistory': his }
        console.debug('cleare_call_history', data)
        return data;
    } catch (error) {
        throw rejectWithValue(error.message)
    }
})


