import React, { useEffect, useState } from 'react'
import { FaPowerOff } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { SidebarTab, openSidebar, toggleSidebar } from '../../store/themeSlice'
import { Modal } from 'react-bootstrap'
import LogoutConfirmImg from '../../assets/app-icon/icon/logout-confirm.svg'
import { autlogout } from '../../store/Auth/authService'
import useDisconnectUa from '../../hooks/useDisconnectUa'
import { _changePlatformLanguage, translateKey } from '../../utils/Locale'
import SettingSidebarMenu from './SettingSidebarMenu'
import LoaddingSpinner from '../Loadding/LoaddingSpinner'
import { clearHistory } from '../../store/Contacts/contactSlice'
const SettingsSidebar = () => {
    const { Sidebar } = useSelector((state) => state.theme)
    const { userSettings } = useSelector((state) => state.auth)
    const [logoutLoaddiing, setLogoutloadding] = useState(false);
    const [show, setShow] = useState(false);
    const disconnectUA = useDisconnectUa();
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const dispatch = useDispatch();


    const logoutHandler = async () => {
        dispatch(clearHistory());
        setLogoutloadding(true);
        dispatch(openSidebar({ tab: SidebarTab.home }))
        disconnectUA();
        await dispatch(autlogout())
        setLogoutloadding(false);
    }
    useEffect(() => {
        _changePlatformLanguage()
    }, [show])

    return (
        <>
            {logoutLoaddiing && <LoaddingSpinner />}
            <div className="all-contacts-container setting-conatiner">
                {/* <!-- <div className="col-lg-3 col-md-4 col-sm-12"> --> */}
                <div className="left-menu-content py-4 px-3">
                    <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                        <SettingSidebarMenu />
                        <button
                            className='nav-link'
                            onClick={handleShow}
                        >
                            <FaPowerOff />
                            <span className="__MSG__Logout">Logout</span>
                        </button>
                    </div>
                </div>
                {/* <!-- </div> --> */}
            </div>

            {/* <!-- LOGOUT MODAL POPUP :: STARTS --> */}
            <Modal show={show} onHide={handleClose} aria-labelledby="contained-modal-title-vcenter"
                centered className='logout-modal-popup'>
                <Modal.Header closeButton>
                    <h5 className="modal-title __MSG__logoutTitle" id="dialPademodalLabel">Logout</h5>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close">
                        <svg width="20" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.3328 9.59625L2.41425 0.677734L0.646484 2.4455L9.565 11.364L0.646675 20.2823L2.41444 22.0501L11.3328 13.1318L19.6438 21.4428L21.4115 19.675L13.1005 11.364L21.4117 3.05281L19.644 1.28505L11.3328 9.59625Z" fill="white"></path>
                        </svg>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <img src={LogoutConfirmImg} alt="LOGOUT-CONFIRM" width="" height="" />
                    <p className="__MSG__LogoutConfirmMsg">Are you sure you want to logout?</p>
                </Modal.Body>
                <Modal.Footer>
                    <button className="cancel-btn __MSG__cancellogout" type="button" onClick={handleClose}>Cancel</button>
                    <button className="gradient-btn _logoutConfirm __MSG__confirmlogout" type="button" onClick={logoutHandler}>Confirm</button>
                </Modal.Footer>
            </Modal>
            {/* <!--LOGOUT MODAL POPUP :: ENDS --> */}
        </>
    )
}

export default SettingsSidebar