export const API_BASE_URL = "https://blog.voizcall.com/v1.50.2/api/"; // LIVE API URL
// export const API_BASE_URL = "https://panel-api.voizcall.com/v1.50.12/api"; // LOCAL API URL
export const THEME_COLOR = ['red', 'green', 'default'];
export const aboutUsUrl = "https://www.voizcall.com/about";
export const privacyPolicyUrl = "https://www.voizcall.com/privacy";

export const sipConfig = {
    _logEnable: true,
    _iceServer: "stun:stun.l.google.com:19302",
};
export const VAPID_KEY = 'BLFM-sstVTV4iy3WVn_7O1OYfr-iG8M2o0azMqqg_Vq-4Z-6jjImBBKpCFpDRvVrEUgEhpwRDILu6mv5BLBGKp8'
export const FIREBASE_CLOUD_MSG_SERVERKRY = 'AAAA5qyI82g:APA91bFk_DIvjViMJamw_fOr2KQOzEHWwZvNfLQxIRmiw7hljIK4-7F5lCYumX0d-GE-Uhqwwnb2uymjXxk5cRXHaXJCfgCoiqhRNX-NBCqwJQxRlxDx3akrB9VL-GN8bJUiILnoBL9k';
export const _accountCreds = {
    "_sipExtension": "505050",
    "_sipPassword": "505060@5050A1",
    "_sipServer": "s1.netcitrus.com",
    "_sipUsernameWithInstance": "505050",
    "_wssServer": "wss://s1.netcitrus.com:7443",
    "_extNumber": "505050",
    "_instanceId": "ddddaun8b3rw13s0ixmky6ntmgkpprl31s93",
    "_sipAor": "505050@s1.netcitrus.com"
}

export const wssServer = 'wss://s1.netcitrus.com:7443';

export const appName = "VoizCall-Web 1.1.5";

export const appVersion = "2.0.3";















































