import React, { useEffect, useState } from 'react'
import PageTitle from '../Layoute/PageTitle'
import { FaArrowLeft } from 'react-icons/fa'
import { useDispatch, useSelector } from 'react-redux'
import { SidebarTab, openSidebar, toggleSidebar } from '../../store/themeSlice'
import usePhonedial from '../../hooks/usePhonedial'

const ContactDetails = () => {
    const dispatch = useDispatch();
    const { contacts } = useSelector((state) => state.contacts);
    const { Sidebar } = useSelector((state) => state.theme)
    let contact = contacts.filter((ele) => ele.ed_id == Sidebar.openId)[0]
    const [dialPhone] = usePhonedial();
    return (
        <>
            <div className="container-fluid all-contacts-container contact-detail-container ">

                <div className="tab-content" id="v-pills-tabContent">
                    <div className="tab-pane fade show active contact-container" id="v-pills-all-calls" role="tabpanel" aria-labelledby="v-pills-all-calls-tab">
                        <div className="call-header page-title">
                            <div className="d-flex align-items-center">
                                <button className='back_button'
                                    onClick={() => {
                                        dispatch(openSidebar({ tab: SidebarTab.contact }))
                                    }}
                                ><FaArrowLeft /></button>
                                <h3 className="__MSG__allContact2 m-0"></h3></div>
                        </div>
                        <div className="tab-pane fade show active" id="v-pills-all-calls" role="tabpanel" aria-labelledby="v-pills-all-call-tab">
                            <div className="contact-list">
                                <div className="caller-details row mb-4">
                                    <div className="col-lg-1 col-md-2 col-sm-1 col call">
                                        <span className="person userDp">{contact.ed_name[0]}</span>
                                    </div>
                                    <div className="col-lg-3 col-md-5 col-sm-5 col caller-name">
                                        <h6 className="mb-0 userName">{contact.ed_name}</h6>
                                        <p className="mb-0"></p>
                                    </div>
                                </div>
                                <div className="contact-body">
                                    <div className="d-flex justify-content-between align-item-center py-1 px-2">
                                        <div className="number-details">
                                            <label>Mobile</label>
                                            <p>{contact.ed_mobile}</p>
                                        </div>
                                        <div className="call-option d-flex justify-content-end align-item-center">
                                            <span onClick={() => dialPhone(contact.ed_mobile, true)} title='video Call'>
                                                <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M0 19.2343C0 21.3383 1.64721 23.0488 3.67322 23.0488H18.3661C20.3921 23.0488 22.0393 21.3383 22.0393 19.2343V3.97635C22.0393 1.87241 20.3921 0.16185 18.3661 0.16185H3.67322C1.64721 0.16185 0 1.87241 0 3.97635V19.2343ZM32.089 20.9151C32.6859 20.5813 33.059 19.9376 33.059 19.2343V3.97635C33.059 3.27305 32.6859 2.62935 32.089 2.29558C31.4921 1.96181 30.7689 1.99758 30.2007 2.39095L24.6909 6.20544L23.8759 6.77166V16.439L24.6909 17.0052L30.2007 20.8197C30.7632 21.2071 31.4864 21.2489 32.089 20.9151Z"
                                                        fill="white"
                                                    ></path>
                                                </svg>
                                            </span>
                                            <div title="Click to dial" >
                                                <span id="clickToAudioCallCmn" onClick={() => dialPhone(contact.ed_mobile)}>
                                                    <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                        <path
                                                            d="M38.1743 28.835C35.4461 28.835 32.8065 28.3824 30.3444 27.5675C29.9587 27.4341 29.544 27.4143 29.1477 27.5104C28.7515 27.6065 28.3898 27.8145 28.1041 28.1108L24.6217 32.5696C18.3445 29.514 12.4665 23.7424 9.33895 17.1107L13.6643 13.3535C14.2631 12.7198 14.4406 11.8371 14.1966 11.0449C13.3759 8.53256 12.9545 5.83914 12.9545 3.05519C12.9545 1.83297 11.9563 0.814453 10.7585 0.814453H3.08388C1.88611 0.814453 0.444336 1.35766 0.444336 3.05519C0.444336 24.0819 17.5903 41.5552 38.1743 41.5552C39.7492 41.5552 40.3703 40.1293 40.3703 38.8844V31.0758C40.3703 29.8536 39.3721 28.835 38.1743 28.835Z"
                                                            fill="white"
                                                        ></path>
                                                    </svg>
                                                </span>
                                            </div>

                                            <span>
                                                <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M0.453613 11.9998C0.453613 18.3768 5.62322 23.5464 12.0002 23.5464C14.1373 23.5464 16.1388 22.9658 17.8556 21.9537L23.5469 23.5464L21.9542 17.8551C22.9663 16.1383 23.5469 14.1369 23.5469 11.9998C23.5469 5.62273 18.3773 0.453125 12.0002 0.453125C5.62322 0.453125 0.453613 5.62273 0.453613 11.9998Z"
                                                        fill="white"
                                                    ></path>
                                                    <path d="M15.8436 10.1621H7.64355" stroke="#614B98" strokeWidth="1.6" strokeLinecap="round"></path>
                                                    <path d="M16.3561 14.7998L10.2061 14.7998" stroke="#614B98" strokeWidth="1.6" strokeLinecap="round"></path>
                                                </svg>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="d-flex justify-content-between align-item-center py-1 px-2">
                                        <div className="number-details">
                                            <label>Email</label>
                                            <p>
                                                <svg width="20" height="15" viewBox="0 0 20 15" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path
                                                        d="M18 0H2C0.9 0 0.00999999 0.9 0.00999999 2L0 13C0 14.1 0.9 15 2 15H18C19.1 15 20 14.1 20 13V2C20 0.9 19.1 0 18 0ZM17.6 4.25L10.53 8.67C10.21 8.87 9.79 8.87 9.47 8.67L2.4 4.25C2.29973 4.19371 2.21192 4.11766 2.14189 4.02645C2.07186 3.93525 2.02106 3.83078 1.99258 3.71937C1.96409 3.60796 1.9585 3.49194 1.97616 3.37831C1.99381 3.26468 2.03434 3.15581 2.09528 3.0583C2.15623 2.96079 2.23632 2.87666 2.33073 2.811C2.42513 2.74533 2.53187 2.69951 2.6445 2.6763C2.75712 2.65309 2.87328 2.65297 2.98595 2.67595C3.09863 2.69893 3.20546 2.74453 3.3 2.81L10 7L16.7 2.81C16.7945 2.74453 16.9014 2.69893 17.014 2.67595C17.1267 2.65297 17.2429 2.65309 17.3555 2.6763C17.4681 2.69951 17.5749 2.74533 17.6693 2.811C17.7637 2.87666 17.8438 2.96079 17.9047 3.0583C17.9657 3.15581 18.0062 3.26468 18.0238 3.37831C18.0415 3.49194 18.0359 3.60796 18.0074 3.71937C17.9789 3.83078 17.9281 3.93525 17.8581 4.02645C17.7881 4.11766 17.7003 4.19371 17.6 4.25Z"
                                                        fill="var(--primary-color)"
                                                    ></path>
                                                </svg>
                                                <span>{contact.ed_email_id}</span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="tab-pane fade" id="v-pills-favourite" role="tablist" aria-labelledby="v-pills-favourite-tab">
                        Favourite
                    </div>
                    <div className="tab-pane fade" id="v-pills-enterprise" role="tablist" aria-labelledby="v-pills-enterprise-tab">
                        Enterprise contacts
                    </div>
                    <div className="tab-pane fade" id="v-pills-sync-contacts" role="tablist" aria-labelledby="v-pills-sync-contacts-tab">
                        Sync contacts
                    </div>
                </div>
            </div>



        </>
    )
}

export default ContactDetails