
import React, { useEffect, useState } from 'react'
import Select from "react-select";
import Axios from '../../utils/Axios';

const CountryPhoneCode = ({ setCountryPhoneCode }) => {

    const [coutryOption, setcoutryOption] = useState([]);
    const [defaultOption, setDefaultoptin] = useState('IN');
    const getCountryPhoneCode = async () => {
        let tocken = localStorage.getItem('access_token');
        try {
            let res = await Axios.get('auth/get-countries-phonecode-list');
            let country_list = res.data.data;
            country_list = JSON.stringify(country_list).replaceAll('country_phonecode', 'value');
            country_list = JSON.parse(country_list)
            setcoutryOption(country_list)
            let defult_phonecode = country_list.filter((elem) => elem.country_code === defaultOption)[0]['value']
            setCountryPhoneCode(defult_phonecode)
        } catch (error) {
            console.log(error)
        }
    }
    useEffect(() => {
        getCountryPhoneCode();

    }, []);


    const formatOptionLabel = ({ country_flag, value }) => (
        <div className="">
            <img src={country_flag} alt="" className='dd-selected-image' width={'30px'} height={'30px'} />
            <label className="" > +{value}</label>
        </div>
    );

    const handleChange = (e) => {
        setCountryPhoneCode(e.value);
        setDefaultoptin(e.country_code)
    }

    return (
        <Select
            className="image-select"
            classNamePrefix="image-select"
            value={coutryOption.filter((elem) => elem.country_code === defaultOption)[0]}
            formatOptionLabel={formatOptionLabel}
            isSearchable={false}
            options={coutryOption}
            onChange={handleChange}

        />
    )
}

export default CountryPhoneCode;
