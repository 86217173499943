import React, { useState } from 'react'
import Logo from '../../assets/app-icon/icon/logo.svg'
import CountryPhoneCode from '../../component/Auth/CountryPhoneCode'
import { useDispatch } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { SendOtp } from '../../store/Auth/authService'
import * as Yup from 'yup';
import { MdArrowBackIosNew } from 'react-icons/md'
import { BsQrCodeScan } from 'react-icons/bs'

const OtpLogin = () => {
    const [countyPhonecode, setCountryPhoneCode] = useState()
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const formik = useFormik({
        initialValues: {
            user_data: '',
        },
        validationSchema: Yup.object({
            user_data: Yup.number().required("Mobile Number Is Required"),
        }),
        onSubmit: async (values) => {
            let form_data = {
                "user_data": values.user_data,
                'usr_country_phonecode': countyPhonecode,
                "user_type": 'mobile'
            }

            let res = await dispatch(SendOtp(form_data));

            const { status, data } = res.payload;

            if (status === "SUCCESS") {
                navigate('/otp-verify');
            }
        }
    })
    return (
        <>
            <div className="login-left-section">
                <div className="logo">
                    <img src={Logo} alt="LOGO" />
                </div>
                <Link className="back _backToLoginScreen" to={'/login'}>
                    <MdArrowBackIosNew />
                    Back
                </Link>

                <div>
                    <h3 className="heading">Login with OTP</h3>
                    <p className="sub-heading">Your OTP will be sent to this mobile number</p>
                    <form onSubmit={formik.handleSubmit} >
                        {/* <!-- Login with OTP --> */}
                        <div className="col-lg-12 mb-2">
                            <div className="input-group m-0">
                                {/* <!-- Country code --> */}
                                <div className="input-group-text right-line position-relative border-end-0 p-0">
                                    <div className="country-code dropdown__options--visible">
                                        <CountryPhoneCode setCountryPhoneCode={setCountryPhoneCode} />

                                    </div>
                                </div>
                                {/* <!-- Mobile number --> */}
                                <input
                                    type="text"
                                    className="form-control border-start-0"
                                    placeholder="Enter your mobile number"
                                    name='user_data'
                                    maxLength="10"
                                    {...formik.getFieldProps("user_data")}
                                />
                            </div>
                            {
                                formik.errors.user_data && formik.touched.user_data ? (
                                    <div className='error_msg'>{formik.errors.user_data}</div>
                                ) : null
                            }
                        </div>
                        <div className="row align-items-center">
                            {/* <!-- Continue with get OTP --> */}
                            <div className="col-md-6 col-12">
                                <button type="submit" className="col-12 common-btn gradient-btn" >Continue</button>
                            </div>
                            {/* <!-- Login with Username --> */}
                            <div className="col-md-6 col-12">
                                <Link type="button" className="col-12 text-center common-btn login-with-username show" to="/login">
                                    <span style={{ paddingLeft: '5px',paddingRight:'5px' }}>
                                        <svg style={{ scale: '1.3' }} width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path
                                                d="M7.50001 8.00026C8.22071 8.00026 8.92523 7.78654 9.52447 7.38615C10.1237 6.98575 10.5908 6.41664 10.8666 5.7508C11.1424 5.08496 11.2145 4.35229 11.0739 3.64544C10.9333 2.93858 10.5863 2.2893 10.0767 1.77969C9.56704 1.27007 8.91776 0.923023 8.21091 0.782421C7.50405 0.641819 6.77138 0.713981 6.10554 0.989781C5.4397 1.26558 4.8706 1.73263 4.4702 2.33187C4.0698 2.93111 3.85608 3.63563 3.85608 4.35633C3.85589 4.83491 3.95001 5.30884 4.13307 5.75103C4.31612 6.19322 4.58453 6.595 4.92294 6.93341C5.26134 7.27181 5.66312 7.54022 6.10531 7.72327C6.5475 7.90633 7.02143 8.00045 7.50001 8.00026ZM7.50001 9.82185C5.06828 9.82185 0.212891 11.0429 0.212891 13.4658V15.2874H14.7871V13.4658C14.7871 11.0429 9.93247 9.82185 7.50001 9.82185Z"
                                                fill="white"
                                            ></path>
                                        </svg>
                                    </span>
                                    Login with Username
                                </Link>
                            </div>
                        </div>


                        {/* <!-- Login with QR Code --> */}
                        <div className="col-lg-12 mt-3">
                            <button type="button" className="col-lg-12 col-md-12 col-sm-12 common-btn login-with-qr primary-btn show" id="loginWithQRCode">

                                <span>
                                    <BsQrCodeScan className='icon' style={{ color: "white" }} />
                                </span>
                                Login with QR Code
                            </button>
                            
                        </div>
                    </form>
                    {/* <!-- Sign Up --> */}
                    <div className="col-lg-12 hide">
                        <button type="button" className="col-lg-12 col-md-12 col-sm-12 common-btn signup-button">
                            Sign Up
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default OtpLogin