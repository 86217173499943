import React, { useEffect } from 'react'
import { Outlet, useNavigate } from 'react-router-dom'
import Image1 from '../../assets/images/login_signup/1.png'
import Image2 from '../../assets/images/login_signup/2.png'
import Image4 from '../../assets/images/login_signup/4.png'
import Image5 from '../../assets/images/login_signup/5.png'
import Image6 from '../../assets/images/login_signup/6.png'
import Image7 from '../../assets/images/login_signup/7.png'
import LOGO from '../../assets/images/logo/logo.png'
import { FaGoogle, FaQrcode, FaVideo } from 'react-icons/fa'
import { Link } from 'react-router-dom'
// import '../../style.css'
import './style.css'
import { useSelector } from 'react-redux'
import { appVersion } from '../../config'
const AuthLayouteOld = () => {
    const { user } = useSelector((state) => state.auth);
    const navigate = useNavigate();
    useEffect(() => {
        if (user) {
            return navigate('/')
        }
    }, [user]);
    return (
        <>
            <div className="login-page1">
                <div className="container-fluid p-0">
                    <div className="row m-0">
                        <div className="col-12 p-0">
                            <div className="login-contain-main">
                                <div className="left-page">
                                    <div className="login-content">
                                        <Outlet />
                                        {/* <div className="termscondition"><h4 className="mb-0"><span>*</span>Terms and condition<b>&amp;</b>Privacy policy</h4></div> */}
                                    </div>
                                </div>
                                <div className="right-page">
                                    <span className='appVersion'>Version {appVersion}</span>
                                    <div className="right-login animat-rate">
                                        <div className="animation-block">
                                            <div className="bg_circle">
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                                <div></div>
                                            </div>
                                            <div className="cross"></div>
                                            <div className="cross1"></div>
                                            <div className="cross2"></div>
                                            <div className="dot"></div>
                                            <div className="dot1"></div>
                                            <div className="maincircle"></div>
                                            <div className="top-circle"></div>
                                            <div className="center-circle"></div>
                                            <div className="bottom-circle"></div>
                                            <div className="bottom-circle1"></div>
                                            <div className="right-circle"></div>
                                            <div className="right-circle1"></div>
                                            <img className="heart-logo" src={Image5} alt="login logo" />
                                            <img className="has-logo" src={Image4} alt="login logo" />
                                            <img className="login-img" src={Image1} alt="login logo" />
                                            <img className="boy-logo" src={Image6} alt="login boy logo" />
                                            <img className="girl-logo" src={Image7} alt="girllogo" />
                                            <img className="cloud-logo" src={Image2} alt="login logo" />
                                            <img className="cloud-logo1" src={Image2} alt="login logo" />
                                            <img className="cloud-logo2" src={Image2} alt="login logo" />
                                            <img className="cloud-logo3" src={Image2} alt="login logo" />
                                            <img className="cloud-logo4" src={Image2} alt="login logo" />
                                            <img className="has-logo1" src={Image4} alt="login logo" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AuthLayouteOld