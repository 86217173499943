import React from "react";
import CallTimer from "./CallTimer";
import useCallHangup from "../../hooks/useCallHangup";
import { useSelector } from "react-redux";

const MinimizeCallPopup = ({ calltimer }) => {
  const callHangUp = useCallHangup();
  const { sessions, callDirection, sessionId, sessionState } = useSelector(
    (state) => state.sipjs
  );
  let session= sessions[Object.keys(sessions)[Object.keys(sessions).length-1]];
  let dialNumber = session?.remoteIdentity?.uri?.normal?.user;
  return (
    <>
      <div
        className="blind-call-minimize attandant-minimize-call-popup "
        id="divOngoingCallHoldMinimised"
      >
        <div className="d-flex justify-content-between align-items-center">
          <span className="call-status mx-2">On Hold</span>
          {/*
        <!-- Onhold icon -->
        */}
          <span className="mx-2 connecting-icon">
            <svg
              width="22"
              height="22"
              viewBox="0 0 22 22"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M20.3742 15.1311C18.9009 15.1311 17.4756 14.8867 16.146 14.4467C15.9378 14.3746 15.7138 14.3639 15.4998 14.4158C15.2859 14.4677 15.0905 14.58 14.9363 14.74L13.0558 17.1478C9.66607 15.4978 6.49196 12.3811 4.80309 8.8L7.13876 6.77111C7.46216 6.42889 7.55798 5.95222 7.42622 5.52444C6.98304 4.16778 6.75547 2.71333 6.75547 1.21C6.75547 0.55 6.21647 0 5.56967 0H1.42536C0.778556 0 0 0.293333 0 1.21C0 12.5644 9.25882 22 20.3742 22C21.2246 22 21.56 21.23 21.56 20.5578V16.3411C21.56 15.6811 21.021 15.1311 20.3742 15.1311Z"
                fill="white"
              />
              <line
                x1="14.0244"
                y1="8.32422"
                x2="14.0244"
                y2="2.03491"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
              <line
                x1="20.1689"
                y1="2.03613"
                x2="20.1689"
                y2="8.32544"
                stroke="white"
                strokeWidth="2"
                strokeLinecap="round"
              />
            </svg>
          </span>
          {/*
        <!-- Onhold information -->
        */}
          <p className="mb-0 mx-2 caller-name">
            <span className="mb-0">
              <span id="onHoldNumber">{dialNumber}</span> |{" "}
              <span className="_timer" id="onHoldTimer">
                {calltimer}
              </span>
            </span>
          </p>
          {/*
        <!-- Onhold call disconnect -->
        */}
          <button
            className="end-button mx-2"
            type="button"
            value=""
            id="btnOnHoldCallDisconnect"
            onClick={() => callHangUp()}
          >
            <svg
              width="69"
              height="27"
              viewBox="0 0 69 27"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12.6227 24.2714C14.942 21.9521 17.5866 20.1089 20.4009 18.7371C20.8469 18.5273 21.217 18.1923 21.4688 17.7704C21.7206 17.3485 21.8438 16.8569 21.8245 16.3518L20.8381 9.44456C28.8791 6.81295 38.9848 6.92491 47.5137 10.1364L47.1625 17.1391C47.2144 18.2092 47.8449 19.1414 48.7535 19.6352C51.675 21.1613 54.4174 23.1872 56.8817 25.6515C57.9636 26.7333 59.7136 26.7864 60.7319 25.7681L67.2561 19.2439C68.2743 18.2257 69.0192 16.5192 67.5166 15.0166C48.9043 -3.59566 18.8617 -4.48662 1.36319 13.0119C0.0244024 14.3507 0.758618 16.1409 1.86052 17.2428L8.77249 24.1547C9.85436 25.2366 11.6045 25.2896 12.6227 24.2714Z"
                fill="white"
              />
            </svg>
          </button>
        </div>
      </div>
    </>
  );
};

export default MinimizeCallPopup;
