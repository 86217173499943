import React, { useEffect, useState } from 'react';
import { useStopwatch } from 'react-timer-hook';
import { pad } from '../utils/Utils';

const useCallTimerDuration = () => {
    const {
        seconds,
        minutes,
        hours,
        start,
        pause,
        reset,
    } = useStopwatch({ autoStart: false });

    // const [calltimer, setCallTimer] = useState()
    const TimerAction = (action) => {
        // console.debug('timer ', action)
        // console.debug('ksdfdsnfv xfcvbxcfvb dfxcvdxc')
        switch (action) {
            case 'start': start();
                break;
            case 'reset': reset();
                pause();
                break;
            case 'pause': pause();
                break;
        }
    }
    let calltimer = <><span>{pad(hours)}</span>:<span>{pad(minutes)}</span>:<span>{pad(seconds)}</span></>



    return [calltimer, TimerAction]
}

export default useCallTimerDuration;
