import React from 'react'
import { Modal } from 'react-bootstrap'
import OngoingCallUser from '../../assets/app-icon/icon/ongoing-user.svg'
import { useState } from 'react'
import { useSelector } from 'react-redux'
import useCallHangup from '../../hooks/useCallHangup'

const ConnectingModal = (props) => {
    const [fullScreen, setFullScreen] = useState(false)
    const { sessions, callDirection, sessionId, sessionState } = useSelector((state) => state.sipjs);
    const number = localStorage.getItem('dialNumber')

    // let number = sessions[sessionId]?.remoteIdentity?.uri?.normal?.user
    // let displayName = sessions[sessionId]?.remoteIdentity?.displayName || dialNumber
    const callHangUp = useCallHangup();
    const endCallHandler = () => {
        callHangUp()
    }
    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className={props.className + (fullScreen ? ' fullscreen' : '')}
            >
                <Modal.Header>
                    <h5 className="modal-title" id="callConnectingModalLabel">Calling...</h5>
                    <div>
                        <svg className="mx-3 cursor-pointer _minimizeConnectingModal" width="20" height="3" viewBox="0 0 20 3" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => setFullScreen(false)}>

                            <path d="M18.3589 1.11035L1.70703 1.11035" stroke="url(#paint0_linear_815_541)" strokeWidth="2" strokeLinecap="round"></path>
                            <defs>
                                <linearGradient id="paint0_linear_815_541" x1="10.033" y1="0.870605" x2="10.033" y2="2.11035" gradientUnits="userSpaceOnUse">
                                    <stop stopColor="#EDEDED"></stop>
                                    <stop offset="1" stopColor="#FFFFFE"></stop>
                                </linearGradient>
                            </defs>
                        </svg>
                        <svg className="cursor-pointer _maximizeConnectingModal" onClick={() => {
                            setFullScreen(true)
                        }} width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1.92578 6.50549V4.2381C1.92578 3.63675 2.16467 3.06003 2.58989 2.63481C3.0151 2.20959 3.59183 1.9707 4.19318 1.9707H6.46057M15.5302 1.9707H17.7975C18.3989 1.9707 18.9756 2.20959 19.4008 2.63481C19.8261 3.06003 20.0649 3.63675 20.0649 4.2381V6.50549M20.0649 15.5751V17.8425C20.0649 18.4438 19.8261 19.0205 19.4008 19.4458C18.9756 19.871 18.3989 20.1099 17.7975 20.1099H15.5302M6.46057 20.1099H4.19318C3.59183 20.1099 3.0151 19.871 2.58989 19.4458C2.16467 19.0205 1.92578 18.4438 1.92578 17.8425V15.5751" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                        </svg>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    {/* <!--CALL CONNECTING INFO :: STARTS--> */}
                    <div className="call-connecting-info">
                        <h3 id="callingContactDisplayName">{number}</h3>
                        <img id="callingContactProfileImage" src={OngoingCallUser} alt="DIALER" width="" height="" />
                        <p id="callingContactDisplayNumber">{number}</p>
                        <span id="callingContactStatus">Connecting...</span>
                        <button className="call-end-btn" type="button" id="disconnectCall" onClick={endCallHandler}>
                            <svg width="69" height="27" viewBox="0 0 69 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M12.6227 24.2714C14.942 21.9521 17.5866 20.1089 20.4009 18.7371C20.8469 18.5273 21.217 18.1923 21.4688 17.7704C21.7206 17.3485 21.8438 16.8569 21.8245 16.3518L20.8381 9.44456C28.8791 6.81295 38.9848 6.92491 47.5137 10.1364L47.1625 17.1391C47.2144 18.2092 47.8449 19.1414 48.7535 19.6352C51.675 21.1613 54.4174 23.1872 56.8817 25.6515C57.9636 26.7333 59.7136 26.7864 60.7319 25.7681L67.2561 19.2439C68.2743 18.2257 69.0192 16.5192 67.5166 15.0166C48.9043 -3.59566 18.8617 -4.48662 1.36319 13.0119C0.0244024 14.3507 0.758618 16.1409 1.86052 17.2428L8.77249 24.1547C9.85436 25.2366 11.6045 25.2896 12.6227 24.2714Z" fill="white"></path>
                            </svg>
                        </button>
                    </div>
                    {/* <!-- CALL CONNECTING INFO :: ENDS--> */}
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ConnectingModal