import React, { useEffect } from 'react'
import { useSelector } from 'react-redux';
import { Outlet, useNavigate } from 'react-router-dom'
import Deshboard from './Layoute/Deshboard';


const RootLayoute = () => {
  const { user } = useSelector((state) => state.auth);
  const navigate = useNavigate();

  useEffect(() => {
    if (user == null) {
      return navigate('/login');
    }
  }, [user]);
  return (
    <Deshboard />
  )
}

export default RootLayoute