import React from 'react'
import { BsGear, BsTelephone, BsChatText, BsChatLeftText } from 'react-icons/bs'
import { IoHomeOutline } from 'react-icons/io5'
import { MdDialpad, MdOutlinePermContactCalendar } from 'react-icons/md'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { SidebarTab, openSidebar, toggleOpenDialPadModal, toggleSidebar } from '../store/themeSlice'
import { getConfigSettings } from '../data/userSettings'
import { nanoid } from '@reduxjs/toolkit'
import { getObjectCount } from '../utils/Utils'


const Menu = ({ mobileMenu }) => {
    const { sessions, callDirection, sessionId, sessionState } = useSelector(
        (state) => state.sipjs
    );
    const dispatch = useDispatch()
    const { Sidebar, openDialpad } = useSelector((state) => state.theme)

    const sms_service_status = getConfigSettings('sms.enablesms');
    const im_service_status = getConfigSettings('im.enableim');

    const menu_item = [
        {
            'id': nanoid(),
            "tab": SidebarTab.home,
            'icon': <IoHomeOutline className='text-white' />,
            "visible": true
        },
        {
            'id': nanoid(),
            "tab": SidebarTab.contact,
            'icon': <MdOutlinePermContactCalendar className='text-white' />,
            "visible": true
        },
        {
            'id': nanoid(),
            "tab": 'dialpad',
            'icon': <MdDialpad className='text-white' />,
            "visible": true,
        },
        {
            'id': nanoid(),
            "tab": SidebarTab.callHistory,
            'icon': <BsTelephone className='text-white' />,
            "visible": true
        },

        {
            'id': nanoid(),
            "tab": SidebarTab.chat,
            'icon': <BsChatText className='text-white' />,
            "visible": ([1, '1', true, 'true', 'TRUE'].includes(im_service_status)) ? true : false
        },
        {
            'id': nanoid(),
            "tab": SidebarTab.message,
            'icon': <BsChatLeftText className='text-white' />,
            "visible": ([1, '1', true, 'true', 'TRUE'].includes(sms_service_status)) ? true : false
        },
        {
            'id': nanoid(),
            "tab": SidebarTab.settings,
            'icon': <BsGear className='text-white' />,
            "visible": true
        }
    ]

    return (
        <>
            <ul className="navbar-nav me-auto w-100 menu">
                {menu_item.map((item, index) => {
                    let sessioncount = getObjectCount(sessions)
                    let action_desabled = '';
                    if (sessioncount > 0 && item.tab == 'dialpad') {
                        action_desabled = 'action-disabled'
                    }
                    return <React.Fragment key={item.id}>
                        {item.visible && <li id="liHomeScreen" className={`nav-item ` + (Sidebar.tab == item.tab ? 'active' : '')} >
                            <Link className={`nav-link ${action_desabled}`} aria-current="page" onClick={() => {
                                (() => {
                                    switch (item.tab) {
                                        case 'dialpad':
                                            dispatch(toggleOpenDialPadModal(true))
                                            break;
                                        case SidebarTab.callHistory:
                                            mobileMenu ? dispatch(openSidebar({ tab: item.tab, key: 'allcallhistory' })) : dispatch(openSidebar({ tab: item.tab }))
                                            break;
                                        default: dispatch(openSidebar({ tab: item.tab }))
                                            dispatch(toggleSidebar(false))
                                    }
                                })()
                            }}>
                                {item.icon}
                            </Link>
                        </li>
                        }
                    </React.Fragment>
                })}
            </ul>

        </>
    )
}

export default Menu