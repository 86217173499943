import React from 'react'
import Draggable, { DraggableCore } from 'react-draggable';
import DialPadeButtonList from '../Dialpade/DialPadeButtonList';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendDTMF } from '../../store/sipjs/sipjsService';
const DtmfModal = ({ showHide }) => {
    const [number, setNumber] = useState('');
    const { sessions, sessionId } = useSelector((state) => state.sipjs);
    const dispatch = useDispatch()

    const CleareNumber = () => {
        setNumber(number.substring(0, (number.length - 1)))
    }

    const typeDtmfNumber = (num) => {
        let session = sessions[sessionId];
        setNumber((prev) => prev.concat(num))
        dispatch(sendDTMF({ session, digit: num }))
    }

    return (
        <>
            <Draggable>
                <div>
                    <div className="dailpad-dropdown show" id="DTMFModal">
                        <div className="dail-header">
                            <button type="button" className="btn-close" id="DTMFModalClose" onClick={() => showHide(false)}>
                                <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M11.3328 9.59625L2.41425 0.677734L0.646484 2.4455L9.565 11.364L0.646675 20.2823L2.41444 22.0501L11.3328 13.1318L19.6438 21.4428L21.4115 19.675L13.1005 11.364L21.4117 3.05281L19.644 1.28505L11.3328 9.59625Z"
                                        fill="white"
                                    ></path>
                                </svg>
                            </button>
                            <div className="position-relative d-flex align-items-center contact-area">
                                <input type="text" className="_DTMFInput" id="DTMFInput" value={number} />
                                <svg className="_clearDTMFNumber" id="clearDTMFNumber" width="29" height="22" viewBox="0 0 29 22" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={CleareNumber}>
                                    <path
                                        fillRule="evenodd"
                                        clipRule="evenodd"
                                        d="M9.05628 0.0557677C8.71586 0.124022 8.18544 0.393393 7.91178 0.636965C7.57938 0.932808 0.109632 10.2757 0.0382662 10.4849C-0.0476479 10.7367 0.0144408 11.0729 0.191045 11.3121C0.764007 12.0881 7.72612 20.7242 7.91233 20.8899C8.20246 21.1481 8.71246 21.3991 9.09926 21.474C9.29195 21.5114 12.2429 21.5313 17.5828 21.5313C24.7581 21.5313 25.8108 21.5207 26.0936 21.4454C27.0405 21.1935 27.7698 20.446 28.0178 19.4734C28.1412 18.9894 28.1412 2.53703 28.0178 2.05297C27.7698 1.08042 27.0405 0.332934 26.0936 0.0809492C25.8103 0.00557245 24.7663 -0.00418602 17.5342 0.00125412C13.0039 0.00461916 9.18891 0.0291278 9.05628 0.0557677ZM25.9375 1.93346C26.0209 1.99706 26.1401 2.11876 26.2023 2.20395C26.3154 2.3588 26.3155 2.36233 26.3155 10.7632C26.3155 19.1641 26.3154 19.1676 26.2023 19.3224C26.1401 19.4076 26.0209 19.5293 25.9375 19.5929C25.7862 19.7084 25.771 19.7086 17.606 19.7232C10.1075 19.7365 9.41014 19.73 9.23557 19.6454C9.09432 19.5769 8.14164 18.4247 5.54807 15.1857C3.62464 12.7837 2.05096 10.7935 2.05096 10.7632C2.05096 10.7329 3.62426 8.74316 5.5472 6.34171C7.986 3.29601 9.09844 1.94765 9.2253 1.8836C9.3917 1.79958 10.1052 1.79285 17.5966 1.80474C25.7723 1.81781 25.7862 1.81798 25.9375 1.93346ZM11.3831 6.27682L10.7809 6.89408L12.6739 8.82864L14.5669 10.7632L12.687 12.6843L10.8071 14.6056L11.4243 15.2362L12.0416 15.8668L13.9222 13.9463L15.8027 12.0257L17.6832 13.9463L19.5638 15.8668L20.1811 15.2362L20.7983 14.6056L18.9184 12.6843L17.0385 10.7632L18.9318 8.8283L20.8252 6.8934L20.2079 6.2628L19.5906 5.63213L17.6966 7.56641L15.8027 9.50069L13.9222 7.58015C12.8879 6.52381 12.029 5.65956 12.0136 5.65956C11.9981 5.65956 11.7145 5.93734 11.3831 6.27682Z"
                                        fill="white"
                                    ></path>
                                </svg>
                            </div>
                        </div>
                        <div className="keypad-div">
                            <div className="row digits_box">
                                <DialPadeButtonList typeNumber={typeDtmfNumber} />
                            </div>
                        </div>
                    </div>
                </div>
            </Draggable>
        </>


    )
}

export default DtmfModal