import { createSlice } from '@reduxjs/toolkit'
const themecolor = localStorage.getItem('themeColor') ? localStorage.getItem('themeColor') : 'default';
export const SidebarTab = Object.freeze({
    home: 'Home',
    contact: "Contact",
    callHistory: "callHistory",
    settings: "settings",
    message: "message",
    chat: "chat"
});
let lang = localStorage.getItem('lang') ? localStorage.getItem('lang') : 'en'
const initialState = {
    Sidebar: {
        tab: SidebarTab.home,
        openId: null
    },
    hideSidebar: false,
    connectinCall: false,
    openDialpad: false,
    incommingCallModalOpen: false,
    videocall: false,
    dialNumber: '',
    minimizeVideoCall: false
    ///  true sidebar open false sidebar hide
}

export const themeSlice = createSlice({
    name: 'theme',
    initialState,
    reducers: {
        toggleMainMenu: (state, action) => {
            state.MainMenu = action.payload;
        },
        openSidebar: (state, action) => {
            state.Sidebar.tab = action.payload.tab;
            state.Sidebar.openId = action.payload?.key;
        },
        toggleSidebar: (state, action) => {
            state.hideSidebar = action.payload
        },

        // this function used when call is connecting 
        toggleConnectingCall: (state, action) => {
            state.connectinCall = action.payload
        },
        toggleOpenDialPadModal: (state, action) => {
            state.openDialpad = action.payload
        },
        //this function used for show hide incomming call modal
        toggleIncommigCallModal: (state, action) => {
            state.incommingCallModalOpen = action.payload
        },
        // this function used to show video calling screen
        toggleVideoCall: (state, action) => {
            state.videocall = action.payload
        },
        updateThemeProperties: (state, action) => {
            const { key, value } = action.payload;
            state[key] = value
        }
    },


})

// Action creators are generated for each case reducer function
export default themeSlice.reducer

export const { toggleMainMenu, openSidebar, toggleSidebar, toggleConnectingCall, toggleOpenDialPadModal, toggleIncommigCallModal, toggleVideoCall, updateThemeProperties } = themeSlice.actions