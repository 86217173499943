import React, { useEffect, useState } from 'react'
import OnGoingCall from '../../assets/app-icon/icon/ongoing-user.svg'
import HeaderUser from '../../assets/app-icon/icon/header-user.svg'
import LogoHeader from '../../assets/app-icon/icon/logo-header.svg'
import { FaBars } from 'react-icons/fa'
import { IoHomeOutline } from 'react-icons/io5'
import { BsGear, BsTelephone } from 'react-icons/bs'
import { MdDialpad, MdOutlinePermContactCalendar } from "react-icons/md";
import { useDispatch, useSelector } from 'react-redux'
import { Container, Nav, NavDropdown, Navbar } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { SidebarTab, openSidebar, toggleOpenDialPadModal } from '../../store/themeSlice'
import DialPade from '../Dialpade/DialPade'
import DialPadeModal from '../Dialpade/DialPadeModal'
import ConnectingModal from '../Calling/ConnectingModal'
import Menu from '../Menu'
import { nanoid } from '@reduxjs/toolkit'



const Header = () => {
    const [openMenu, setOpenMenu] = useState(false);
    const { user, accountCreds } = useSelector((state) => state.auth)
    const { sessions, callDirection, sessionId, sessionState, regState } = useSelector((state) => state.sipjs);
    const { Sidebar, openDialpad } = useSelector((state) => state.theme)
    const [openDialpade, setOpenDialpade] = useState(false)
    const dispatch = useDispatch();

    return (
        <>
            {/* <!-- HEADER :: STARTS --> */}
            <header className="header" id="myHeader">
                <Navbar bg="light" >
                    <Container fluid>
                        <div className="d-flex w-100 justify-content-between justify-content-md-start">
                            <Navbar.Brand>
                                <img src={LogoHeader} className="logo-header" alt="LOGO" />{openMenu}
                            </Navbar.Brand>
                            <Link className="nav-link dropdown-toggle " id="navbarDropdown" role="button" data-bs-toggle="" aria-expanded="false">
                                <div className="profile-info">
                                    <img className="_userImage" src={HeaderUser} alt="PROFILE-PIC" width="" height="" />
                                    <span className={`green-dot _pcsTitle ` + (regState == 'Registered' ? 'user-registered' : 'user-unregistered')} title="REGISTERED_STATE"
                                    ></span>
                                    <p className="mb-0">
                                        <span className="_extName">{user?.usr_mobile_number}</span>
                                        <span className="_extNum">{accountCreds?._sipExtension}</span>
                                    </p>

                                </div>
                            </Link>
                        </div>

                        <div className="w-100 d-none d-md-block">

                            <Menu key="5453416565428546285462354" />
                        </div>

                    </Container>
                </Navbar>
            </header>
            {/* <!-- HEADER :: ENDS --> */}


            {/* <!-- DIAL PAD MODAL POPUP :: START --> */}
            <DialPadeModal
                show={openDialpad}
                onHide={() => dispatch(toggleOpenDialPadModal(false))}
                className="dialpad-modal"
            />

            {/* <!-- DIAL PAD MODAL POPUP :: ENDS --> */}


        </>
    )
}

export default Header