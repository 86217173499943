import React from 'react'
import { useSelector } from 'react-redux';
import { getObjectCount } from '../../utils/Utils';
import CallTimer from './CallTimer';

const ConferenceCallUserDisplay = ({ calltimer }) => {
    const { holdUnholdStates, sessions, conferenceCallMerge } = useSelector((state) => state.sipjs)
    let count = 0;
    return (

        <>
            <div className="conference-call-info text-center " id="callConferenceInfo">
                <p className="mb-0 caller-info call-conference-detail" id="coferenceCallNames">
                    {
                        Object.keys(sessions).map((_sessionId) => {
                            count++;
                            let number = sessions[_sessionId]?.remoteIdentity.uri.normal.user;
                            let displayName = sessions[_sessionId]?.remoteIdentity._displayName;
                            return (
                                <>
                                    {displayName != "" ? displayName : number}
                                    {count != getObjectCount(sessions) ? (" & ") : ('')}
                                </>
                            )
                        })
                    }
                </p>

                <div className="call-time _timer">
                    {calltimer}
                </div>
            </div>
        </>
    )
}

export default ConferenceCallUserDisplay