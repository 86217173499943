import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import Axios from "../../utils/Axios";
import { wssServer } from "../../config";
import { AddSettings, getConfigSettings, userSettings } from "../../data/userSettings";
import { getRefershToken } from "../../utils/Utils";

export let tocken = localStorage.getItem('access_token')
let access_token = null;
const config_axios = {
    headers: {
        'Authorization': `Barear ${tocken}`
    }
}
const device_token = uuidv4().replaceAll("-", "");
const device_type = 'web';
const instanceId = "dddd" + device_token;

// -------------------------------------------------
// ----------- Get Global Config Settings ---------
// -------------------------------------------------
export const getGlobalConfigData = createAsyncThunk('auth/get-switch-config', async (_, { rejectWithValue }) => {
    try {
        let res = await Axios.get('auth/get-switch-config')
        localStorage.setItem('_globalConfigSettings', JSON.stringify(res.data.data));
        return res.data;
    } catch (error) {
        throw rejectWithValue(error);
    }
})


// ------------------------- AUTH Login --------------------
export const authLogin = createAsyncThunk(
    "auth/login",
    async (values, { rejectWithValue }) => {

        try {
            let res = await Axios.post("auth/login", values);
            const { access_token } = res.data
            tocken = access_token;
            storedata(res.data);
            if (tocken) {
                await getUserProfileDataAPI();
            }
            return res.data;
        } catch (error) {
            //console.log(error)
            throw rejectWithValue(error.message);
        }
    }
);

/*---------------------------------------------------
------------ Auth Otp login to send otp-------------*/
export const SendOtp = createAsyncThunk(
    "auth/send-otp",
    async (values, { rejectWithValue }) => {
        try {
            let res = await Axios.post("auth/send-otp", values, {
                headers: {
                    'Content-Type': 'application/json'
                }
            });

            let { status, message } = res.data;
            if (status == "ERROR") {
                toast.error(message)
            }

            localStorage.setItem('otp_auth_tocken', res.data.data.otp_auth_token);
            return res.data;
        } catch (error) {
            console.log(error)
            throw rejectWithValue(error.message);
        }
    }
);

/*---------------------------------------------------
------------ Auth logout ----------------------------*/
export const autlogout = createAsyncThunk(
    "auth/logout",
    async (_, { rejectWithValue }) => {
        //console.log(tocken)
        let accountCreds = JSON.parse(localStorage.getItem("_accountCreds"))
        let instanceId = accountCreds?._instanceId || null;
        let user = JSON.parse(localStorage.getItem('user'));
        let auth_type = user.auth_type;
        let device_type = 'web';
        let value = {
            auth_type: auth_type,
            instance_id: instanceId,
            device_type: device_type
        }
        try {
            await deletePushSubscriber(value)

            let res = await Axios.post("auth/logout", _, {
                headers: {
                    'Authorization': `Barear ${tocken}`,
                    "Content-Type": "application/json"
                }
            });
            removedata();

            return res.data;
        } catch (error) {
            //console.log(error);
            throw rejectWithValue(error.message);
        }
    }
);

// ----------------------------- PASSWORD RESET -------------------------
export const passwordReset = createAsyncThunk(
    "auth/reset-password",
    async (values, { rejectWithValue }) => {
        try {
            // console.log(values);
            let res = await Axios.post(
                "/auth/reset-password",
                values
            );
            res = res.data;
            if (res.status === "ERROR") {
                toast.error(res.message);
            } else {
                toast.success(res.message);
            }
            return res;
        } catch (error) {
            throw rejectWithValue(error.message);
        }
    }
);


//// -------------------------- Verify Otp -----------------------------
export const VerifyOtp = createAsyncThunk(
    "auth/verify-otp",
    async (values, { rejectWithValue }) => {
        try {
            let res = await Axios.post("auth/verify-otp", values);
            const { access_token } = res.data
            tocken = access_token;
            storedata(res.data);
            if (tocken) {
                await getUserProfileDataAPI();
            }
            return res.data;
        } catch (error) {
            throw rejectWithValue(error.message);
        }
    }
);


export const ResendOtp = createAsyncThunk(
    "auth/resend-otp",
    async (values, { rejectWithValue }) => {
        try {
            let res = await Axios.post("auth/resend-otp", values);
            localStorage.setItem('otp_auth_tocken', res.data.data.otp_auth_token);
            return res.data;
        } catch (error) {
            throw rejectWithValue(error.message);
        }
    }
);

/////// get profile details api function 
export const getUserProfileData = async () => {
    try {
        let user_id = localStorage.getItem('user_id');
        let value = { "user_id": user_id }
        let res = await Axios.get("profile/get");
        let { status, data, message } = res.data;
        if (status == "SUCCESS") {
            const { account_properties } = data
            let sipUsername = account_properties?.sip?.username?.value?.[0] || '';
            let sipUsernameWithInstance = sipUsername;
            let sipDomain = account_properties?.sip?.sipServer?.value?.[0] || '';
            let sipPassword = account_properties?.sip?.password?.value?.[0] || '';
            let sipHa1 = account_properties?.sip?.password?.value?.[0] || '';
            let sipPort = account_properties?.sip?.sipwssPort?.value?.[0] || '7443';
            let sipProtocol = account_properties?.sip?.sipProtocol?.value?.[0] || '';
            let outboundProxy =
                account_properties?.sip?.outboundProxyServer?.value?.[0] || '';
            let obProxyPort = account_properties?.sip?.outboundProxyPort?.value[0] || '';
            let sipData = {
                "_sipExtension": sipUsername,
                "_sipPassword": sipPassword,
                "_sipServer": sipDomain,
                "_sipUsernameWithInstance": sipUsernameWithInstance,
                "_wssServer": `wss://${sipDomain}:${sipPort}`,
                "_extNumber": sipUsername,
                "_instanceId": "",
                "_sipAor": sipUsernameWithInstance + '@' + sipDomain
            };
            // console.debug('get profile datail call')
            // ACCOUNT CREDENTIALS
            localStorage.setItem('_accountCreds', JSON.stringify(sipData));
        }
        AddSettings(data.account_properties);
        return { data, status, message };
    } catch (error) {
        console.log(error);
    }
};

export const setUsersSettings = createAsyncThunk('usersetting', async (_, { rejectWithValue }) => {
    try {
        let res = await getUserProfileData();
        console.debug('set user Settings', res.data)
    } catch (error) {
        throw rejectWithValue(error);
    }
})



///// get profile detils get and this function user for store response in redux
export const getUserProfileDetails = createAsyncThunk('getUserProfileDetails', async (_, { rejectWithValue }) => {
    try {
        let res = await getUserProfileData();
        // console.debug('res', res)
        let { account_properties } = res.data;

        let sipUsername = account_properties?.sip?.username?.value?.[0] || '';
        let sipUsernameWithInstance = sipUsername;
        let sipDomain = account_properties?.sip?.sipServer?.value?.[0] || '';
        let sipPassword = account_properties?.sip?.password?.value?.[0] || '';
        let sipHa1 = account_properties?.sip?.password?.value?.[0] || '';
        let sipPort = account_properties?.sip?.sipwssPort?.value?.[0] || '7443';
        let sipProtocol = account_properties?.sip?.sipProtocol?.value?.[0] || '';
        let outboundProxy =
            account_properties?.sip?.outboundProxyServer?.value?.[0] || '';
        let obProxyPort = account_properties?.sip?.outboundProxyPort?.value[0] || '';
        let sipData = {
            "_sipExtension": sipUsername,
            "_sipPassword": sipPassword,
            "_sipServer": sipDomain,
            "_sipUsernameWithInstance": sipUsernameWithInstance,
            "_wssServer": `wss://${sipDomain}:${sipPort}`,
            "_extNumber": sipUsername,
            "_instanceId": instanceId,
            "_sipAor": sipUsernameWithInstance + '@' + sipDomain
        };
        // console.debug('get profile datail call')
        // ACCOUNT CREDENTIALS
        // localStorage.setItem('_accountCreds', JSON.stringify(sipData));

        return { account_properties, sipData }
    } catch (error) {
        throw rejectWithValue(error)
    }
});


//pushSunscriber api call function
export const pushSubscribe = async (values) => {
    // value ===> { "device_token": "r8pbxrrfp4pkdilr5af7t92474r0m9je", "device_type": "deskapp", "instance_id": "ddddr8pbxrrfp4pkdilr5af7t92474r0m9je", "auth_type": "auto", "device_voip_token": "", "device_apns_token": "", "sip_username_with_instance": "ddddr8pbxrrfp4pkdilr5af7t92474r0m9je-9112345678", "sip_domain": "81.22.16.245", "sip_password": "X04?Ogl3", "sip_ha1": "X04?Ogl3", "sip_port": "5060", "sip_protocol": "udp", "outbound_proxy": "", "ob_proxy_port": "" }
    try {
        let res = await Axios.post("push/subscribe", values, {
            headers: {
                'Authorization': `Barear ${tocken}`
            }
        });
        // return res.data
        return res.data;
    } catch (error) {
        console.log(error);
    }
};


/// deletePushSubscriber api call function this function call when user try to logout
export const deletePushSubscriber = async (values) => {
    try {
        let res = await Axios.delete("push/subscribe", values, {
            headers: {
                'Authorization': `Barear ${tocken}`
            }
        })
        // console.debug('res', res)
        return res.data;
    } catch (error) {
        console.log('delete pushSubscriber', error)
    }
}



///// pcs Register api call function
export const pcsRegister = async (data, action) => {
    let aor = data.instance_id + '-' + data.sip_username_with_instance + "@" + data.sip_domain;
    let values = { aor, action: action };
    try {
        let res = await Axios.post("pcs/register", values);
        return res.data
    } catch (error) {
        console.log(error);
    }
}

///// pcs status api function 
export const pcsStatus = async (data) => {
    let aor = data.instance_id + '-' + data.sip_username_with_instance + "@" + data.sip_domain
    let values = { aor };
    try {
        let res = await Axios.post("pcs/status", values);
        return res.data
    } catch (error) {
        console.log('pcs status api error', error)
    }
}

export const profileUpdate = async (values) => {
    try {
        let res = await Axios.post("profile/update", values, {
            headers: {
                'Authorization': `Barear ${tocken}`
            }
        });
        return res.data
    } catch (error) {
        console.log(error)
    }
}

export const updateUserSettings = createAsyncThunk('update_user_settings', async (value, { rejectWithValue }) => {
    try {
        let res = await profileUpdate(value);
        let getsettinngs = await getUserProfileData();
    } catch (error) {
        throw rejectWithValue(error)
    }
})

/* Get Profile Configuration Data API Called */
export const getconfigData = async () => {
    try {
        let res = await Axios.get('profile/get-config-data', {
            headers: {
                'Authorization': `Barear ${tocken}`
            }
        });
        return res.data
    } catch (error) {
        console.log(error)
    }
}


/* GET Refresh Token */
export const refreshAuthToken = async () => {

    const refresh_token = localStorage.getItem('refresh_token')
    console.debug('referh', refresh_token)
    if (refresh_token) {
        try {
            let res = await Axios.get('/auth/refresh', {
                headers: {
                    "Authorization": `Barear ${refresh_token}`
                }
            })
            const { status, access_token } = res?.data
            if (status === "SUCCESS") {
                localStorage.setItem('access_token', access_token)
                return access_token;
            } else {
                localStorage.clear();
                window.location = "/";
            }
        } catch (error) {
            localStorage.clear();
            window.location.href = "/";
        }
    } else {
        localStorage.clear();
        window.location.href = "/";
    }

}

///store user data to local storage
function storedata(user) {
    if (user.data) {

        localStorage.setItem('user_id', user.data.usr_id)
        localStorage.setItem("user", JSON.stringify(user.data));
        localStorage.setItem("access_token", user.access_token);
        localStorage.setItem("refresh_token", user.refresh_token);
    }
}

function removedata() {
    let remove_data = [
        'refresh_token', 'dialNumber', 'user', 'access_token', '_accountCreds', '_profileConfigSettings', '_customSettings', 'user_id', 'connected', 'fcmtocken'
    ];
    remove_data.map((item) => localStorage.removeItem(item))
}

export const getUserProfileDataAPI = async () => {

    getUserProfileData().then((data) => {
        // console.info('  getUserProfileData api calling ===>', data)
        let { account_properties } = data.data;

        let sipUsername = account_properties?.sip?.username?.value?.[0] || '';
        let sipUsernameWithInstance = sipUsername;
        let sipDomain = account_properties?.sip?.sipServer?.value?.[0] || '';
        let sipPassword = account_properties?.sip?.password?.value?.[0] || '';
        let sipHa1 = account_properties?.sip?.password?.value?.[0] || '';
        let sipPort = account_properties?.sip?.sipwssPort?.value?.[0] || '7443';
        let sipProtocol = account_properties?.sip?.sipProtocol?.value?.[0] || '';
        let outboundProxy =
            account_properties?.sip?.outboundProxyServer?.value?.[0] || '';
        let obProxyPort = account_properties?.sip?.outboundProxyPort?.value[0] || '';

        let values = {
            device_token: device_token,
            device_type: device_type,
            instance_id: instanceId,
            auth_type: "auto",
            device_voip_token: "",
            device_apns_token: "",
            sip_username_with_instance: sipUsernameWithInstance,
            sip_domain: sipDomain,
            sip_password: sipPassword,
            sip_ha1: sipHa1,
            sip_port: sipPort,
            sip_protocol: sipProtocol,
            outbound_proxy: outboundProxy,
            ob_proxy_port: obProxyPort,
        };

        pushSubscribe(values).then(({ data }) => {
            //  console.info('pushSubscriber api calling ===>', data)
            pcsRegister(data, 'enable').then((res) => {

                let val = {
                    "audio.audioCodecs": getConfigSettings("audio.audioCodecs"),
                    "video.videoCodecs": getConfigSettings("video.videoCodecs"),
                    "call.encryption": getConfigSettings("call.encryption"),
                    "call.dtmfType": getConfigSettings("call.dtmfType"),
                    "sip.register.interval": getConfigSettings("sip.interval"),
                    "sip.register.respectServerExpires": getConfigSettings("sip.respectServerExpires")
                }
                profileUpdate(val).then((res) => {
                    // console.info('  profileUpdate api calling ===>', res)
                    getconfigData().then(({ data }) => {

                        let sms_service_status = getConfigSettings("sms.status");
                        let profileConfigData = {
                            "sms_service_status": sms_service_status,
                            "balance_status": data.balance_status,
                            "recharge_status": data.recharge_status,
                            "toll_free_flag": data.toll_free_flag,
                            "toll_free_number": data.toll_free_number,
                            "im_service_status": data.im_status
                        };
                        AddSettings(data)


                        /**  Profile Config Settings */
                        localStorage.setItem('_profileConfigSettings', JSON.stringify(profileConfigData));
                        //  SIP data for local storage
                        let sipData = {
                            "_sipExtension": sipUsername,
                            "_sipPassword": sipPassword,
                            "_sipServer": sipDomain,
                            "_sipUsernameWithInstance": sipUsernameWithInstance,
                            "_wssServer": `wss://${sipDomain}:${sipPort}`,
                            "_extNumber": sipUsername,
                            "_instanceId": instanceId,
                            "_sipAor": sipUsernameWithInstance + '@' + sipDomain
                        };
                        // console.debug('sipData')

                        // ACCOUNT CREDENTIALS
                        // localStorage.setItem('_accountCreds', JSON.stringify(sipData));

                        //  Custom Settings Data for local storage
                        let customSettingsData = {
                            "_dnd": false,
                            "_autoAnswer": false,
                            "_account": true,
                            "_pcsRegStatus": "NOT_REGISTERED_STATE",
                            "_sipRegStatus": "NOT_REGISTERED_STATE",
                            "_i18n": "en"
                        };
                        // Custom Settings
                        localStorage.setItem('_customSettings', JSON.stringify(customSettingsData));
                    }).catch((error) => {
                        console.log(error)
                    })
                }).catch((error) => {
                    console.log(error)
                })
            }).catch((error) => {
                console.log(error);
            })
        }).catch((error) => {
            console.log(error)
        })
    }).catch((error) => console.log('error', error));

}

