import { createSlice, nanoid } from '@reduxjs/toolkit'
import { call_cdr_sync, cleare_call_history, getEnterPriseContact } from './ContactService';
import dateFormat, { masks } from "dateformat";
import { toast } from 'react-toastify';
const now = new Date();


const user_id = localStorage.getItem('user_id')
export const callType = Object.freeze({
    incoming: "incoming",
    outgoing: "outgoing",
    missedCall: 'missedCall'

})
let allHistroy = JSON.parse(localStorage.getItem('callhistory')) || []
const getUserHistory = () => {
    let history = [];
    const user_id = localStorage.getItem('user_id');
    let allHistroy = JSON.parse(localStorage.getItem('callhistory'));
    if (![null, undefined, ''].includes(allHistroy)) {
        history = allHistroy.filter((elem) => elem.user_id == user_id);
    }
    return history;
}
let userhis = getUserHistory();
const initialState = {
    contacts: [],
    isLoading: false,
    isSucess: true,
    isError: false,
    status: "ERROR",
    callhistory: allHistroy,
    dialnumber: '',
    userHistory: userhis
}

const accountCreds = JSON.parse(localStorage.getItem('_accountCreds'))
let coller = accountCreds?._sipExtension;

export const contactSlice = createSlice({
    name: "contacts",
    initialState,
    reducers: {
        searchContact: (state, action) => {
            state.contacts = action.payload
        },
        removeFromHistory: (state, action) => {
            state.callhistory = state.callhistory.filter((ele) => ele.id != action.payload);
            localStorage.setItem('callhistory', JSON.stringify(state.callhistory))
            toast.success("Call history Deleted successfully");
        },
        clearHistory: (state, action) => {
            state.userHistory = [];
        },
        addDialNumber: (state, action) => {
            state.dialnumber = action.payload
        },
        setAllCallHistory: (state, action) => {
            state.callhistory = action.payload?.callHistory
            state.userHistory = action.payload?.userHistory
        },
        addToCallHistory: (state, action) => {
            let user_id = localStorage.getItem('user_id');

            const { direction, number, missedCall, call_type } = action.payload
            let call = {
                "_id": nanoid(),
                'user_id': user_id,
                'callee': number,
                'caller': coller,
                'call_direction': direction,
                'call_type': call_type,
                'missed_call': missedCall,
                'start_time': dateFormat(now, "d  mmm, yyyy h:MM TT")
            }
            let callHistory = [...state.callhistory, call]
            callHistory.reverse();
            state.callhistory = callHistory;
            let userHistory = [...state.userHistory, call];

            userHistory.reverse();
            state.userHistory = userHistory;
            localStorage.setItem('callhistory', JSON.stringify(callHistory))
        },
    },
    extraReducers: (builder) => {
        builder.addCase(getEnterPriseContact.pending, (state) => {
            state.isLoading = true;
        })
            .addCase(getEnterPriseContact.fulfilled, (state, action) => {
                const { data, status } = action.payload

                if (status === "SUCCESS") {
                    state.contacts = data;
                    state.isError = false;
                } else {
                    state.contacts = null;
                    state.isError = true;
                }
                state.isLoading = false;
            })
            .addCase(getEnterPriseContact.rejected, (state, action) => {
                state.isError = true;
                state.isLoading = false
            })
            .addCase(call_cdr_sync.pending, (state) => {
                state.isLoading = true;
            })
            .addCase(call_cdr_sync.fulfilled, (state, action) => {
                state.isLoading = false;
                const { status } = action.payload;
                if (status === 'SUCCESS') {
                    // state.callhistory = action?.payload?.data
                    state.userHistory = action?.payload?.data
                }
            })
            .addCase(cleare_call_history.fulfilled, (state, action) => {
                state.isLoading = false;
                const { status } = action.payload;
                console.debug(action.payload);
                if (status === 'SUCCESS') {
                    state.userHistory = []
                    userhis = []
                    state.callhistory = action.payload?.callhistory
                    toast.success('Call History Cleare')
                }
            })
    }

})


export default contactSlice.reducer;
export const { searchContact, removeFromHistory, clearHistory, addToCallHistory, addDialNumber, setAllCallHistory } = contactSlice.actions