import React, { useRef, useState } from 'react'
import Draggable, { DraggableCore } from 'react-draggable'
import VideoCallActionButton from './VideoCallActionButton'
import { useDispatch, useSelector } from 'react-redux'
import { updateThemeProperties } from '../../store/themeSlice'
import { useEffect } from 'react'
import { setupRemoteMedia } from '../../store/sipjs/sipjsService'
import { getConfigSettings } from '../../data/userSettings'


const VideoCallMinimize = () => {
    const { minimizeVideoCall } = useSelector((state) => state.theme)
    const { userSettings } = useSelector((state) => state.auth);
    const { sessions, callDirection, sessionId, sessionState } = useSelector(
        (state) => state.sipjs
    );
    const nodeRef = useRef(null);
    const selfView = getConfigSettings("video.selfview", userSettings);
    const [self_view, setSelfiview] = useState(selfView)

    // let number = sessions[sessionId]?.remoteIdentity?.uri?.normal?.user;
    let number = sessions[sessionId]?.remoteIdentity?.uri?.normal?.user;

    const dispatch = useDispatch()
    const fullScreenVideo = () => {
        dispatch(updateThemeProperties({ key: "minimizeVideoCall", value: false }))
    }
    //  Set remote media stream on connect
    const setRemoteMediaForVideoCall = async () => {
        setTimeout(async () => {
            var session = sessions[sessionId];
            // Setup remote media after page Load for outgoing request
            if (typeof session.outgoingInviteRequest !== "undefined") {
                if (sessionId && session) {
                    await setupRemoteMedia(session, true);
                }
            }
        }, 500);
    }
    useEffect(() => {
        setRemoteMediaForVideoCall()
    }, [])

    useEffect(() => {
        setSelfiview(getConfigSettings("video.selfview", userSettings))
        setRemoteMediaForVideoCall()
    }, [userSettings])
    useEffect(() => {
        console.debug(nodeRef, 'node ref')
    })
    return (
        <>
            {/* <!-- ACTIVE VIDEO CALL MINIMIZE POPUP :: STARTS--> */}

            <Draggable defaultPosition={{ x: 10, y: 400 }} nodeRef={nodeRef} allowAnyClick={true}>
                <div ref={nodeRef}>

                    <div className="videocall-minimize position-fixed " id="divActiveVideoCallMinimisePopup" style={{ border: "1px solid var(--bs-gray)" }}>
                        {/* <!-- Remote user name --> */}
                        <span className="name" id="onGoingVideoCallDisplayNumberMinimise">{number}</span>

                        {/* <!-- Remote user video stream --> */}
                        <video className="_remoteVideoMiniPopup" id={minimizeVideoCall ? 'remoteVideoElement' : "remoteVideoMiniPopup"}></video>
                        {["true", true, 1, "1", "TRUE"].includes(self_view) && <video className='localMinimizeVideo' id={minimizeVideoCall ? 'localVideoElement' : "localVideoMiniPopup"}></video>}

                        {/* <!-- Maximise to maximise button --> */}
                        <span onClick={fullScreenVideo}>
                            <svg id="btnVideoCallMinimiseToMaximise" className="maximize-arrow position-absolute cursor-pointer" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ background: "var(--main-gradient-color)" }}>
                                <rect x="2" y="2" width="18" height="18" rx="2" stroke="white" strokeWidth="2.3" />
                            </svg>
                        </span>

                        <VideoCallActionButton />
                    </div>
                </div>

            </Draggable>

            {/* <!-- ACTIVE VIDEO CALL MINIMIZE POPUP :: ENDS--> */}
        </>
    )
}

export default VideoCallMinimize