import React, { useEffect, useState } from "react";

import { useDispatch, useSelector } from "react-redux";
import Header from "./Header";
import SiteSidebar from "./Sidebar";
import MainScreen from "./MainScreen";
import useCreateUa from "../../hooks/useCreateUa";
import CallingScreen from "../Calling/CallingScreen";
import { getUserProfileDetails } from "../../store/Auth/authService";
import useCallTimerDuration from "../../hooks/useCallTimerDuration";
import MobileMenu from "./MobileMenu";
import { _changePlatformLanguage } from "../../utils/Locale";

import ringtoneSound from '../../assets/sounds/ringtone.mp3'
import { SidebarTab } from "../../store/themeSlice";
import VideoCallMinimize from "../Calling/VideoCallMinimize";


const Deshboard = () => {
  const { Sidebar, hideSidebar, videocall, minimizeVideoCall } = useSelector(
    (state) => state.theme
  );

  const { customSettings, UserProfileDetails, accountCreds, user } = useSelector(
    (state) => state.auth
  );
  const { sessions, sessionState, connected } = useSelector((state) => state.sipjs);
  const [sidebarHide, setSidebarHide] = useState('d-none')
  const dispatch = useDispatch();
  const [CreateUA] = useCreateUa();

  const connect = async () => {
    CreateUA();
  };
  useEffect(() => {
    dispatch(getUserProfileDetails());
  }, []);
  const [calltimer, TimerAction] = useCallTimerDuration()
  ////// check user profile data set or not
  useEffect(() => {
    if (
      accountCreds?._sipExtension != "" &&
      accountCreds?._sipPassword != "" &&
      accountCreds?.__sipServer != ""
    ) {
      connect();
    }
  }, [accountCreds, customSettings, user]);

  useEffect(() => {
    if (!Sidebar?.openId && (Sidebar.tab == SidebarTab.settings)) {
      setSidebarHide('')
    } else {
      setSidebarHide('d-none')
    }
    if (Sidebar?.openId) {
      setSidebarHide('d-none')
    }
  }, [Sidebar])


  useEffect(() => {
    _changePlatformLanguage();
  })



  return (
    <>
      {/* <VideoCallMinimize /> */}
      <audio id="mediaElement"></audio>
      <audio id="connectingRigton" src={ringtoneSound} loop>
      </audio>
      {videocall && minimizeVideoCall && <VideoCallMinimize />}
      <div className="container-fluid call-connecting-minimize-container main-container position-relative p-0">

        <Header />
        <CallingScreen />
        <section className="content" id="content">
          <div className="container-fluid bg-light-gery">
            <div className="row">
              {/* sidebar start */}
              <div
                className={
                  `col-lg-3 col-md-4 col-sm-12 side-menu-left d-md-block ` +
                  sidebarHide
                }
              >
                <SiteSidebar />
              </div>


              {/* sidebar end  */}

              {/* main Content start */}
              <div className="col-lg-9 col-md-8 col-sm-12 p-0 side-menu-right">
                <MainScreen />
              </div>
              {/* main Content end */}
            </div>
          </div>
        </section>
      </div>
      <MobileMenu />
    </>
  );
};

export default Deshboard;
