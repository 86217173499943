import React from 'react'

import DeshboardImage from '../assets/app-icon/icon/dashboard-img.svg'
const HomePage = () => {
  return (
    <>
      <div className="container-fluid dashboard-container bg-light-gery">
        <div className="row">
          <div className="right-side-content">
            <img src={DeshboardImage} alt="DASHBOARD-IMG" height="" width="" />
            <h3 className="__MSG__getStarted">Get Started!</h3>
            <p className="__MSG__welcomemsg">Level-up your unified communications with our<br /> VoIP softphone</p>
            <div className="row button-container">
              <button className="col-lg-5 col-md-5 col-sm-12 m-2 outline-btn" id="openDialpad" data-bs-toggle="modal" data-bs-target="#dialPademodal">
                <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M13.23 9.62889C12.2733 9.62889 11.3478 9.47333 10.4844 9.19333C10.3492 9.14749 10.2038 9.14068 10.0648 9.1737C9.92588 9.20671 9.79906 9.27821 9.69889 9.38L8.47778 10.9122C6.27667 9.86222 4.21556 7.87889 3.11889 5.6L4.63556 4.30889C4.84556 4.09111 4.90778 3.78778 4.82222 3.51556C4.53444 2.65222 4.38667 1.72667 4.38667 0.77C4.38667 0.35 4.03667 0 3.61667 0H0.925556C0.505556 0 0 0.186667 0 0.77C0 7.99556 6.01222 14 13.23 14C13.7822 14 14 13.51 14 13.0822V10.3989C14 9.97889 13.65 9.62889 13.23 9.62889Z" fill="var(--primary-color)"></path>
                </svg>
                <span className="__MSG__callLabel">Call</span>
              </button>
              <button className="col-lg-5 col-md-5 col-sm-12 m-2 outline-btn" id="">
                <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.869141 8C0.869141 12.2144 4.28559 15.6309 8.5 15.6309C9.91235 15.6309 11.2351 15.2472 12.3696 14.5783L16.1309 15.6309L15.0783 11.8696C15.7472 10.7351 16.1309 9.41235 16.1309 8C16.1309 3.78559 12.7144 0.369141 8.5 0.369141C4.28559 0.369141 0.869141 3.78559 0.869141 8Z" fill="var(--primary-color)"></path>
                  <path d="M10.6047 6.42114H6.39453" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
                  <path d="M10.8676 9.31567L7.70996 9.31567" stroke="white" strokeWidth="1.5" strokeLinecap="round"></path>
                </svg>
                <span className="__MSG__newChatMessageLabel">New Chat Message</span>
              </button>
            </div>
          </div>
        </div>
      </div>

    </>
  )
}

export default HomePage