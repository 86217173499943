import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { toggelHoldCall } from '../store/sipjs/sipjsService';

const useHoldUnholdCall = (toggle = true) => {
    const { sessions, callDirection, sessionId } = useSelector((state) => state.sipjs);
    const dispatch = useDispatch();
    const [showHoldButton, setShowHoldbutton] = useState(toggle)
    // console.debug('hold hook sessionid',sessionId)

    const toggeCallHoldUnhold = async (state) => {
        // console.debug('hold hook id ', sessionId)
        /// state false ==> unhold
        /// state true ==> hold
        let res = await dispatch(
            toggelHoldCall({ session: sessions[sessionId], state: state })
        );
        const { holdCall, unHoldCall } = res.payload;
        if (holdCall) {
            setShowHoldbutton(false)
        }
        if (unHoldCall) {
            setShowHoldbutton(true)
        }

    }
    return [showHoldButton, toggeCallHoldUnhold];
}

export default useHoldUnholdCall