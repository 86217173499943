import React from 'react'
import OnGoingUser from "../../assets/app-icon/icon/ongoing-user.svg";
import CallTimer from './CallTimer';
import { useSelector } from 'react-redux';
const CallInformation = ({ calltimer }) => {
    const { sessions, callDirection, sessionId, sessionState } = useSelector(
        (state) => state.sipjs
    );
    let dialNumber = sessions[sessionId]?.remoteIdentity?.uri?.normal?.user;
    return (
        <>
            <h3 id="onGoingCallContactDisplayName"></h3>
            <img
                id="onGoingCallContactProfileImage"
                src={OnGoingUser}
                alt="DIALER"
                width=""
                height=""
            />
            <p id="onGoingCallContactDisplayNumber">{dialNumber}</p>
            <div id="timerToCheck" className="call-time _timer">
                {calltimer}
            </div>
        </>
    )
}

export default CallInformation