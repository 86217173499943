
export  const button_list = [
    {
        value: "1",
        button_label: ''
    },
    {
        value: "2",
        button_label: 'ABC'
    },
    {
        value: "3",
        button_label: 'DEF'
    },
    {
        value: "4",
        button_label: 'GHI'
    },
    {
        value: "5",
        button_label: 'JKL'
    },
    {
        value: "6",
        button_label: 'MNO'
    },
    {
        value: "7",
        button_label: 'PQRS'
    },
    {
        value: "8",
        button_label: 'TUV'
    },
    {
        value: "9",
        button_label: 'WXYZ'
    },
    {
        value: "*",
        button_label: ''
    },
    {
        value: "0",
        button_label: '+'
    },
    {
        value: "#",
        button_label: ''
    }

]
