import React from 'react'
import { callhistoryTab } from '../Sidebar/CallHistorySidebar'
import { useDispatch, useSelector } from 'react-redux'
import { SidebarTab, openSidebar } from '../../store/themeSlice'
const CallHistoryMobileTab = () => {
    const { openId } = useSelector((state) => state.theme.Sidebar)
    const dispatch = useDispatch();
    return (
        <>
            <div className="d-md-none d-sm-flex align-items-center justify-content-center py-3">
                <div className='d-flex align-items-center justify-content-center' style={{ gap: '30px' }}>
                    {callhistoryTab.map((elem) => <button className={`btn  mx-1 ${elem.transKey} ` + (openId === elem.tab ? ' button-gradient ' : 'button-outline ')} key={elem.id}
                        onClick={() => dispatch(dispatch(openSidebar({ tab: SidebarTab.callHistory, key: elem.tab })))}
                    >{elem.icon}</button>)}
                </div>
            </div>
        </>
    )
}

export default CallHistoryMobileTab