import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggelHoldCall } from '../store/sipjs/sipjsService'
import { updateSipProperties } from '../store/sipjs/sipjsSlice'

const useConferenceCallMerge = () => {
    const { holdUnholdStates, sessions, sessionId } = useSelector((state) => state.sipjs)
    const dispatch = useDispatch()
    const callMergerer = async () => {
       


        for (let _sessionId in sessions) {
         

            if (_sessionId != sessionId) {
                let prevSession = sessions[_sessionId];
                if (prevSession && prevSession.state == "Established") {
                    let _prevCallId = prevSession.incomingInviteRequest
                        ? prevSession.incomingInviteRequest.message.callId
                        : prevSession.outgoingInviteRequest.message.callId;


                    // unhold session 
                    dispatch(toggelHoldCall({ session: prevSession, state: false }))

                    // await this.unholdSession(_sessionId);
                    let currentSession = sessions[sessionId];
                  
                    setTimeout(() => {
                        if (
                            currentSession &&
                            _prevCallId &&
                            currentSession.state == "Established"
                        ) {
                            const options = {
                                requestOptions: {
                                    extraHeaders: [
                                        `org-Call-ID: ${_prevCallId}`,
                                        "Event-Type: conf",
                                    ],
                                },
                            };
                            currentSession.info(options);
                        

                        }
                    }, 2000);
                    dispatch(updateSipProperties({ key: "conferenceCallMerge", value: true }))
                }

            }

        }
    }
    return [callMergerer]
}

export default useConferenceCallMerge