import React, { useState } from 'react';
import { useSelector } from 'react-redux';

const useVideoPlayPause = () => {
  const { sessions, sessionId } = useSelector((state) => state.sipjs);
  const [playvideo, setPlay] = useState(true);
  let session = sessions[sessionId]
  /**    videoPausePlay : This function will be used when pause and play local video streams. */
  const videoPausePlay = (state) => {
    console.log(' VideoPause/Play Status: ' + state);
    let pc = session.sessionDescriptionHandler.peerConnection;
    /** To stop Remote Stream **/
    // pc.getRemoteStreams().forEach(function (stream) {
    //     stream.getVideoTracks().forEach(function (track) {
    //         track.enabled = state;
    //     });
    // });
    const localVideoElement = document.getElementById('localVideoElement');
    if (typeof (localVideoElement) != 'undefined' && localVideoElement != null) {
      if (state) {
        setPlay(true)
        localVideoElement.play();
        localVideoElement.classList.remove('blurme');
      } else {
        setPlay(false)
        localVideoElement.pause();
        localVideoElement.classList.add('blurme');
      }
      pc.getLocalStreams().forEach(function (stream) {
        stream.getVideoTracks().forEach(function (track) {
          track.enabled = state;
        });
      });
    }
  }
  return [playvideo, videoPausePlay];

}

export default useVideoPlayPause;
