import React from "react";
import TransferUserIcon from "../../assets/img/transfer-user.svg";
import OngoingUser from "../../assets/app-icon/icon/ongoing-user.svg";
import MinimizeCallPopup from "./MinimizeCallPopup";
import CallUserCard from "./CallUserCard";
import CallingActionButton from "./CallingActionButton";
import { useSelector } from "react-redux";
import ConferenceCallUserDisplay from "./ConferenceCallUserDisplay";
const TransaferCall = ({ calltimer }) => {
  const { holdUnholdStates, sessions, conferenceCallMerge } = useSelector((state) => state.sipjs)
  return (
    <>
        {/*  ACTIVE CALL ATTANDANT CONTAINER :: STARTS */}
        <div className="call-connecting-info active-call-attandent-info" id="divActiveCallAttendentTransfer">
        {conferenceCallMerge && <ConferenceCallUserDisplay calltimer={calltimer} />}
          {/*  Attended transfer call information */}
          <div className={`d-flex justify-content-center align-items-center flex-wrap ` + (conferenceCallMerge ? ' callConferenceSquare ' : " ")}>
            {Object.keys(holdUnholdStates).map((sessionId) => {
              let number = sessions[sessionId]?.remoteIdentity?.uri?.normal?.user;
              let holdSession = holdUnholdStates[sessionId] == "hold" ? true : false;
              return <CallUserCard hold={holdSession} number={number} type={(conferenceCallMerge ? 'conference' : 'transfer')} sessionId={sessionId} key={sessionId} />
            })}
          </div>
        </div>
    </>
  );
};

export default TransaferCall;
