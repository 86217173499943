import { createBrowserRouter } from "react-router-dom";
import RootLayoute from "./component/RootLayoute";
import AuthLayout from "./component/Auth/AuthLayout";
import UserLogin from "./page/Auth/UserLogin";
import OtpLogin from "./page/Auth/OtpLogin";
import OtpVerify from "./page/Auth/OtpVerify";
import PasswordReset from "./page/Auth/PasswordReset";
import AuthLayouteOld from "./component/Auth/AuthLayouteOld";
import AuthLayouteNew from "./component/Auth/AuthLayouteNew";

const router = createBrowserRouter([

    {
        path: "/",
        element: <AuthLayouteOld />,
        children: [
            {
                path: 'login',
                element: <UserLogin />

            },
            {
                path: 'otp-login',
                element:<OtpLogin />
            },
            {
                path: 'otp-verify',
                element: <OtpVerify />
            },
            {
                path: 'reset-password',
                element: <PasswordReset />
            }
        ]
    },
    {
        path: '/',
        element: <RootLayoute />,
        index: true
    }

]);
export default router;