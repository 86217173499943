import React from 'react'
import Logo from '../../assets/app-icon/icon/logo.svg'
import { Link, useNavigate } from 'react-router-dom'
import { FaArrowLeft } from 'react-icons/fa';
import { MdArrowBackIosNew } from 'react-icons/md';
const PasswordReset = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className="login-left-section">
                <div className="logo">
                    <img src={Logo} alt="LOGO" />
                </div>

                <Link className="back _backToLoginScreen" to={'/login'}>
                    <MdArrowBackIosNew />
                    Back
                </Link>

                <div className="login-form">
                    <h3 className="heading">Reset Password</h3>
                    <p className="sub-heading">Enter your username.</p>
                    <div className="col-lg-12">
                        <div className="input-group">
                            <div className="input-group-text border-end-0">
                                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M9.937 9.938C10.9198 9.938 11.8805 9.64657 12.6976 9.10057C13.5148 8.55457 14.1517 7.77852 14.5278 6.87056C14.9038 5.96259 15.0023 4.96349 14.8105 3.9996C14.6188 3.03571 14.1455 2.15032 13.4506 1.45539C12.7557 0.760461 11.8703 0.28721 10.9064 0.0954802C9.94251 -0.0962497 8.94341 0.00215283 8.03545 0.378245C7.12748 0.754336 6.35143 1.39123 5.80543 2.20837C5.25943 3.02552 4.968 3.98623 4.968 4.969C4.96774 5.62161 5.09608 6.26788 5.34571 6.87087C5.59533 7.47385 5.96133 8.02174 6.4228 8.4832C6.88427 8.94467 7.43215 9.31067 8.03514 9.5603C8.63812 9.80992 9.28439 9.93827 9.937 9.938ZM9.937 12.422C6.621 12.422 0 14.087 0 17.391V19.875H19.874V17.391C19.874 14.087 13.254 12.422 9.937 12.422Z" fill="var(--primary-color)"></path>
                                </svg>
                            </div>
                            <input type="text" id="username" className="form-control border-start-0" placeholder="Enter Username" />
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <button id="clickToResetPassword" type="button" className="col-lg-12 col-md-12 col-sm-12 common-btn gradient-btn">Continue</button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PasswordReset