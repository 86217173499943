import axios from "axios";
import { API_BASE_URL } from "../config";
import { getAccessToken, getReferssToken } from "./Utils";
let tocken = localStorage.getItem("access_tocken");
const Axios = axios.create({
    baseURL: API_BASE_URL,
    headers: {
        "Content-type": "application/json",
    },

});

Axios.interceptors.request.use(async (config) => {
    const token = getAccessToken();
    if (token) {
        config.headers["Authorization"] = `Bearer ${token}`;
    }
    return config;
}, (error) => {
    return Promise.reject(error);
});

Axios.interceptors.response.use(
    (response) => {
        return response;
    },
    async function (error) {
        const originalRequest = error.config;
        if (error.response.status === 403 && !originalRequest._retry) {
            let refresh_token = localStorage.getItem('refresh_token')
            if (refresh_token) {
                return axios
                    .post(API_BASE_URL + 'auth/refresh', {
                        refresh_token: refresh_token,

                    }, {
                        headers: {
                            "Authorization": `Bearer ${refresh_token}`
                        }
                    })
                    .then(res => {
                        const { status, access_token } = res.data;
                        if (status == "SUCCESS") {
                            localStorage.setItem('access_token', access_token);
                            Axios.defaults.headers.common['Authorization'] = 'Bearer ' + getAccessToken()
                            return Axios(originalRequest)
                        } else {
                            localStorage.clear()
                            return window.location.href = '/login'
                        }
                    }).catch((error) => {
                        const { status } = error?.response?.data
                        if (error?.response?.status === 403 && status === "ERROR") {
                            localStorage.clear()
                            return window.location.href = '/login'
                        }
                    })
            } else {
                localStorage.clear()
                return window.location.href = '/login'
            }

        }

        return Promise.reject(error);
    }
);

export default Axios