import React from 'react'

const Search = ({ setSerachVal,placeHolder }) => {
    const searchHandler = (e) => {
        setSerachVal(e.target.value)
    }
    return (
        <>
            <span className="position-relative">
                <input className="__MSG__searchcontact" type="search" placeholder={placeHolder ? placeHolder : 'Search Contacts'} onChange={searchHandler} />
                <svg className="search-icon" width="19" height="18" viewBox="0 0 19 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M7.07416 0.0374223C6.04086 0.141441 4.92712 0.507496 4.00418 1.04641C3.23696 1.49443 2.34414 2.30108 1.78326 3.05296C1.26625 3.74605 0.904051 4.43475 0.650491 5.20672C0.147102 6.7395 0.147102 8.414 0.650491 9.94678C0.904051 10.7187 1.26625 11.4074 1.78326 12.1005C2.34414 12.8524 3.23696 13.6591 4.00418 14.1071C5.49852 14.9797 7.20881 15.313 8.9434 15.0696C10.1724 14.8972 11.5001 14.3389 12.4269 13.6049C12.5457 13.5108 12.6563 13.4338 12.6725 13.4338C12.6888 13.4338 13.7077 14.437 14.9368 15.6631C16.8657 17.5874 17.1944 17.9009 17.3386 17.9544C17.8984 18.1619 18.4349 17.6255 18.2273 17.0658C18.1739 16.9216 17.8603 16.593 15.9357 14.6643C14.7094 13.4354 13.706 12.4166 13.706 12.4004C13.706 12.3841 13.7831 12.2736 13.8772 12.1548C14.2208 11.7212 14.6131 11.041 14.8447 10.4774C15.7527 8.26816 15.5818 5.79179 14.3795 3.73332C14.123 3.2942 13.6105 2.64209 13.197 2.22868C12.7836 1.81528 12.1313 1.30285 11.6922 1.04641C10.3024 0.234837 8.66553 -0.12281 7.07416 0.0374223ZM8.86846 1.48577C9.71425 1.64172 10.383 1.89274 11.0674 2.31124C12.2488 3.03371 13.1825 4.17539 13.6473 5.46612C13.9156 6.21124 14.0028 6.72863 14.0028 7.57675C14.0028 8.42487 13.9156 8.94226 13.6473 9.68738C13.0383 11.3784 11.6504 12.7661 9.95912 13.375C9.21389 13.6433 8.69642 13.7304 7.84818 13.7304C7.20589 13.7304 6.87858 13.695 6.37051 13.5706C4.18266 13.0348 2.40912 11.2776 1.86988 9.11157C1.71997 8.50937 1.69235 8.27066 1.69235 7.57675C1.69235 6.88284 1.71997 6.64413 1.86988 6.04193C2.40472 3.89355 4.1618 2.13662 6.31313 1.59898C6.60764 1.52538 6.79453 1.49165 7.28526 1.42348C7.50487 1.39301 8.59862 1.43603 8.86846 1.48577Z" fill="#BDBDBD"></path>
                </svg>
            </span>
        </>
    )
}

export default Search