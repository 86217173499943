import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { callhistoryTab } from '../Sidebar/CallHistorySidebar'
import PageTitle from '../Layoute/PageTitle'
import Search from '../Search'
import CleareHistory from './CleareHistory'
import CallHistoryAccordion from './CallHistoryAccordion'
import CallHistoryLoad from '../Loadding/CallHistoryLoad'
import { useEffect } from 'react'
import CallHistoryMobileTab from './CallHistoryMobileTab'
import { call_cdr_sync } from '../../store/Contacts/ContactService'
import { setAllCallHistory } from '../../store/Contacts/contactSlice'


const CallHistory = () => {
    const { openId } = useSelector((state) => state.theme.Sidebar)
    const { user_id } = useSelector((state) => state.auth)
    const { callhistory, isLoading, userHistory } = useSelector((state) => state.contacts)
    const [allcallHistory, setAllCallHistroy] = useState(callhistory)
    const [history, setHistory] = useState()
    const [search, setSerachVal] = useState()
    const dispatch = useDispatch();
    useEffect(() => {
        setHistory(callhistoryTab.find((elem) => elem.tab == openId))
    }, [openId])

    const setAllCallHistorys = () => {
        let allHistory = JSON.parse(localStorage.getItem('callhistory'));
        let userHistory = []
        if (![null, undefined].includes(allHistory)) {
            userHistory = allHistory.filter((history) => history.user_id == user_id);
        }
        dispatch(setAllCallHistory({ callHistory: allHistory, userHistory }))
    }
    useEffect(() => { setAllCallHistorys ()},[])
    return (
        <>
            {![undefined, 'undefined', null].includes(history) && <PageTitle title={history.label} transkey={history.titleTransKey}>
                <div className="d-flex align-items-center">
                    <Search setSerachVal={setSerachVal} placeHolder={history.serachPlaceholder} />
                    <CleareHistory />
                </div>
            </PageTitle>
            }
            <CallHistoryMobileTab />
            {isLoading && userHistory.length == 0 && allcallHistory.length == 0 && <CallHistoryLoad key="fchbgfvhbnv" />}
            {!isLoading && <div className="calls-history" id='callHistory-tab'>
                <CallHistoryAccordion searchval={search} type="all" />
            </div>
            }
        </>
    )
}

export default CallHistory