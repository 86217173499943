import React from 'react'
import { button_list } from '../../data/DialButtonList'
import DialPadeButton from './DialPadeButton'
const DialPadeButtonList = ({ typeNumber }) => {
    return (
        <>

            {button_list.map((btn) => <React.Fragment key={btn.button_label + Math.random() + btn.value}>
                <DialPadeButton button={btn} typeNumber={typeNumber} />
            </React.Fragment>)}
        </>
    )
}

export default DialPadeButtonList