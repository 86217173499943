import React from 'react'
import PageTitle from '../component/Layoute/PageTitle'
import { useDispatch, useSelector } from 'react-redux'
import { getSettingSidebarItem } from '../data/SettingItems'
import { SidebarTab, openSidebar } from '../store/themeSlice'


const Settings = () => {
    const settings = getSettingSidebarItem();
    const { Sidebar } = useSelector((state) => state.theme);
    const component = settings.filter((elem) => elem.transKey == Sidebar.openId)[0];
    const dispatch = useDispatch();
    const backTosetting = () => {
        dispatch(openSidebar({ tab: SidebarTab.settings }))
    }
    return (
        <>
            {!['', null, undefined, 'undefined'].includes(Sidebar.openId) &&
                <>
                    <PageTitle title={component.title} key={component.transKey} transkey={component.title_transkey} backbutton={true} backHandler={backTosetting} />
                    {component.component}
                </>}

        </>
    )
}

export default Settings