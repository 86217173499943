import React from 'react'

const LoaddingSpinner = () => {
    return (
        <>
            <div id="loading">
                <div className="spinner-box">
                    <div>
                        <span className="loader"></span>
                    </div>
                </div>
            </div></>
    )
}

export default LoaddingSpinner