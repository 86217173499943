import React from 'react'

const AddCallModalHeader = ({ number, setNumber, title, hide, hideContact }) => {

    const CleareNumber = () => {
        setNumber(number.substring(0, (number.length - 1)))
    }
    const changeHandal = (e) => {
        setNumber(e.target.value)
        hideContact(true)
    }
    return (
        <>
            <h5 className="modal-title" id="callTransferModalLabel">{title}</h5>
            <button type="button" className="btn-close" id="callTransferModalClose" data-bs-dismiss="modal" aria-label="Close" onClick={hide}>
                <svg width="20" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fillRule="evenodd" clipRule="evenodd" d="M11.3328 9.59625L2.41425 0.677734L0.646484 2.4455L9.565 11.364L0.646675 20.2823L2.41444 22.0501L11.3328 13.1318L19.6438 21.4428L21.4115 19.675L13.1005 11.364L21.4117 3.05281L19.644 1.28505L11.3328 9.59625Z" fill="white"></path>
                </svg>
            </button>
            <div className="d-flex justify-content-between align-items-center mt-4">
                {/* <!-- Back or close --> */}
                <svg className="m-0 cursor-pointer" id="callTransferModalBack" width="20" height="18" viewBox="0 0 23 20" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={() => hideContact(false)}>
                    <path d="M10.2381 19L1.00732 10L10.2381 1M2.28938 10H21.0073" stroke="#fff" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
                {/* <!-- Transfer number or name input  --> */}
                <input type="text" className="transferNumber" id="transferNumber" value={number} onChange={changeHandal} />
                <svg className="m-0 cursor-pointer clearTransferNumber" width="31" height="28" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg" onClick={CleareNumber}>
                    <path fillRule="evenodd" clipRule="evenodd" d="M12.8353 0.865171C12.3528 0.961907 11.6011 1.34368 11.2132 1.68889C10.7421 2.10819 0.15538 15.3497 0.054234 15.6462C-0.0675306 16.0031 0.0204667 16.4796 0.270765 16.8186C1.08281 17.9184 10.9501 30.1582 11.214 30.393C11.6252 30.759 12.348 31.1148 12.8962 31.2209C13.1693 31.2739 17.3517 31.3021 24.9198 31.3021C35.0893 31.3021 36.5813 31.2871 36.9821 31.1804C38.324 30.8233 39.3577 29.7639 39.7091 28.3855C39.884 27.6994 39.884 4.38183 39.7091 3.69578C39.3577 2.3174 38.324 1.258 36.9821 0.900861C36.5805 0.794031 35.1009 0.7802 24.8509 0.78791C18.4303 0.792679 13.0233 0.827415 12.8353 0.865171ZM36.7609 3.52639C36.879 3.61653 37.0479 3.78901 37.1361 3.90976C37.2965 4.12922 37.2965 4.13423 37.2965 16.0406C37.2965 27.947 37.2965 27.9521 37.1361 28.1715C37.0479 28.2923 36.879 28.4647 36.7609 28.5549C36.5464 28.7185 36.5249 28.7188 24.9526 28.7395C14.3252 28.7584 13.3368 28.7491 13.0894 28.6292C12.8892 28.5321 11.539 26.8992 7.8632 22.3086C5.13715 18.9042 2.90679 16.0836 2.90679 16.0406C2.90679 15.9976 5.1366 13.1777 7.86195 9.77413C11.3184 5.45752 12.8951 3.5465 13.0749 3.45573C13.3107 3.33665 14.3219 3.32712 24.9393 3.34397C36.5267 3.36249 36.5463 3.36273 36.7609 3.52639ZM16.1332 9.68217L15.2796 10.557L17.9625 13.2988L20.6454 16.0406L17.981 18.7635L15.3167 21.4864L16.1915 22.3802L17.0664 23.2739L19.7317 20.552L22.3969 17.83L25.0621 20.552L27.7274 23.2739L28.6023 22.3802L29.4772 21.4864L26.8128 18.7635L24.1484 16.0406L26.8318 13.2983L29.5152 10.556L28.6403 9.6623L27.7654 8.76847L25.0812 11.5099L22.3969 14.2513L19.7317 11.5294C18.2658 10.0322 17.0485 8.80733 17.0267 8.80733C17.0047 8.80733 16.6027 9.20103 16.1332 9.68217Z" fill="#fff"></path>
                </svg>
            </div>
        </>
    )
}

export default AddCallModalHeader