import React, { useEffect } from "react";
import { useStopwatch } from "react-timer-hook";
import { pad } from "../../utils/Utils";
import { useSelector } from "react-redux";

const CallTimer = (props) => {
  const { userAgent, sessionState, sessions, calltimerStart, atxCallTimer } = useSelector((state) => state.sipjs);
  const { seconds, minutes, hours, start, pause, reset } = useStopwatch({
    autoStart: false,
  });

  const ongoingsession_state = sessions[Object.keys(sessions)[0]].state
  useEffect(() => {
    if (ongoingsession_state =="Established") {
      start();
    }
    if (ongoingsession_state =='Terminated'){
      reset();
      pause();
    }
  }, [ongoingsession_state]);

  return (
    <>
      <span>{pad(hours)}</span>:<span>{pad(minutes)}</span>:
      <span>{pad(seconds)}</span>
    </>
  );
};

export default CallTimer;
