import React, { useEffect } from 'react'
import { FaArrowLeft } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { toggleSidebar } from '../../store/themeSlice';
import { _changePlatformLanguage } from '../../utils/Locale';

const PageTitle = ({ children, title, transkey, backbutton,backHandler }) => {
    const dispatch = useDispatch();
    useEffect(() => {
        _changePlatformLanguage();
    }, [])
    return (<>
        <div className="call-header page-title">
            <div className="row justify-content-between w-100">
                <div className="col-md-7 col-12 mb-2">
                    <div className="flex">
                        {backbutton && <button className='back_button d-md-none inline-block'
                            onClick={backHandler}
                        ><FaArrowLeft /></button>}
                        <h3 className={`__MSG__${transkey} m-0 inline-block1`} style={{ display: 'inline-block' }}>{title}</h3>
                    </div>

                </div>
                <div className="col-md-5 col-12 d-flex justify-content-md-end"> {children} </div>
            </div>



        </div>
        <div className="divider"></div>
    </>
    )
}

export default PageTitle