import { createSlice } from "@reduxjs/toolkit";
import { getObjectCount } from "../../utils/Utils";
import { connectUA, hangup, toggelHoldCall } from "./sipjsService";
import dateFormat, { masks } from "dateformat";
const sessionState = localStorage.getItem("sessionState")
  ? localStorage.getItem("sessionState")
  : null;


export const ConnectedStatus = Object.freeze({
  failed: "failed",
  connected: "connected",
  connecting: "connecting"
});
export let sessionCount = 0;
const connected = localStorage.getItem('connected') || false;
const now = new Date();

const initialState = {
  connected: connected,
  regState: "Unregistered",
  userAgent: {},
  registerer: {},
  incomingSession: {},
  outgoingSession: {},
  sessionId: "",
  sessions: {},
  contactData: {},
  holdUnholdStates: {},
  showCallingScreeen: false,
  showVideoCallngScreen: false,
  sessionState: sessionState,
  dialNumber: null,
  holdCall: false,
  unHoldCall: false,
  showCallTransferStrip: false,
  blindTransferCallBtn: false,
  attendantCalltransferBtn: false,
  attendantCalltransfer: false,
  isConferenceCallPressed: false,
  conferenceCallMerge: false,
  conferenceCallHangup: false,
  invitationSession: {},
  callDirection: null,
  connectedTime: null,
  calltimerStart: false,
  atxCallTimer: false,
  connectingStatus: ConnectedStatus.failed //  connecting connected
};

export const sipjsSlice = createSlice({
  name: "sipjs",
  initialState,
  reducers: {
    updateSipProperties: (state, action) => {
      const { key, value } = action.payload;
      state[key] = value
    },
    setregState: (state, action) => {
      state.regState = action.payload
    },
    callEnd: (state) => {
      state.showCallingScreeen = false;
    },
   

    // this function use for remove session not set prvious session in into sessionid
    removeSpecificSession: (state, action) => {
      let sessions = state.sessions;
      sessions = Object.keys(sessions)
        .filter((fkey) => fkey !== action.payload)
        .reduce((obj, key) => {
          obj[key] = sessions[key];
          return obj;
        }, {});
      state.sessions = sessions;
    },
    //// this function used for remove session data and set session id in avilable session
    removeSessionData: (state, action) => {

      let sessions = state.sessions;
      /// remove session from sessions
      sessions = Object.keys(sessions)
        .filter((fkey) => fkey !== action.payload)
        .reduce((obj, key) => {
          obj[key] = sessions[key];
          return obj;
        }, {});
      state.sessions = sessions;
      let holdUnholdStates = Object.keys(state.holdUnholdStates)
        .filter((fkey) => fkey !== action.payload)
        .reduce((obj, key) => {
          obj[key] = state.holdUnholdStates[key];
          return obj;
        }, {});

      state.holdUnholdStates = holdUnholdStates
      /// remove session s form holdunhold state
      if (getObjectCount(sessions)) {
        let activeSessionId = sessions[Object.keys(sessions)[Object.keys(sessions).length - 1]].id;
        // console.log('active Sesssion id ', activeSessionId)
        // console.debug('remove session', sessions);
        state.sessionId = activeSessionId
        state.sessionState = sessions[activeSessionId].state
      } else {
        state.sessionId = null;
      }
      // let activeSessionId = state.sessions[Object.keys()[0]].id;
      // state.sessionId = activeSessionId
      sessionCount = getObjectCount(state.sessions)
    },
    setUserAgent: (state, action) => {
      state.userAgent = action.payload;
    },
    setRegisterer: (state, action) => {
      state.registerer = action.payload;
    },
    setCallIncomingOutGoingSession: (state, action) => {

      const { callDirection, session, sessionId } = action.payload;
      if (callDirection == "incoming") {
        state.incomingSession = session;
      } else {
        state.outgoingSession = session;
      }
      state.callDirection = callDirection;
      state.sessionId = sessionId;
      state.sessions[sessionId] = session;
      state.holdUnholdStates[sessionId] = "unhold";
      sessionCount = getObjectCount(state.sessions)
    },
    setConncetInfo: (state, action) => {
      state.connected = action.payload.connected;
      state.connectingStatus = action.payload.connectingStatus;
      state.connectedTime = action.payload.connectedTime;
    },
    setSessionState: (state, action) => {
      state.sessionState = action.payload
    }
  },
  extraReducers: (builder) => {
    builder

      .addCase(toggelHoldCall.pending, (state) => {
        console.debug("hold call state panding");
      })
      .addCase(toggelHoldCall.fulfilled, (state, action) => {
        console.debug("hold call fulfield", action);
        const { holdCall, unHoldCall, sessionId } = action.payload;
        state.holdUnholdStates[sessionId] = action.payload.holdUnholdStates;
        state.holdCall = holdCall;
        state.unHoldCall = unHoldCall;
      })
      .addCase(toggelHoldCall.rejected, (state, action) => {
        console.debug("hold call rejected", action);
      })
      .addCase(hangup.fulfilled, (state, action) => {
        const { sessionId } = action.payload;
        let sessions = state.sessions
        sessions = Object.keys(sessions)
          .filter((fkey) => fkey !== action.payload)
          .reduce((obj, key) => {
            obj[key] = sessions[key];
            return obj;
          }, {});
        state.sessions = sessions;
      })
      .addCase(hangup.rejected, (state, action) => {
        console.debug("call hangup session error", action);
      })
      .addCase(connectUA.pending, (state) => {
        state.connectindStatus = 'connecting'
        state.connected = false;
        state.connectedTime = dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT");
      }).addCase(connectUA.fulfilled, (state, action) => {
        state.connected = true;
        state.connectindStatus = 'connected'
        state.connectedTime = dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT");
      }).addCase(connectUA.rejected, (state, action) => {
        state.connected = false;
        state.connectindStatus = 'failed'
        state.connectedTime = dateFormat(now, "dddd, mmmm dS, yyyy, h:MM:ss TT");
      })
  },
});

// Action creators are generated for each case reducer function

export default sipjsSlice.reducer;
export const {
  setregState,
  callEnd,
  removeSessionData,
  setUserAgent,
  setRegisterer,
  setCallIncomingOutGoingSession,
  setActiveSessionId,
  setConncetInfo,
  setSessionState,
  updateSipProperties
} = sipjsSlice.actions;
