export const it = {
  contact: "Contatto",
  callhistory: "Cronologia delle chiamate",
  message: "Messaggio",
  chat: "Chiacchierata",
  settings: "Impostazioni",
  searchcontact: "Cerca contatti",
  searchmessages: "Cerca messaggi",
  searchchats: "Cerca nelle chat",
  addNewContact: "Aggiungi nuovo contatto",
  applicationSetting: "Impostazioni dell'applicazione",
  language: "Lingua",
  testConnection: "Connessione di prova",
  debuggingLogs: "Log di debug",
  reRegister: "Registrati di nuovo",
  privacyPolicy: "politica sulla riservatezza",
  about: "Di",
  Logout: "Disconnettersi",
  logoutTitle: "Disconnettersi",
  LogoutConfirmMsg: "Sei sicuro di voler uscire?",
  cancellogout: "Annulla",
  confirmlogout: "Confermare",
  contact1: "Contatto",
  addNewContact1: "Aggiungi nuovo contatto",
  firstname: "Nome di battesimo",
  firstnameplaceholder: "Inserisci il nome",
  lastname: "Cognome",
  lastnameplaceholder: "Inserisci il cognome",
  displayname: "Nome da visualizzare",
  displaynameplaceholder: "Immettere il nome visualizzato",
  extensionNumber: "Numero di interno",
  extensionnumberplaceholder1: "Immettere il numero dell'interno",
  phoneNumber: "Numero di telefono",
  phoneNumberPlaceholder: "Inserisci il numero di telefono",
  cellNumber: "Numero di cellulare",
  cellNumberPlaceholder: "Immettere il numero di cella",
  email: "E-mail",
  emailPlaceholder: "Inserisci l'email",
  save: "Salva",
  cancel: "Annulla",
  smslist: "Seleziona la tua Lista SMS",
  addnewmsg: "Aggiungi nuovo messaggio",
  addnewchat: "Aggiungi nuova chat",
  addnewmsg1: "Nuovo messaggio",
  msgnote:
    "Nota: per i messaggi di testo, sono consentiti massimo 160 caratteri. Per gli allegati multimediali, i formati supportati sono: GIF, JPG, JPEG, PNG, MP3, MP4, VCF, TXT e dimensione massima del file: 1 MB",
  msgplaceholder: "Messaggio",
  addnewchat1: "Aggiungi nuova chat",
  chatnote: "Nota: massimo 1200 caratteri consentiti per la chat",
  chatplaceholder: "Messaggio",
  deletethread: "Elimina thread",
  msgnote1:
    "Nota: per i messaggi di testo, sono consentiti massimo 160 caratteri. Per gli allegati multimediali, i formati supportati sono: GIF, JPG, JPEG, PNG, MP3, MP4, VCF, TXT e dimensione massima del file: 1 MB",
  msgscreenplaceholder: "Messaggio",
  chatnote1: "Nota: massimo 1200 caratteri consentiti per la chat",
  chatscreenplaceholder: "Messaggio",
  accountsetting: "Impostazione conto",
  audio: "Audio",
  audiocodecs: "Codec audio",
  audiocodecs: "Codec audio",
  video: "Video",
  videocodec: "Codec video",
  videocodecs: "Video Codecs",
  sip: "SIP",
  sipusername: "Nome utente SIP",
  sipprotocol: "protocollo SIP",
  sipport: "Porta SIP",
  proxysserver: "Server proxy", 
  proxyserver: "Server proxy", 
  proxyport: "Porta proxy",
  callerid: "ID chiamante",
  chooselanguage: "Scegli la lingua",
  aboutTitle: "Di",
  edit: "Modificare",
  privacyPolicyTitle: "politica sulla riservatezza",
  reloadconf: "Registrati di nuovo",
  editcontactlabel: "Modifica il contatto",
  addtocontact: "Aggiungere ai contatti",
  to: "Per",
  copy: "copia",
  forward: "Inoltrare",
  delete: "Elimina",
  selectall: "seleziona tutto",
  testConnection1: "Connessione di prova",
  onhold: "in Attesa",
  ongoingcall: "Chiamata in corso",
  mute: "Muta",
  unmute: "Riattiva",
  hold: "presa",
  unhold: "sbloccare",
  transfer: "Trasferimento",
  blindtransfer: "Trasferimento cieco",
  attendanttransfer: "Trasferimento assistente",
  hangup: "Appendere",
  connecting: "Collegamento",
  incomingcallfrom: "Chiamata in arrivo da",
  callhistory1: "Cronologia delle chiamate",
  callhistory2: "Cronologia delle chiamate",
  ongoingcallconference: "Teleconferenza in corso",
  enterpriseContact: "Contatto aziendale",
  googleContact: "Contatti Google",
  outlookContact: "Contatto di Outlook",
  googleContact1: "Contatti Google",
  enterpriseContact1: "Contatto aziendale",
  outlookContact1: "Contatto di Outlook",
  message1: "Messaggio",
  chat1: "Chiacchierata",
  settings1: "Impostazioni",
  googleContactExist: "Il contatto Google non esiste.",
  outlookContactExist1: "Il contatto di Outlook non esiste.",
  syncDescription:
    "Non ci sono contatti sincronizzati dal<br />Il tuo account, sincronizza i tuoi contatti",
  syncContactButton: "Sincronizza contatto",
  desktopApp: "App desktop",
  otherDevices: "Altri dispositivi",

  version: "Versione v",
  loginToVoizcall: "Accedi a Voizcall",
  loginWith: "Accedi con username e password",

  debuggingLogs1: "Log di debug",
  debugLogsContent:
    "Ciò consentirà all'utente di scaricare o cancellare tutti i registri acquisiti dall'archivio.",
  clearLogs: "Cancella registri",
  downloadLogs: "Scarica i registri",
  wsTest: "Test WebSocket",
  wsConnection: "Connessione",
  wsActionConnecting: "Collegamento alla presa web",
  wsActionConnected: "Collegato",
  wsActionFailed: "Fallito",
  wsStatusConnecting: "Cercando di connettersi",
  wsStatusConnected: "Collegato",
  wsStatusFailed: "Fallito",

  play: "Giocare a", 
  pause: "Pausa", 

  modalcancelbutton: "Annulla", 
  modalconfirmbutton: "Confermare", 

  "audio-codec": "Codec audio", 
  "audiocodecs": "Codec audio", 
  "video-codec": "Codec video", 
  "videocodecs": "Codec video", 

  enablevideo: "Abilita video", 
  enabledvideostatus: "Abilitata", 
  disabledvideostatus: "Disabilitata", 

  enableselfview: "Vista di sé", 
  enabledselfviewstatus: "Abilitata", 
  disabledselfviewstatus: "Disabilitata", 

  call: "Chiamata", 
  dtmfTypeTitle: "Tipo DTMF", 
  dtmftype: "Tipo DTMF", 

  noAnswerTimeoutTitle: "Timeout per nessuna risposta", 
  noAnswertimeout: "Timeout per nessuna risposta", 

  interval: "Il registro scade", 
  interval: "Il registro scade", 

  outboundCallerIdNameTitle: "Nome ID chiamante in uscita", 

  earlyMediaTitle: "I primi media", 
  earlymedia: "I primi media", 
  enabledearlymediastatus: "Abilitata", 
  disabledearlymediastatus: "Disabilitata", 

  extraHeaderTitle: "Intestazioni extra", 
  extraheaders: "Intestazioni extra", 

  encryptionTitle: "Crittografia", 
  encryption: "Crittografia", 

  "1TouchVoicemailTitle": "Numero di segreteria telefonica One Touch", 
  oneTouchVoicemailMenu: "Segreteria telefonica One Touch", 

  allowHoldTitle: "Consenti controlli di attesa", 
  allowholdcontrols: "Consenti controlli di attesa", 
  enabledallowholdstatus: "Abilitata", 
  disabledallowholdstatus: "Disabilitata", 

  allowTransferTitle: "Consenti controlli di trasferimento", 
  allowtransfercontrols: "Consenti controlli di trasferimento", 
  enabledallowtransferstatus: "Abilitata", 
  disabledallowtransferstatus: "Disabilitata", 

  respectServerExpiresTitle: "Rispetta la scadenza del server", 
  respectserverexpires: "Rispetta la scadenza del server", 
  enabledrespectserverexpiresstatus: "Abilitata", 
  disabledrespectserverexpiresstatus: "Disabilitata", 

  registerExpiresTitle: "Il registro scade", 
  registerExpires: "Il registro scade", 
  registerexpires: "Il registro scade", 

  sms: "SMS", 
  smsStatusTitle: "Abilita SMS", 
  enabledsmsstatus: "Abilitata", 
  enabledsms: "Abilitata", 
  enablesms: "Abilitata SMS", 
  disabledsmsstatus: "Disabilitata", 

  downloadConfiguration: "Scarica l'ultima configurazione",
  clearhistory: "Cancellare la cronologia",

  callHistoryModalConfirmMsg:
    "Sei sicuro di voler cancellare la cronologia delle chiamate ?",

  chatSetting: "Impostazione della chat",
  chatSettings: "Impostazioni della Chat",
  takePhoto: "Fare foto",
  photosGallery: "Galleria fotografica",
  deleteGallery: "Elimina foto",
  importChats: "Importa chat",
  exportChats: "Esporta chat",
  online: "in linea",
  offline: "Disconnessa",
  busy: "Occupata",
  dnd: "Non disturbare",
  away: "Lontano",
  custom: "Costume",
  newChat: "Nuova chat",
  groupChat: "Chat di gruppo",
  confirm: "Confermare",
  addChat: "Aggiungi chat",

  windowsRagistry: "Click2Call Windows Registry",

  instantMessage: "Messaggio istantaneo",
  imStatusTitle: "Stato IM",
  enabledimstatus: "Abilitata",
  enableim: "Abilitata",
  disabledimstatus: "Disabilitata",
  exportChat: "Esporta chat",
  btnExport: "Esportare",
  exportDone: "Esportato con successo",
  browseAndImportChat: "Sfoglia e importa la chat",
  btnImport: "Importare",
  forwardChat: "Inoltrare chat",
  createGroup: "Creare un gruppo",
  groupName: "Nome del gruppo",
  newChatAdd: "Nuova chat",
  contactAdd: "Contatto",
  clearChat: "Chiara chat",
  userInfo: "Informazioni utente",
  clearChatUserInfo: "Chiara chat",
  allSharedMedia: "Tutti i media condivisi",
  importChatsLabel: "Importa chat",
  exportChatsLabel: "Esporta chat",
  groupInfo: "Info gruppo",
  exitGroup: "Esci dal gruppo",

  getStarted: "Iniziare!",
  welcomemsg:
    "Migliora le tue comunicazioni unificate con il nostro<br>softphone VoIP",
  callLabel: "Chiamata",
  newChatMessageLabel: "Nuovo messaggio di chat",

  allContact: "Tutti i contatti",
  allContact1: "Tutti i contatti",
  allContact2: "Tutti i contatti",
  messageLabel: "Messaggi",
  chats: "Chat",
  "newChatMessageLabel": "Nuovo messaggio di chat",
  newChatMessageLabel1: "Nuovo messaggio di chat",
  createGroupChat: "Crea chat di gruppo",
  noContactFound: "Nessun contatto trovato",
  noContactFound1: "Nessun contatto trovato",
  loading: "Caricamento...",
  callshistory: "Cronologia delle chiamate",
  allCalls: "Tutte le chiamate",
  allCallsTitle: "Tutte le chiamate",
  missedcalls: "Chiamate perse",
  missedcallsTitle: "Chiamate perse",

  detailPageApplicationSetting: "Impostazioni dell'applicazione",
  autoAnswer: "Risposta automatica",
  account: "account",
  dnd: "Non disturbare",

  _autoAnswerdisabled: "Disabilitata",
  _autoAnswerenabled: "Abilitata",

  _accountdisabled: "Disabilitata",
  _accountenabled: "Abilitata",

  _dnddisabled: "Disabilitata",
  _dndenabled: "Abilitata",
  complete: "Completare",
  searchCallHistory: "Cerca nella cronologia delle chiamate",
  noLogsFound: "Nessun registro trovato",
  searchMissedcallHistory: "Cerca nella cronologia delle chiamate perse",
  noMissedCallFound: "Nessuna chiamata persa trovata",
  clearMissedCallDescription:
    "Sei sicuro di voler cancellare <br /> la cronologia delle chiamate perse?",
};
