import React, { useEffect, useState } from 'react'
import { Accordion } from 'react-bootstrap';
import CallDirectionIcon from './CallDirectionIcon';
import { lastSeenTime } from '../../utils/Utils';
import dateFormat, { masks } from "dateformat";
import noCallsfound from '../../assets/app-icon/icon/no-missedcall.svg'
import { useDispatch, useSelector } from 'react-redux';
import { getCallHistory } from '../../store/Contacts/contactSlice';
import { call_cdr_sync } from '../../store/Contacts/ContactService';

const now = new Date();

const getUiqueCallHistory = (allHistory) => {
    let result = [];
    result = allHistory.filter((value, index, self) => self.findIndex((m) => m.callee === value.callee) === index);
    return result;
}

const CallHistoryAccordion = ({ searchval, type }) => {
    const { callhistory, isLoading, userHistory } = useSelector((state) => state.contacts)
    const { Sidebar } = useSelector((state) => state.theme)
    const [uniqueHistory, setUniqueHistory] = useState([])
    const [callHistoryList, setCallHistoryList] = useState([])
    const [nocallfoundmsg, setNocallFoundmsg] = useState()
    const [specificuserHist, set] = useState()
    const dispatch = useDispatch();
    const searchHandler = async () => {
        if (searchval != "") {
            if (callHistoryList != undefined && callHistoryList.length > 0) {
                let serachHistory = callHistoryList.filter((history) => {
                    if (history.callee.match(searchval.toLowerCase()) || history.caller.match(searchval.toLowerCase())) { return history }
                })
                let res = getUiqueCallHistory(serachHistory);
                setUniqueHistory(res)
            }
        } else {
            let res = getUiqueCallHistory(callHistoryList);
            setUniqueHistory(res)
        }
    }


    useEffect(() => {
        searchHandler();
    }, [searchval])

    useEffect(() => {
        setHistory();
    }, [Sidebar.openId, callhistory])
    // console.debug('uniqueHistory ==> ', uniqueHistory)

    const setHistory = () => {
        switch (Sidebar.openId) {
            case 'allcallhistory':
                let res = getUiqueCallHistory(userHistory);
                setUniqueHistory(res)
                setCallHistoryList(userHistory)
                setNocallFoundmsg('No Calls Found')
                break;
            case 'incomingcall':
                let incominghistory = userHistory.filter((history) => {
                    if (['inbound', 'INBOUND', 'incmoing'].includes(history.call_direction)) {
                        return history;
                    }
                });
                let insres = getUiqueCallHistory(incominghistory);
                setUniqueHistory(insres)
                setCallHistoryList(incominghistory)
                setNocallFoundmsg('No incoming Calls Found')
                break;
            case 'outgoingcall':
                let outgoing = userHistory.filter((history) => {
                    if (['OUTBOUND', 'outbound', "outgoing"].includes(history.call_direction)) {
                        return history;
                    }
                });
                let outres = getUiqueCallHistory(outgoing);
                setUniqueHistory(outres)
                setCallHistoryList(outgoing)
                setNocallFoundmsg('No Outgoing Calls Found')
                break;
            case 'missedcalls':
                let missedcalls = userHistory.filter((history) => {
                    if (['missedcall', 'MISSEDCALL'].includes(history.call_direction) || [true, 'true', 'TRUE', 1, '1'].includes(history.missed_call)) {
                        return history;
                    }
                });
                let misres = getUiqueCallHistory(missedcalls);
                setUniqueHistory(misres)
                setCallHistoryList(missedcalls)
                setNocallFoundmsg('No Missed Calls Found')
                break;
        }
    }

    return (
        <>
            <Accordion>
                {![undefined, ''].includes(uniqueHistory) && uniqueHistory.length > 0 && uniqueHistory.map((history) => {
                    let call = userHistory.filter((his) => his.callee == history.callee);
                    let count = call.length;

                    return <React.Fragment key={history._id}>
                        <Accordion.Item className='card bg-white' eventKey={history._id} >
                            <Accordion.Header className='card-header bg-white' as="div">
                                <div className="caller-details row w-100" data-bs-toggle="collapse" href="#call-details1">
                                    <div className="col-lg-1 col-md-2 col-sm-1 col call">
                                        <span className="person">
                                            {history.callee[0]}
                                        </span>

                                    </div>
                                    <div className="col-lg-3 col-md-2 col-sm-2 p-1 col-3 caller-name">
                                        <h6 className="mb-0">
                                            ({count})
                                            {history.callee}
                                            {history.call_type != 'audio' && <svg className="mx-1" width="18" height="12" viewBox="0 0 18 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M0 10C0 11.1031 0.896875 12 2 12H10C11.1031 12 12 11.1031 12 10V2C12 0.896875 11.1031 0 10 0H2C0.896875 0 0 0.896875 0 2V10ZM17.4719 10.8812C17.7969 10.7062 18 10.3687 18 10V2C18 1.63125 17.7969 1.29375 17.4719 1.11875C17.1469 0.943749 16.7531 0.9625 16.4438 1.16875L13.4438 3.16875L13 3.46562V8.53438L13.4438 8.83125L16.4438 10.8312C16.75 11.0344 17.1437 11.0562 17.4719 10.8812Z" fill="#57A1D7" />
                                            </svg>
                                            }
                                        </h6>
                                        <p className="mb-0"> {history.caller}</p>
                                    </div>
                                    <div className="col-lg-3 col-md-5 d-flex align-items-center justify-content-center flex-md-row flex-column col-sm-3 col call-type">
                                        <CallDirectionIcon callDetails={history} />
                                        {history.call_direction}
                                    </div>
                                    <div className="col-lg-5 col-md-3 col-sm-5  col-sm-3 col call-duration">
                                        <p className="mb-0">{dateFormat(history.start_time, "dd/mm/yyyy")}</p>
                                        <p className="mb-0"> {lastSeenTime(dateFormat(history.start_time, "isoDateTime"))}</p>
                                    </div>
                                </div>
                            </Accordion.Header>
                            <Accordion.Body>
                                <div className="card-body">
                                    <ul className="opened-calls-detail-box">
                                        {call.map((elem) => {
                                            let missedCall = (!['inbound', 'INBOUND', 'incmoing', 'OUTBOUND', 'outbound', "outgoing"].includes(elem.call_direction) || [true, 'true', 1, '1', 'TRUE'].includes(elem.missed_call));
                                            return <React.Fragment key={elem._id}>
                                                <li>
                                                    <div className={`call-type ` + (missedCall ? ' missed-call ' : '')}>
                                                        <CallDirectionIcon callDetails={elem} />
                                                        <p>
                                                            {elem.call_direction}
                                                            <span className="call-date">{dateFormat(elem.start_time, "dd mmm, hh:mm")}</span>
                                                        </p>
                                                    </div>
                                                    <div className="call-duration">
                                                        <p className="mb-0">{lastSeenTime(dateFormat(elem.start_time, "isoDateTime"))}</p>
                                                    </div>
                                                </li>
                                            </React.Fragment>
                                        })}
                                    </ul>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>
                    </React.Fragment>
                }
                )}
            </Accordion>

            {![undefined, ''].includes(uniqueHistory) && uniqueHistory.length == 0 && <>
                <div className="no-missed-calls">
                    <img src={noCallsfound} alt="NO LOGS" width="" height="" />
                    <p className="text-center">{nocallfoundmsg} </p>
                </div>
            </>}
        </>
    )
}

export default CallHistoryAccordion