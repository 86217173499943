import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { SidebarTab, toggleMainMenu, toggleSidebar } from '../../store/themeSlice';
import CallHistorySidebar from '../Sidebar/CallHistorySidebar';
import SettingsSidebar from '../Sidebar/SettingsSidebar';
import DefaultSidebar from '../Sidebar/DefaultSidebar';
import { nanoid } from '@reduxjs/toolkit';

const Sidebar = () => {
    const dispatch = useDispatch();
    const { Sidebar } = useSelector((state) => state.theme)
    return (
        <>
            {(() => {
                switch (Sidebar.tab) {
                    case SidebarTab.callHistory:
                        return <CallHistorySidebar key={"callHistorysidebar"} />
                    case SidebarTab.settings:
                        return <SettingsSidebar key={'dfvgnxcbgvhjcbfgvbfxdcjkbvxcfjkbgvjkxcbf' + nanoid()} />
                    default:
                        return <DefaultSidebar key={'defaultsidebar'} />

                }
            })()}
        </>
    )
}

export default Sidebar