import React, { useEffect, useState } from 'react'
import Logo from '../../assets/app-icon/icon/logo.svg'
import { MdArrowBackIosNew } from 'react-icons/md'
import OtpInput from 'react18-input-otp'
import { useDispatch, useSelector } from 'react-redux'
import { useTimer } from 'react-timer-hook'
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import { v4 as uuidv4 } from 'uuid';
import { ResendOtp, VerifyOtp } from '../../store/Auth/authService'
import { pad } from '../../utils/Utils'
const OtpVerify = () => {

    const time = new Date();
    time.setSeconds(time.getSeconds() + 30);
    let expiryTimestamp = time;

    const { minutes, seconds, start, restart } = useTimer({ expiryTimestamp, autoStart: false, onExpire: () => restartTimer });
    const [otp, setOtp] = useState(null);
    const handleChange = (otp) => setOtp(otp);
    const dispatch = useDispatch();
    const { otp_auth_tocken, user, isError, isSucess } = useSelector((state) => state.auth);
    // const [minute, setMinute] = useState(0);
    // const [second, setSecond] = useState(30);
    const restartTimer = () => {
        restart(time)
    }


    const handleSubmit = (e) => {
        e.preventDefault();
        const device_token = uuidv4().replaceAll('-', '');
        if (otp) {
            start();
            const values = {
                'otp_value': otp,
                "otp_auth_token": otp_auth_tocken,
                "device_token": device_token,
                "device_type": 'web',
                "device_model": navigator.product,
                "device_os": navigator.platform,
                "instance_id": 'dddd' + device_token
            }
            dispatch(VerifyOtp(values))
            setOtp(null)
        } else {
            toast.error("Plase Enter Otp")
        }
    }

    useEffect(() => {
        restartTimer();
    }, []);

    const resetOtpHandler = async () => {
        setOtp(null)
        const value = {
            'otp_auth_token': otp_auth_tocken,
            'user_type': 'mobile'
        };
        await dispatch(ResendOtp(value));

    }

    return (
        <>
            <div className="login-left-section">
                <div className="logo">
                    <img src={Logo} alt="LOGO" />
                </div>

                <Link className="back _backToLoginScreen" to={'/otp-login'}>
                    <MdArrowBackIosNew />
                    Back
                </Link>

                <div className='otp-verification-content'>


                    <div className="otp-verification">
                        <h3 className="heading">OTP Verification</h3>
                        <p className="sub-heading">Enter OTP you received on your Mobile number</p>
                        <div className="">
                            <OtpInput value={otp}
                                onChange={handleChange}
                                numInputs={4}
                                containerStyle={{ justifyContent: "center", gap: "10px" }}
                                inputStyle={{ width: "50px", height: "50px", backgroundColor: "#f4f4f4", border: " 1px solid #ced4da", fontWeight: 700, borderRadius: "3px" }}
                            />
                            <p className="time mt-2"><span id="otpTimer">{pad(minutes)}:{pad(seconds)}</span></p>
                        </div>

                        <div className="col-lg-12">
                            <button type="button" id="validateOTPAndLogin" className="col-lg-12 col-md-12 col-sm-12 common-btn gradient-btn" onClick={handleSubmit} >Verify &amp; Continue</button>
                        </div>
                        <div className="col-lg-12 resend-otp">
                            Don’t receive the OTP? <span className="" id="resendOtp">
                                <button className={seconds == 0 ? ' resend_otp_btn ' : ' resend_otp_btn  action-disabled'} onClick={resetOtpHandler}>RESEND OTP</button>
                            </span>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default OtpVerify