import { configureStore } from "@reduxjs/toolkit";
import authReducer from "./Auth/authSlice";
import contactReducer from "./Contacts/contactSlice";
import sipjsReducer from "./sipjs/sipjsSlice";
import themeReducer from './themeSlice'
export const store = configureStore({
    reducer: {
        auth: authReducer,
        contacts: contactReducer,
        theme: themeReducer,
        sipjs: sipjsReducer
    },
    middleware: (getDefaultMiddleware) => getDefaultMiddleware({ serializableCheck: false }),
})