import React from 'react'
import { TiContacts } from 'react-icons/ti'
import { RiContactsBook2Fill } from 'react-icons/ri'
import { BiMessageAltDetail } from 'react-icons/bi'
import { SiGooglechat } from 'react-icons/si'
import { IoChatbubbleEllipses, IoChatbubbleEllipsesOutline } from 'react-icons/io5'
import { FaUserFriends } from 'react-icons/fa'
import { useDispatch } from 'react-redux'
import { SidebarTab, openSidebar, toggleSidebar } from '../../store/themeSlice'
import { Accordion } from 'react-bootstrap'

const DefaultSidebar = () => {
    const dispatch = useDispatch();
    return (
        <>
            <div className="dashboard-container all-contacts-container">

                <div className="left-menu-content">
                    <Accordion>
                        <Accordion.Item eventKey="0">
                            <Accordion.Header><TiContacts className='sidebar_icon scale_icon' />
                                <span className="__MSG__allContact" >All Contacts</span></Accordion.Header>
                            <Accordion.Body>
                                <div className="nav flex-column nav-pills" id="v-pills-tab" role="tablist" aria-orientation="vertical">
                                    <a className="nav-link mb-3 active" id="allContactTab" data-bs-toggle="pill" data-bs-target="#v-pills-all-calls" role="tab" aria-controls="v-pills-all-calls" aria-selected="true">
                                        <span className="__MSG__allContact1" onClick={() => {
                                            dispatch(openSidebar({ tab: SidebarTab.contact }))
                                            dispatch(toggleSidebar(true))
                                        }}>All Contacts</span>
                                        <span className="contact-icon">
                                            <RiContactsBook2Fill className='icon scale_icon' />
                                        </span>
                                    </a>
                                </div>
                            </Accordion.Body>
                        </Accordion.Item>

                        <Accordion.Item eventKey="1">
                            <Accordion.Header>
                                <BiMessageAltDetail className='sidebar_icon scale_icon' />
                                <span className="__MSG__messageLabel">Messages</span>
                            </Accordion.Header>
                            <Accordion.Body>
                                <button id="btnNewMessage" className="col-lg-3 col-md-3 col-sm-12 outline-btn">
                                    <SiGooglechat className='icon scale_icon' />
                                    <span className="__MSG__addnewmsg1">New Message</span>
                                </button>
                            </Accordion.Body>
                        </Accordion.Item>


                        <Accordion.Item eventKey="2">
                            <Accordion.Header>
                                <IoChatbubbleEllipsesOutline className='sidebar_icon scale_icon' />

                                <span className="__MSG__chats">Chats</span>
                            </Accordion.Header>
                            <Accordion.Body>
                                <button id="btnChatMessage" className="col-lg-3 col-md-3 col-sm-12 mb-3 outline-btn">
                                    <IoChatbubbleEllipses className='icon scale_icon' />
                                    <span className="__MSG__newChatMessageLabel1">New Chat Message</span>
                                </button>
                                <button id="btnCreateGroupChat" className="col-lg-3 col-md-3 col-sm-12 mb-3 outline-btn">
                                    <FaUserFriends className='icon scale_icon' />
                                    <span className="__MSG__createGroupChat">Create Group Chat</span>
                                </button>
                            </Accordion.Body>
                        </Accordion.Item>

                    </Accordion>

                </div>
            </div>
        </>
    )
}

export default DefaultSidebar