import React, { useState } from 'react'
import { useSelector } from 'react-redux'

const useMuteUnmute = () => {
    const { sessions, sessionId } = useSelector((state) => state.sipjs);
    const [showMuteIcon, setShowMuteIcon] = useState(true);
    let session = sessions[sessionId];

    const toggleMuteUnmuteCall = (state) => {
        if (state) {
            setShowMuteIcon(true)
        } else {
            setShowMuteIcon(false)
        }
        console.log('Audio Mute Statue: ' + state);
        let pc = session.sessionDescriptionHandler.peerConnection;
        pc.getLocalStreams().forEach(function (stream) {
            stream.getAudioTracks().forEach(function (track) {
                track.enabled = state;
            });
        });
    }
    return [showMuteIcon, toggleMuteUnmuteCall];
}

export default useMuteUnmute





















// const useMuteUnmute = (toggle = true) => {
//     const { sessions, sessionId } = useSelector((state) => state.sipjs);
//     const [showMuteIcon, setShowMute] = useState(toggle)
//     const dispatch = useDispatch();

//     const toggleMuteUnmuteCall = async (state) => {
//         console.debug('dfxfmd xvgbdxbvdjxbvjdxgbvdxhc')
//         //state is false than mute  true =>unmute
//         let res = await dispatch(toggleMute({ session: sessions[sessionId], state: state }))
//         console.debug('dfcsdxcx', res)
//         if (state) {
//             setShowMute(false)
//         } else {
//             setShowMute(true)
//         }
//     }
//     return [showMuteIcon, toggleMuteUnmuteCall];
// }

// export default useMuteUnmute