import React from 'react'
import usePhonedial from '../../hooks/usePhonedial';
import { useDispatch, useSelector } from 'react-redux';
import { toggleConnectingCall, toggleOpenDialPadModal } from '../../store/themeSlice';
import { getConfigSettings } from '../../data/userSettings';
import ConnectingModal from '../Calling/ConnectingModal';
import { useState } from 'react';

const DialPadeActionButton = ({ number, setNumber }) => {
    const { regState } = useSelector((state) => state.sipjs)
    const { Sidebar, connectinCall, incommingCallModalOpen } = useSelector((state) => state.theme);
    const [dialNum, setdialNum] = useState()
    const [dialPhone] = usePhonedial();
    const dispatch = useDispatch()
    const videoEnable = getConfigSettings('video.enablevideo');
    const CleareNumber = () => {
        setNumber(number.substring(0, (number.length - 1)))
    }

    const AudioCallHandler = () => {
        dispatch(toggleOpenDialPadModal(false));
        if (number != '') {
            // dispatch(toggleConnectingCall(true))
        }
        dialPhone(number)
        setdialNum(number)
    }
    const VideoCallHandler = () => {
        if (number != '') {
            // dispatch(toggleConnectingCall(true))
        }
        dispatch(toggleOpenDialPadModal(false));
        dialPhone(number, true)
        setdialNum(number)
    }
    return (
        <>
            {/* <!-- Video call button --> */}
            {[true, 'true', 1, '1', "TRUE"].includes(videoEnable) &&
                <div className={`col-4 col-lg-4 col-md-4 col-sm-4 mt-3 cursor-pointer _liDialVideo  ` + (regState == 'Registered' ? '' : 'action-disabled')} id="liDialVideo" title="Click to dial" onClick={VideoCallHandler}>
                    <div className="video-call-button _dialVideo" id="divdialVideo">
                        <svg width="34" height="24" viewBox="0 0 34 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M0 19.2343C0 21.3383 1.64721 23.0488 3.67322 23.0488H18.3661C20.3921 23.0488 22.0393 21.3383 22.0393 19.2343V3.97635C22.0393 1.87241 20.3921 0.16185 18.3661 0.16185H3.67322C1.64721 0.16185 0 1.87241 0 3.97635V19.2343ZM32.089 20.9151C32.6859 20.5813 33.059 19.9376 33.059 19.2343V3.97635C33.059 3.27305 32.6859 2.62935 32.089 2.29558C31.4921 1.96181 30.7689 1.99758 30.2007 2.39095L24.6909 6.20544L23.8759 6.77166V16.439L24.6909 17.0052L30.2007 20.8197C30.7632 21.2071 31.4864 21.2489 32.089 20.9151Z" fill="white"></path>
                        </svg>
                    </div>
                </div>
            }
            {/* <!-- Audio call button --> */}
            <div className={`col-4 col-lg-4 col-md-4 col-sm-4 mt-3 cursor-pointer _liDialAudio ` + (regState == 'Registered' ? '' : 'action-disabled')} id="liDialAudio" title="Click to dial" onClick={AudioCallHandler}>
                <div className="call-button _dialAudio" id="divDialAudio">
                    <svg width="41" height="42" viewBox="0 0 41 42" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M38.1743 28.835C35.4461 28.835 32.8065 28.3824 30.3444 27.5675C29.9587 27.4341 29.544 27.4143 29.1477 27.5104C28.7515 27.6065 28.3898 27.8145 28.1041 28.1108L24.6217 32.5696C18.3445 29.514 12.4665 23.7424 9.33895 17.1107L13.6643 13.3535C14.2631 12.7198 14.4406 11.8371 14.1966 11.0449C13.3759 8.53256 12.9545 5.83914 12.9545 3.05519C12.9545 1.83297 11.9563 0.814453 10.7585 0.814453H3.08388C1.88611 0.814453 0.444336 1.35766 0.444336 3.05519C0.444336 24.0819 17.5903 41.5552 38.1743 41.5552C39.7492 41.5552 40.3703 40.1293 40.3703 38.8844V31.0758C40.3703 29.8536 39.3721 28.835 38.1743 28.835Z" fill="white"></path>
                    </svg>
                </div>
            </div>
            {/* <!-- Back button --> */}
            <div className="col-4 col-lg-4 col-md-4 col-sm-4 mt-3 cursor-pointer">
                <div className="back-button clear_number" onClick={CleareNumber}>
                    <svg width="40" height="32" viewBox="0 0 40 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M12.8353 0.865171C12.3528 0.961907 11.6011 1.34368 11.2132 1.68889C10.7421 2.10819 0.15538 15.3497 0.054234 15.6462C-0.0675306 16.0031 0.0204667 16.4796 0.270765 16.8186C1.08281 17.9184 10.9501 30.1582 11.214 30.393C11.6252 30.759 12.348 31.1148 12.8962 31.2209C13.1693 31.2739 17.3517 31.3021 24.9198 31.3021C35.0893 31.3021 36.5813 31.2871 36.9821 31.1804C38.324 30.8233 39.3577 29.7639 39.7091 28.3855C39.884 27.6994 39.884 4.38183 39.7091 3.69578C39.3577 2.3174 38.324 1.258 36.9821 0.900861C36.5805 0.794031 35.1009 0.7802 24.8509 0.78791C18.4303 0.792679 13.0233 0.827415 12.8353 0.865171ZM36.7609 3.52639C36.879 3.61653 37.0479 3.78901 37.1361 3.90976C37.2965 4.12922 37.2965 4.13423 37.2965 16.0406C37.2965 27.947 37.2965 27.9521 37.1361 28.1715C37.0479 28.2923 36.879 28.4647 36.7609 28.5549C36.5464 28.7185 36.5249 28.7188 24.9526 28.7395C14.3252 28.7584 13.3368 28.7491 13.0894 28.6292C12.8892 28.5321 11.539 26.8992 7.8632 22.3086C5.13715 18.9042 2.90679 16.0836 2.90679 16.0406C2.90679 15.9976 5.1366 13.1777 7.86195 9.77413C11.3184 5.45752 12.8951 3.5465 13.0749 3.45573C13.3107 3.33665 14.3219 3.32712 24.9393 3.34397C36.5267 3.36249 36.5463 3.36273 36.7609 3.52639ZM16.1332 9.68217L15.2796 10.557L17.9625 13.2988L20.6454 16.0406L17.981 18.7635L15.3167 21.4864L16.1915 22.3802L17.0664 23.2739L19.7317 20.552L22.3969 17.83L25.0621 20.552L27.7274 23.2739L28.6023 22.3802L29.4772 21.4864L26.8128 18.7635L24.1484 16.0406L26.8318 13.2983L29.5152 10.556L28.6403 9.6623L27.7654 8.76847L25.0812 11.5099L22.3969 14.2513L19.7317 11.5294C18.2658 10.0322 17.0485 8.80733 17.0267 8.80733C17.0047 8.80733 16.6027 9.20103 16.1332 9.68217Z" fill="#474747"></path>
                    </svg>
                </div>
            </div>

            {/* connecting call modal */}
            {/* <ConnectingModal
                className='call-connecting-modal'
                show={connectinCall}
                onHide={() => dispatch(toggleConnectingCall(false))}
                dialNumber={dialNum}
            /> */}

        </>
    )
}

export default DialPadeActionButton