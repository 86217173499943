import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { Inviter, Registerer, Session, UserAgent } from "sip.js";
import { appVersion } from "../../config";
import { getObjectCount, removeObjValByKey } from "../../utils/Utils";
// import { localVideoElement, remoteVideoElement } from "../../component/Calling/VideoCallingScreen";

const _accountCreds = {
    _sipExtension: "505050",
    _sipPassword: "505060@5050A1",
    _sipServer: "s1.netcitrus.com",
    _sipUsernameWithInstance: "505050",
    _wssServer: "wss://s1.netcitrus.com:7443",
    _extNumber: "505050",
    _instanceId: "ddddaun8b3rw13s0ixmky6ntmgkpprl31s93",
    _sipAor: "505050@s1.netcitrus.com",
};

export const sipConfig = {
    _logEnable: true,
    _iceServer: "stun:stun.l.google.com:19302",
};



let _sipExtension = _accountCreds._sipExtension;
let _sipServer = _accountCreds._sipServer;
let _sipPassword = _accountCreds._sipPassword;
let _deviceUID = _accountCreds._instanceId;
let _wssServer = _accountCreds._wssServer;
let sipTrace = sipConfig._logEnable;
let earlyMedia = "true";
let displayName = "test by kishan"

// _sipServer = '129.146.201.224:5090'
_sipExtension = '1011';
_sipPassword = "1011@1101Aa"
// _wssServer = 'wss://s1.netcitrus.com:5090'

export const userAgentOptions = {

};

const registererOptions = {
    extraContactHeaderParams: []// ["transport=wss"],
};

const mediaElement = document.getElementById("mediaElement");
const phonetime = document.getElementById("phonetime");
const connectinfRigton = document.getElementById('connectinfRigton')
// export const remoteVideoElement = document.getElementById("remoteVideo_user");
// export const localVideoElement = document.getElementById("localVideo_user");

// Number of times to attempt reconnection before giving up
const reconnectionAttempts = 3;
// Number of seconds to wait between reconnection attempts
const reconnectionDelay = 4;

// Used to guard against overlapping reconnection attempts
let attemptingReconnection = false;
// If false, reconnection attempts will be discontinued or otherwise prevented
let shouldBeConnected = true;

let userAgent = null;
export const sessionsData = {}


/**    unregisterUA : This function will be used to Unregistered From User Agent */
export const unregisterUA = (registerer) => {
    registerer.unregister()
        .then((request) => {
            console.log("Successfully UNREGISTER");
            console.log(request);
        })
        .catch((error) => {
            toast.error(error, 'UA Unregister Request Fail.')
            console.log("Failed to send UNREGISTER");
            console.log(error);
        });
    return false;
}






/**    _disconnectUA : This function will be used to disconnect user from UA & make Unregister.*/
export const _disconnectUA = (userAgent, registerer) => {
    console.log("We'll destroy SIP User Agent. Also, we'll disconnect wss connection.");
    try {
        registerer.unregister()
            .then((request) => {
                console.log("Successfully UNREGISTER");
                console.log(request);
                userAgent.stop().then(() => {
                    console.log('Disconnected with WebSocket.');
                    // Send UNREGISTER
                }).catch((error) => {
                    console.log("Websocket Disconnection Fail.");
                    console.log(error);
                });
            })
            .catch((error) => {
                console.log("Failed to send UNREGISTER");
                console.log(error);
            });
    } catch (error) {
        console.log('Logout Exception occured.', error);
    }
}


/**    _connectUA : This function will be used to check wss connection.*/
export const connectUA = createAsyncThunk('connectUA', async (userAgent, { rejectWithValue }) => {
    try {
        return userAgent.isConnected();
    } catch (error) {
        throw rejectWithValue(error)
    }

})




/*
***** Call Hangup (call cut )
*/
export const hangup = createAsyncThunk(
    "endCall",
    async ({ session, callDirection }, { rejectWithValue }) => {
        try {
            let sessionId = session.id;
            console.debug('session id from hangup func ', sessionId)
            console.debug('session id from callDirection ', callDirection)
            await callHangUp(session, callDirection);
            return { sessionId }; //sessionId used for remove session form redux store
        } catch (error) {
            throw rejectWithValue(error)
        }
    });

export const callHangUp = async (session, callDirection) => {
    switch (session.state) {
        case "Initial":
        case "Establishing":
            if (callDirection === "outgoing") {
                session.cancel();
            }
            if (callDirection === "incoming") {
                session.reject(603);
            }
            break;
        case "Established":
            session.bye();
            break;
        case "Terminating":
            break;
        case "Terminated":
            break;
    }
    return true;
}

/*
****** toggle Call to hold or Unhold 
if state is true than call is hold
if state if false than call is unhold
*/
export const toggelHoldCall = createAsyncThunk(
    "holdCall",
    async ({ session, state }, { rejectWithValue }) => {

        // alert('hold call')
        try {
            session.sessionDescriptionHandlerOptionsReInvite = {
                hold: state, // true for hold call and false for unhold for call
            };
            const sessionId = session.id;
            console.debug("Hold Session id" + sessionId);
            const options = {
                requestDelegate: {
                    onAccept: () => {
                        if (state) {
                            // session is on hold
                            console.info("Hold Request Accepted.");
                            mediaElement.pause();
                        } else {
                            // session is on unhold
                            mediaElement.play();
                            console.info("Unhold Request Accepted.");
                        }
                    },
                    onReject: () => {
                        if (state) {
                            // session is on hold
                            console.debug("Hold Request Rejected.");
                        } else {
                            // session is on unhold
                            console.debug("Unhold Request Accepted.");
                        }
                        // re-invite request was rejected, call not on unhold
                        console.debug("Unhold Request Rejected.");
                    },
                },
            };
            await session
                .invite(options)
                .then((res) => {
                    console.debug("hold / unhold invise send successfully");

                })
                .catch((error) => {
                    console.debug("Hold Error: " + error);
                    throw rejectWithValue(error);
                });

            if (state) {
                return { holdCall: true, unHoldCall: false, holdUnholdStates: "hold", sessionId };
            } else {
                return {
                    holdCall: false,
                    unHoldCall: true,
                    holdUnholdStates: "unhold",
                    sessionId
                };
            }
        } catch (error) {
            throw rejectWithValue(error);
        }
    }
);


/**    blindTx : This function will send event to server for blind transfer.*/
export const blindTx = createAsyncThunk('blindCallTransfer', async ({ session, number }, { rejectWithValue }) => {
    const transferTarget = UserAgent.makeURI(`sip:${number}@${_sipServer}`);

    if (!transferTarget) {
        throw rejectWithValue("Failed to create transfer target URI.")
    }
    session.refer(transferTarget, {
        requestDelegate: {
            onAccept() {
                console.debug('Blind Call Transfer reqest Accept')
                return true;
            },
            onReject: () => {
                console.debug('blind tansfer Call trnasfer rejected')
                return false
            },
            progress() {
                console.debug('blind tansfer Call trnasfer in pogress')
            }
        }
    });
    toast.success("Call Transfer to " + number)

});

export const attendantCallTx = createAsyncThunk('attendantCalltransfer', async ({ session, referSession }) => {
    try {
        session.refer(referSession, {
            requestDelegate: {
                onAccept() {
                    console.info(' attendent transfer. call accepted')
                    return true;

                },
                onReject() {
                    console.info(' attendent transfer. call accepted')
                    return false;
                }
            }
        });
    } catch (e) {
        console.log('Attendant Error:' + e);
    }


});
export const toggleMute = createAsyncThunk('toggleMute', async ({ session, state }, { rejectWithValue }) => {
    console.info('Audio Mute Statue: ' + state);
    let pc = session.sessionDescriptionHandler.peerConnection;
    pc.getLocalStreams().forEach(function (stream) {
        stream.getAudioTracks().forEach(function (track) {
            track.enabled = state;
        });
    });

});


/**    sendDTMF : This function will send DTMF event to server.*/
export const sendDTMF = createAsyncThunk('sendDtmf', async ({ session, digit }) => {
    console.debug('DTMF Call Event Called with digit' + digit)
    const options = {
        requestOptions: {
            body: {
                contentDisposition: "render",
                contentType: "application/dtmf-relay",
                content: "Signal=" + digit + "\r\nDuration=1000"
            }
        }
    };
    session.info(options);
});


/**    setupRemoteMedia : This function will be used when call established and set media streams. */
export const setupRemoteMedia = (session, has_video = false) => {
    const mediaElement = document.getElementById("mediaElement");



    const remoteStream = new MediaStream();
    // console.log('remoteStream', session.sessionDescriptionHandler.peerConnection.getReceivers());
    session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
        // console.log('receiver track', receiver.track);
        if (receiver.track) {
            remoteStream.addTrack(receiver.track);
        }
    });
    if (!has_video) {
        mediaElement.srcObject = remoteStream;
        mediaElement.play();
    } else {
        const remoteVideoElement = document.getElementById("remoteVideoElement");
        const localVideoElement = document.getElementById("localVideoElement");
        console.debug('remoteVideoElement', remoteVideoElement)
        console.debug('localVideoElement', localVideoElement)
        if (typeof (remoteVideoElement) != 'undefined' && remoteVideoElement != null) {

            remoteVideoElement.srcObject = remoteStream;

            remoteVideoElement.play();
        } else {

        }
        const senderStream = new MediaStream();

        session.sessionDescriptionHandler.peerConnection.getSenders().forEach((sender) => {

            if (sender.track) {
                senderStream.addTrack(sender.track);
            }
        });
        if (typeof (localVideoElement) != 'undefined' && localVideoElement != null) {

            localVideoElement.srcObject = senderStream;
            localVideoElement.play();
        }
    }
}


/**    acceptIncomingCall : This function will accept incoming call. */
export const acceptIncomingCall = (incomingSession) => {
    console.log(`Incoming call accepted.`);
    let sessionObj = incomingSession.incomingInviteRequest.message.data.toString();
    let has_video = false;
    if (sessionObj.includes('m=video')) {
        has_video = true;
        // show video call page
        //pageConfig = page.config({ 'pageId': 'video-call', 'pageUrl': '/views/video-call.html' });
    } else {
        // show audio call component
        // pageConfig = page.config({ 'pageId': 'ongoing-call', 'pageUrl': '/views/ongoing-call.html' });
    }
    const options = {
        sessionDescriptionHandlerOptions: {
            constraints: { audio: true, video: has_video }
        }
    };
    incomingSession.accept(options);
}


/**    rejectIncomingCall : This function will Reject incoming call. */
export const rejectIncomingCall = (incomingSession) => {
    let isMissedCall = false;   //  TRAG-1080 - Missed call notification
    let isCallAnsweredElsewhere = false;   //  TRAG-1886 - Manage call answered elsewhere
    incomingSession.reject(603);
}

