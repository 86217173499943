import React from 'react'
import { useState } from 'react';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getEnterPriseContact } from '../../store/Contacts/ContactService';

const ContactSearch = ({ number, setNumber }) => {
    const { contacts, isLoading } = useSelector((state) => state.contacts);
    const [searchCon, setSearchcon] = useState()
    const dispatch = useDispatch()
    const searchHandler = () => {

        if (number != "") {
            let newREs = contacts.filter((elm) => {
                if (elm.ed_name.toLowerCase().match(number.toLowerCase()) || elm.ed_mobile.toLowerCase().match(number.toLowerCase())) { return elm }
            });
            setSearchcon(newREs);
        }
    }

    useEffect(() => {
        if (number != "") {
            searchHandler()
        }

    }, [number])
    useEffect(() => {
        dispatch(getEnterPriseContact());
    }, [])

    return (
        <>
            <div className="dropdown-menu contact-list-menu show" aria-labelledby="searchContactList" id="searchContactList">
                {(searchCon != undefined && searchCon.length > 0) ? searchCon.map((conatct) => <>
                    <ul className="px-2 mb-0" key={conatct.ed_id} onClick={() => {
                        setNumber(conatct.ed_mobile)
                    }}>
                        <li className="d-flex justify-content-start align-items-center p-2 mb-2 position-relative">
                            <span className="caller-avtar">{conatct.ed_name[0]}</span>
                            <div className="mx-2">
                                <p className="mb-0 mt-0">{conatct.ed_name}</p>
                                <span>{conatct.ed_mobile}</span>
                            </div>
                        </li>
                    </ul></>) : <><center style={{ marginTop: " 30px", fontSize: "20px" }}><p> Contact doesn't exists. </p> </center></>}


            </div>
        </>
    )
}

export default ContactSearch