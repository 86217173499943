import React from 'react'
import keyToneSound from '../../assets/sounds/keypad-tone/sound.mp3'
const DialPadeButton = ({ button, typeNumber }) => {
    const keypadTone = new Audio(keyToneSound)
    const clickHandler = () => {
        keypadTone.play()
        typeNumber(button.value)
    }
    return (
        <>
            <div className="col-4 col-lg-4 col-md-4 col-sm-4 mb-2">
                <div className="digit _dialpadBtn" onClick={clickHandler}>
                    {button.value}
                    <div className="sub">{button.button_label}</div>
                </div>
            </div>
        </>
    )
}

export default DialPadeButton