import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { pcsRegister, pcsStatus } from '../store/Auth/authService';
import { UpdatecustomSettings } from '../store/Auth/authSlice';

const useCheckPcsStaus = () => {
    const { accountCreds, customSettings } = useSelector((state) => state.auth);
    // const accountCreds = JSON.parse(localStorage.getItem('_accountCreds'));
    // const customSettings = JSON.parse(localStorage.getItem('_customSettings'))
    const dispatch = useDispatch();
    let pcsStatusTimer;  //  TRAG-1018 - Timer variable declaration
    let pcsStatusCounter = 0;    //  TRAG-1018 - Counter variable declaration
    let pcs_reg_status = ['AUTHENTICATING_STATE', 'REGISTERING_STATE', 'NOT_REGISTERED_STATE', 'undefined', undefined];

    const checkPcsStatus = async () => {

        ++pcsStatusCounter;
        if (pcs_reg_status.includes(customSettings._pcsRegStatus)) {
            try {
                let values = {
                    sip_domain: accountCreds._sipServer,
                    sip_username_with_instance: accountCreds._sipUsernameWithInstance,
                    instance_id: accountCreds._instanceId
                }
                let res = await pcsStatus(values);
                const { status, data } = res;
                const registraion_status = data.registration_status_code;

                if (status == "SUCCESS") {
                    //  Update local storage data collection for _customSettings and also redux
                    dispatch(UpdatecustomSettings({ key: "_pcsRegStatus", value: registraion_status }))

                    if (['REGISTERING_STATE', 'REGISTERED_STATE'].includes(registraion_status)) {
                        //  Clear time out
                        clearTimeout(pcsStatusTimer);
                        //  Manage counter variable
                        pcsStatusCounter = 0;
                    } else {
                        //  Set time out if not registered on PCS
                        pcsStatusTimer = setTimeout(async () => {
                            if (pcsStatusCounter < 5) {
                                //  Call itself
                                checkPcsStatus();
                            } else {
                                //  Clear time out
                                clearTimeout(pcsStatusTimer);
                                //  Manage counter variable
                                pcsStatusCounter = 0;
                            }
                        }, 5000);
                    }
                } else {
                    dispatch(UpdatecustomSettings({ key: "_pcsRegStatus", value: 'NOT_REGISTERED_STATE' }))
                }
            } catch (error) {
                console.log('check pcs status hook error', error)
            }

        }
    }

    return checkPcsStatus;
}

export default useCheckPcsStaus