import React from 'react'
import Skeleton from 'react-loading-skeleton'

const CallHistoryLoad = () => {
    return (
        <>
            {Array(10).fill('').map((val, index) => <React.Fragment key={index}>
                <div className="row mt-2 p-2" key={index}>
                    <div className="col-1">
                        <Skeleton circle={true} width={'50px'} height={'50px'} />
                    </div>
                    <div className="col-2">
                        <Skeleton height={'50px'} width={'100%'} />
                    </div>
                    <div className="col-7">
                        <Skeleton height={'50px'} width={'100%'} />
                    </div>
                    <div className="col-2">
                        <Skeleton height={'50px'} width={'100%'} />
                    </div>
                </div></React.Fragment>)}

        </>
    )
}

export default CallHistoryLoad