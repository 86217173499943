import React, { useState } from 'react'
import DialPadeButtonList from '../Dialpade/DialPadeButtonList'
import { Modal } from 'react-bootstrap';
import AddCallModalHeader from './AddCallModalHeader';
import { blindTx, hangup, toggelHoldCall } from '../../store/sipjs/sipjsService';
import { useDispatch, useSelector } from 'react-redux';
import usePhonedial from '../../hooks/usePhonedial';
import ContactSearch from './ContactSearch';
import { useEffect } from 'react';
import { updateSipProperties } from '../../store/sipjs/sipjsSlice';

const AddCallModal = (props) => {
    const { sessions, sessionId, isConferenceCallPressed, attendantCalltransferBtn, blindTransferCallBtn, callDirection } = useSelector((state) => state.sipjs)
    const [number, setNumber] = useState('');
    const [showConatct, setShowConatct] = useState(false)
    const [dialPhone] = usePhonedial();
    const dispatch = useDispatch();
    const typeNumber = (num) => {
        setNumber((prev) => prev.concat(num))
    }
    const addCallHandler = () => {
        dialPhone(number)

        props.setAddCallModal(false)
    }
    useEffect(() => {
        if (!props.show) {
            setNumber('')
        }
    }, [props.show])
    const callTransferHandler = async () => {
        if (blindTransferCallBtn) {
            let session = sessions[sessionId];
            let res = await blindTx(session, number);
            if (res) {
                dispatch(hangup({ session, "callDirection": callDirection }))
            }
        }

        props.setAddCallModal(false)
    }
    return (
        <Modal
            {...props}
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header >
                <AddCallModalHeader title={'Add to'} number={number} setNumber={setNumber} hide={props.onHide} hideContact={setShowConatct} />
            </Modal.Header>
            <Modal.Body>
                <div className="col-12">
                    {(showConatct) && <ContactSearch number={number} setNumber={setNumber} />}
                </div>
                <div className="col-lg-10 col-md-10 col-sm-12 keypad-div">
                    <div className="row digits_box">
                        <DialPadeButtonList typeNumber={typeNumber} key={'addcallModalbuttonLisdyjnn'} />
                        <div className="col-4 col-lg-4 col-md-4 col-sm-4 m-auto d-block show" id="transferTheCall">
                            {/* call transfer button */}
                            {(attendantCalltransferBtn || blindTransferCallBtn) && <div className="call-button cursor-pointer" onClick={callTransferHandler}>
                                <svg className="m-0" width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M44.9599 32.0265C41.7537 32.0265 38.6518 31.5153 35.7584 30.5953C35.3051 30.4447 34.8177 30.4223 34.352 30.5308C33.8864 30.6393 33.4613 30.8742 33.1256 31.2087L29.0331 36.2431C21.6563 32.7931 14.7486 26.2764 11.0732 18.7887L16.1562 14.5464C16.86 13.8309 17.0686 12.8342 16.7818 11.9398C15.8174 9.10312 15.3221 6.06201 15.3221 2.91867C15.3221 1.53867 14.1491 0.388672 12.7415 0.388672H3.72242C2.31482 0.388672 0.620483 1.00201 0.620483 2.91867C0.620483 26.6598 20.77 46.3887 44.9599 46.3887C46.8106 46.3887 47.5405 44.7787 47.5405 43.3731V34.5565C47.5405 33.1765 46.3675 32.0265 44.9599 32.0265Z" fill="white"></path>
                                    <path fillRule="evenodd" clipRule="evenodd" d="M23.5129 19.5236C23.5708 19.6465 23.6657 19.788 23.7238 19.8379C23.9088 19.9969 32.7966 25.424 32.9722 25.4852C33.3711 25.6243 33.8492 25.4076 34.0321 25.005C34.1247 24.8008 34.1362 24.6194 34.1373 23.3371L34.1385 21.8983L35.9126 21.8978C38.4256 21.8972 39.1959 21.8152 40.3771 21.423C43.1572 20.4997 45.2717 18.1145 45.8996 15.1933C46.0211 14.6278 46.0773 13.3559 45.9881 13.1891C45.8914 13.0086 45.5968 12.8469 45.3638 12.8467C45.1195 12.8464 45.0027 12.9324 44.0464 13.8153C42.6147 15.1373 41.1488 15.92 39.3629 16.3161C38.8463 16.4307 38.6261 16.4419 36.4434 16.4635L34.0826 16.4869L34.0826 14.9992C34.0826 13.3715 34.0749 13.3262 33.7477 13.0509C33.5195 12.8589 33.2222 12.8072 32.9378 12.9101C32.8145 12.9547 30.6817 14.2347 28.1981 15.7545C24.8421 17.8082 23.6553 18.5616 23.5768 18.6881C23.4136 18.9513 23.3897 19.2622 23.5129 19.5236ZM25.056 12.8331C25.1394 12.9322 25.2873 13.0343 25.3847 13.0599C25.7161 13.1471 25.8967 13.0541 26.5201 12.4749C27.4059 11.6519 27.6844 11.4231 28.319 10.9966C29.2844 10.3479 30.3864 9.88236 31.6338 9.59611C32.1112 9.48659 32.3531 9.47458 34.5157 9.45307L36.8764 9.4296L36.8764 10.9201C36.8764 12.5665 36.896 12.6776 37.2274 12.9136C37.4541 13.075 37.9152 13.1136 38.1523 12.991C38.4526 12.8357 47.179 7.47698 47.2909 7.37908C47.5533 7.14971 47.6201 6.66818 47.4388 6.31275C47.3387 6.11669 46.9186 5.84664 42.7407 3.29319C40.2172 1.75091 38.0773 0.462814 37.9854 0.430797C37.5879 0.292226 37.1096 0.509525 36.927 0.91155C36.8343 1.11566 36.8229 1.29715 36.8218 2.57938L36.8205 4.01817L35.0465 4.01867C32.5335 4.01934 31.7631 4.10126 30.582 4.49351C28.1353 5.30605 26.1525 7.28622 25.3421 9.72641C25.0628 10.5674 24.9729 11.049 24.9366 11.8976L24.9044 12.6528L25.056 12.8331Z" fill="white"></path>
                                </svg>
                            </div>
                            }

                            {/* add call button */}
                            {(!attendantCalltransferBtn && !blindTransferCallBtn) && <div className="call-button cursor-pointer" onClick={addCallHandler}>
                                <svg width="48" height="47" viewBox="0 0 48 47" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M44.9599 32.0265C41.7537 32.0265 38.6518 31.5153 35.7584 30.5953C35.3051 30.4447 34.8177 30.4223 34.352 30.5308C33.8864 30.6393 33.4613 30.8742 33.1256 31.2087L29.0331 36.2431C21.6563 32.7931 14.7486 26.2764 11.0732 18.7887L16.1562 14.5464C16.86 13.8309 17.0686 12.8342 16.7818 11.9398C15.8174 9.10312 15.3221 6.06201 15.3221 2.91867C15.3221 1.53867 14.1491 0.388672 12.7415 0.388672H3.72242C2.31482 0.388672 0.620483 1.00201 0.620483 2.91867C0.620483 26.6598 20.77 46.3887 44.9599 46.3887C46.8106 46.3887 47.5405 44.7787 47.5405 43.3731V34.5565C47.5405 33.1765 46.3675 32.0265 44.9599 32.0265Z" fill="white"></path>
                                    <line x1="36.6704" y1="2.10501" x2="36.6704" y2="20.4126" stroke="white" strokeWidth="3.43267" strokeLinecap="round"></line>
                                    <line x1="27.5166" y1="11.2602" x2="45.8242" y2="11.2602" stroke="white" strokeWidth="3.43267" strokeLinecap="round"></line>
                                </svg>
                            </div>
                            }

                        </div>
                    </div>
                </div>
            </Modal.Body>
        </Modal>
    )
}

export default AddCallModal