import React from 'react'
import { HiPhoneIncoming, HiPhoneMissedCall, HiPhoneOutgoing } from 'react-icons/hi'

const CallDirectionIcon = ({ callDetails }) => {
    return (
        <>
            {['OUTBOUND', 'outbound', "outgoing"].includes(callDetails.call_direction) && ![true, 'true', 1, '1', 'TRUE'].includes(callDetails.missed_call)  && <HiPhoneOutgoing className="outgoingcall_icon" />}
            {['inbound', 'INBOUND', 'incmoing'].includes(callDetails.call_direction) && ![true, 'true', 1, '1', 'TRUE'].includes(callDetails.missed_call) && <HiPhoneIncoming className="incomingcall_icon" />}
            {
                (!['inbound', 'INBOUND', 'incmoing', 'OUTBOUND', 'outbound', "outgoing"].includes(callDetails.call_direction) || [true,'true',1,'1','TRUE'].includes(callDetails.missed_call) )&& <HiPhoneMissedCall className="missedcall_icon" />
            }

        </>
       
    )
}

export default CallDirectionIcon