import React from 'react'

const DialPadeInputNumber = ({ numVal, dialNum }) => {
    const typeNumber = (e) => {
        dialNum(e.target.value)
    }
    return (
        <>
            {/* <!-- Destination number --> */}
            <input type="text" className="destinationNumber" value={numVal} onChange={typeNumber} />
        </>
    )
}

export default DialPadeInputNumber