import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import { Inviter, UserAgent } from "sip.js";
import { setupRemoteMedia } from "../store/sipjs/sipjsService";
import {
  hideDialpadeSection,
  toggleConnectingCall,
  toggleVideoCall,
  updateThemeProperties,
} from "../store/themeSlice";
import useCallTimerDuration from "./useCallTimerDuration";
import {
  cleanupMedia,
  connectingCallRigton,
  detectAudioVideoPermission,
  getObjectCount,
} from "../utils/Utils";
import { getConfigSettings } from "../data/userSettings";
import {
  removeSessionData,
  setCallIncomingOutGoingSession,
  setSessionState,
  updateSipProperties,
} from "../store/sipjs/sipjsSlice";
import { addDialNumber, addToCallHistory } from "../store/Contacts/contactSlice";
import { useState } from "react";

export let sessionData = {};
const _accountCreds = JSON.parse(localStorage.getItem("_accountCreds"));

const usePhonedial = () => {
  const dispatch = useDispatch();
  const { userAgent, sessionState, sessions, isConferenceCallPressed, attendantCalltransferBtn } = useSelector((state) => state.sipjs);
  const { accountCreds } = useSelector((state) => state.auth);
  const [callTimer, setCallTimer] = useState({});
  // const [calltimer, TimerAction] = useCallTimerDuration()
  const dialPhone = async (number, video = false) => {
    if (await detectAudioVideoPermission(video)) {
      if (number != "") {
        localStorage.setItem("dialNumber", number);
        dispatch(addDialNumber(number));
        dispatch(toggleConnectingCall(true));
        let call_type = "audio";
        if (video) {
          call_type = 'video';
        }
        let call_deails = { direction: 'OUTBOUND', number, missedCall: false, call_type: call_type }
        dispatch(addToCallHistory(call_deails))
        let _sipServer = accountCreds._sipServer;
        // _sipServer = "switch.nyerhosmobile.com:7443"
        var uri = UserAgent.makeURI(`sip:${number}@${_sipServer}`);

        let _extraHeaders = {};
        let _headers = [];
        let earlyMedia = [true, "true", "TRUE", "1", 1].includes(
          getConfigSettings("call.earlymedia")
        )
          ? true
          : false;
        for (let i in _extraHeaders) {
          _headers.push(i + ": " + _extraHeaders[i]);
        }
        const inviteOptions = {
          sessionDescriptionHandlerOptions: {
            constraints: {
              audio: true,
              video: video,
            },
          },
          extraHeaders: _headers,
          earlyMedia: earlyMedia,
          // contactParams: { transport: 'wss' },
        };
        let session = await new Inviter(userAgent, uri, inviteOptions);
        let setSession = {
          callDirection: "outgoing",
          session: session,
          sessionId: session.id,
        };

        dispatch(setCallIncomingOutGoingSession(setSession));


        session.stateChange.addListener(async (state) => {
          // sessionTeminared(state);
          dispatch(setSessionState(state));
          // alert("session" + " _ " + state + " _ " + session._id)
          switch (state) {
            case "Initial":
              connectingCallRigton('play')
              dispatch(toggleConnectingCall(true));
              break;
            case "Establishing":
              connectingCallRigton('play')
              console.debug("call ===> Establishing");
              dispatch(toggleConnectingCall(true));
            case "Established":
              //connectingCallRigton('pause')
              console.debug("call ===> Established");
              if (video) {
                dispatch(toggleVideoCall(true));
              }
              if (isConferenceCallPressed || attendantCalltransferBtn) {
                dispatch(updateSipProperties({ key: 'atxCallTimer', value: true }))
              } else {
                dispatch(updateSipProperties({ key: 'calltimerStart', value: true }))
              }
              // TimerAction('start')

              setupRemoteMedia(session, video);
              break;
            case "Terminated":
              connectingCallRigton("pause");
              dispatch(toggleConnectingCall(false));
              dispatch(toggleVideoCall(false));
              console.debug("session terminated id", session._id);
              dispatch(removeSessionData(session._id));
              dispatch(updateSipProperties({ key: 'calltimerStart', value: false }))
              dispatch(updateSipProperties({ key: 'atxCallTimer', value: false }))
              dispatch(updateThemeProperties({ key: "minimizeVideoCall", value: false }))
              cleanupMedia();
              break;
            default:
              break;
          }
        });

        // Setup outgoing session delegate
        session.delegate = {
          // Handle outgoing REFER request
          onRefer(referral) {
            //console.log("Handle outgoing REFER request");
            referral.accept().then(() => {
              referral.makeInviter().invite();
            });
          },
        };

        // Options including delegate to capture response messages
        const inviteOptionsOB = {
          requestDelegate: {
            onAccept: (response) => {
              connectingCallRigton("pause");

              dispatch(setCallIncomingOutGoingSession(setSession));
              dispatch(toggleConnectingCall(false));
            },
            onReject: (response) => {
              dispatch(removeSessionData(session._id));
              dispatch(toggleConnectingCall(false));
              // alert('dkgvfdjnbgfncnbjcgfb ')
              console.log("Negative response ....");
              // console.log(response);
              cleanupMedia();
            },
            onProgress: (response) => {
              dispatch(toggleConnectingCall(true));
              // console.debug('onpogressing session=========================')
            },
            onCancel: (res) => {
              console.debug('cancel session====')
            }
          },
          sessionDescriptionHandlerOptions: {
            constraints: {
              audio: true,
              video: video,
            },
          },
        };

        // Send invitation
        var OBSession = session
          .invite(inviteOptionsOB)
          .then((request) => {
            console.log("Successfully sent INVITE ....");
            // console.log(request);
          })
          .catch((error) => {
            console.log("Failed to send INVITE ==> ", error);
          });

        sessionData[session.id] = session;
        // dispatch(showDialpadeSection())
      } else {
        toast.error("Enter Mobile Number To Dail Call");
      }
    }
  };
  return [dialPhone];
};

export const removeSessionRecord = (sessionId) => {
  sessionData = Object.keys(sessionData)
    .filter((fkey) => fkey !== sessionId)
    .reduce((obj, key) => {
      obj[key] = sessionData[key];
      return obj;
    }, {});
};
export default usePhonedial;
