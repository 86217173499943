import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { toggleConnectingCall, toggleSidebar } from '../../store/themeSlice';
import { SidebarTab } from '../../store/themeSlice';
import HomePage from '../../page/HomePage';
import Settings from '../../page/Settings';
import Conatct from '../../page/Conatct';
import OnGoingCall from '../Calling/OnGoingCall';
import ConnectingModal from '../Calling/ConnectingModal';
import { FullScreen, useFullScreenHandle } from "react-full-screen";
import InComingCallModal from '../Calling/InComingCallModal';
import { toggleIncommigCallModal } from '../../store/themeSlice';
import CallHistory from '../CallHistory/CallHistory';

const MainScreen = () => {
    const dispatch = useDispatch();
    const { Sidebar, connectinCall, incommingCallModalOpen } = useSelector((state) => state.theme);
    const { dialnumber } = useSelector((state) => state.contacts);
    
    // const []
    return (
        <>

            <ConnectingModal
                className='call-connecting-modal'
                show={connectinCall}
                onHide={() => dispatch(toggleConnectingCall(false))}

            />

            <InComingCallModal
                className='incoming-call-modal'
                show={incommingCallModalOpen}
                onHide={() => dispatch(toggleIncommigCallModal(false))}

            />



            <div className="right-side-content">
                {/* {(['', null, undefined, 'undefined'].includes(Sidebar.openId) || Sidebar.tab != SidebarTab.contact )&&  <HomePage />} */}
                {(() => {
                    switch (Sidebar.tab) {
                        case SidebarTab.home:
                            return <HomePage />
                        case SidebarTab.contact:
                            return <Conatct />
                        case SidebarTab.settings:
                            return <Settings />
                        case SidebarTab.callHistory:
                            return <>{![null, '', undefined].includes(Sidebar.openId) && <CallHistory />}</>
                    }
                })()}

                {(['', null, undefined, 'undefined'].includes(Sidebar.openId) && Sidebar.tab != SidebarTab.contact && SidebarTab.home != Sidebar.tab) && <HomePage />}
            </div>

        </>
    )
}

export default MainScreen