import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { hangup, setupRemoteMedia } from '../store/sipjs/sipjsService';
import { hideCallingScreeenFun, hideTransferStrip, removeSessionData, setActiveSessionId, showCallingScreeenFun } from '../store/sipjs/sipjsSlice';
import { showDialpadeSection, toggleConnectingCall, updateThemeProperties } from '../store/themeSlice';
import { cleanupMedia, connectingCallRigton, getObjectCount } from '../utils/Utils';
import useHoldUnholdCall from './useHoldUnholdCall';
import { removeSessionRecord } from './usePhonedial';

const mediaElement = document.getElementById("mediaElement");
const phonetime = document.getElementById("phonetime");
const connectinfRigton = document.getElementById('connectinfRigton')


const useCallHangup = () => {
    const { sessions, sessionId, callDirection } = useSelector((state) => state.sipjs)
    const [showHoldButton, toggeCallHoldUnhold] = useHoldUnholdCall(true);
    const dispatch = useDispatch();

    let session = sessions[sessionId];


    const callHangUp = () => {
        if (getObjectCount(sessions) > 0) {
            switch (session.state) {
                case "Initial":
                case "Establishing":
                    if (callDirection === "outgoing") {
                        session.cancel();
                    }
                    if (callDirection === "incoming") {
                        session.reject(603);
                    }
                    callHangUp();
                    //     mediaElement.srcObject = null;
                    // mediaElement.currentTime = null;
                    break;
                case "Established":
                    // mediaElement.currentTime = null;
                    session.bye();
                    //mediaElement.srcObject = null;
                    cleanupMedia();
                    break;
                case "Terminating":
                    break;
                case "Terminated":
                    connectingCallRigton("pause");
                    cleanupMedia();
                    dispatch(updateThemeProperties({ key: "minimizeVideoCall", value: false }))
                    break;
            }
        } else {
            dispatch(toggleConnectingCall(false));
        }
        if (getObjectCount(sessions) > 1) {
            removeSessionRecord(sessionId);
            let activeSessionId = sessions[Object.keys(sessions)[0]].id;
            let activeSession = sessions[activeSessionId];
           
            setupRemoteMedia(activeSession)
        }

      


    }

    return callHangUp
}

export default useCallHangup


