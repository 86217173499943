import React, { Suspense, useEffect, useState } from 'react'
import PageTitle from '../component/Layoute/PageTitle'
import { useDispatch, useSelector } from 'react-redux';
import Search from '../component/Search';
import ContactDetails from '../component/Contact/ContactDetails';



const ContactList = React.lazy(() => import('../component/Contact/ContactList'));

const Conatct = () => {
    const { Sidebar } = useSelector((state) => state.theme)
    const [searchContact, setSearchCon] = useState('');
    return (
        <>



            {![null, '', undefined, 'undefined'].includes(Sidebar.openId) ? <ContactDetails /> : <Suspense fallback={<h3>Loadding..</h3>}>
                <ContactList />
            </Suspense>}

        </>

    )
}

export default Conatct