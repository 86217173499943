import { initializeApp } from "firebase/app";
import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { FIREBASE_CLOUD_MSG_SERVERKRY, VAPID_KEY } from "./config";
import Axios from "./utils/Axios";
import axios from "axios";
const firebaseConfig = {
    apiKey: "AIzaSyDsNmLrcLHK6NaXxXYwuuqz4e9fWDF-ZNs",
    authDomain: "voizcall-web.firebaseapp.com",
    projectId: "voizcall-web",
    storageBucket: "voizcall-web.appspot.com",
    messagingSenderId: "990737134440",
    appId: "1:990737134440:web:d0771695514a1140bddcdd"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const messaging = getMessaging(app);


export const getTokenFCM = () => {
    return getToken(messaging, { vapidKey: VAPID_KEY }).then((currentToken) => {
        if (currentToken) {
            localStorage.setItem('fcmtocken', currentToken)
            return currentToken;
        }
    }).catch((err) => {
        console.debug('An error occurred while retrieving token. ', err);
        // catch error while creating client token
    });
}

export const onMessageListener = () =>
    new Promise((resolve) => {
        onMessage(messaging, (payload) => {
            resolve(payload);
        });
    });

export const sendNotification = async (title, body) => {
    let tocken = localStorage.getItem('fcmtocken');
    let message = {
        "to": tocken,
        "notification": {
            "body": body,
            "title": title,
            'icon': 'https://webphone.voizcall.com/apple-touch-icon.png'
        }
    }
    const config = {
        headers: {
            "Authorization": `key=${FIREBASE_CLOUD_MSG_SERVERKRY}`,
            "Content-Type": "application/json"
        }
    };
    try {
        let res = axios.post('https://fcm.googleapis.com/fcm/send', message, config);

    } catch (error) {

    }
}