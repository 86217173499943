import React, { useEffect } from "react";
import DtmfModal from "../DTMFModal/DtmfModal";
import { useState } from "react";
import useCallHangup from "../../hooks/useCallHangup";
import Draggable from "react-draggable";
import useCallTimerDuration from "../../hooks/useCallTimerDuration";
import useMuteUnmute from "../../hooks/useMuteUnmute";
import { useDispatch, useSelector } from "react-redux";
import useVideoPlayPause from "../../hooks/useVideoPlayPause";
import { getConfigSettings } from "../../data/userSettings";
import CallTimer from "./CallTimer";
import VideoCallActionButton from "./VideoCallActionButton";
import { setupRemoteMedia } from "../../store/sipjs/sipjsService";
import MinimizeCallPopup from "./MinimizeCallPopup";
import { updateThemeProperties } from "../../store/themeSlice";
import VideoCallMinimize from "./VideoCallMinimize";

const VideoCallingScreen = () => {
  const { sessions, callDirection, sessionId, sessionState } = useSelector(
    (state) => state.sipjs
  );
  const { minimizeVideoCall } = useSelector((state) => state.theme)
  const [calltimer, TimerAction] = useCallTimerDuration();
  const [switchVideoScreen, setSwitchVideo] = useState(false);
  const dispatch = useDispatch()


  let number = sessions[sessionId]?.remoteIdentity?.uri?.normal?.user;
  const self_view = getConfigSettings("video.selfview");


  useEffect(() => {
    if (sessionState == "Established") {
      TimerAction("start");
    } else {
      TimerAction("reset");
    }
  }, [sessionState]);

  //  Set remote media stream on connect
  const setRemoteMediaForVideoCall = async () => {
    setTimeout(async () => {
      var session = sessions[sessionId];
      // Setup remote media after page Load for outgoing request
      if (typeof session.outgoingInviteRequest !== "undefined") {
        if (sessionId && session) {
          await setupRemoteMedia(session, true);
        }
      }
    }, 500);
  }
  useEffect(() => {
    setRemoteMediaForVideoCall();
  }, [minimizeVideoCall])


  const videoMinimize = () => {
    dispatch(updateThemeProperties({ key: "minimizeVideoCall", value: true }))
  }
  return (
    <>
      <div className="incoming-call-maximize-container active-call-container active-call-transfer position-relative active-call-attandant-transfer-container attandant-transfer-hold-container ">
        {/* <!-- Active call heading --> */}

        {/* <!-- ACTIVE VIDEO CALL POP UP :: STARTS --> */}
        <div
          className="video-call-popup position-relative"
          id="divActiveVideoCallMaximisePopup"
        >


          {/* <!-- Remote user --> */}
          <div
            className={
              switchVideoScreen
                ? " local-peer position-absolute "
                : " remote-peer "
            }
            id="divRemoteUserVideo"
          >
            {/* <!-- Remote user video stream --> */}
            <video
              className="receiver-call _remoteVideo"
              id={!minimizeVideoCall ? "remoteVideoElement" : 'remoteVideoMiniPopup'}
            ></video>

            <div className="call-header p-3">
              {/* <!-- Remote user display name --> */}
              <p className="name" id="onGoingVideoCallDisplayNumber">{number}</p>

              <div className="d-flex justify-content-start align-items-baseline">
                {/* <!-- Video call minimise --> */}

                {!minimizeVideoCall &&
                  <span onClick={videoMinimize}>
                    <svg id="videoCallMinimise" className="mx-2 cursor-pointer " width="20" height="3" viewBox="0 0 20 3" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M18.3589 1.11035L1.70703 1.11035" stroke="white" strokeWidth="2" strokeLinecap="round" />
                      <defs>
                        <linearGradient id="paint0_linear_815_541" x1="10.033" y1="0.870605" x2="10.033" y2="2.11035" gradientUnits="userSpaceOnUse">
                          <stop stopColor="#EDEDED" />
                          <stop offset="1" stopColor="#FFFFFE" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>

                }

                {/* <!-- Vidio call maximise --> */}
                <svg className="mx-2 cursor-pointer hide" width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <rect x="2" y="2" width="18" height="18" rx="2" stroke="white" strokeWidth="2.3" />
                </svg>


                {/* <!-- Video call switch arrow remote --> */}
                {switchVideoScreen &&
                  <svg onClick={() => setSwitchVideo(!switchVideoScreen)} id="switchVideoCallRemote" className="switch-arrow cursor-pointer " width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M1.55728 1.81546L11.6048 1.68828L11.4776 11.7358M10.8982 2.39485L1.96003 11.333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                  </svg>}
              </div>
            </div>
          </div>

          {/* <!-- Local user --> */}

          {["true", true, 1, "1", "TRUE"].includes(self_view) && (
            <div
              className={
                !switchVideoScreen
                  ? " local-peer   position-absolute "
                  : " remote-peer "
              }
              id="divLocalUserVideo"
            >
              {/* <!-- Local user video stream --> */}
              <span className="name" id="localUserName">
                You
              </span>

              {/* <!-- Local user video stream --> */}
              <video
                className="caller-user _localVideo"
                id={!minimizeVideoCall ? "localVideoElement" : 'localVideoMiniPopup'}

                muted="muted"
              ></video>

              {/* <!-- Video call switch arrow local --> */}
              {!switchVideoScreen &&
                <svg onClick={() => setSwitchVideo(!switchVideoScreen)} id="switchVideoCallLocal" className="switch-arrow cursor-pointer" width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.55728 1.81546L11.6048 1.68828L11.4776 11.7358M10.8982 2.39485L1.96003 11.333" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" />
                </svg>
              }
            </div>
          )}
          {/* <!-- Call action buttons --> */}
          <VideoCallActionButton />

          {/* <!-- Call timer --> */}
          <div className="call-time position-absolute _timer">
            <CallTimer />
          </div>

          {/* {minimizeVideoCall && <VideoCallMinimize />} */}



        </div>
      </div>

    </>
  );
};

export default VideoCallingScreen;
