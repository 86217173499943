import React, { useEffect } from 'react'
import TransferUserIcon from '../../assets/img/transfer-user.svg'
import { useDispatch, useSelector } from 'react-redux'
import { hangup } from '../../store/sipjs/sipjsService';
import CallTimer from './CallTimer';
import { getObjectCount } from '../../utils/Utils';
import { useState } from 'react';
const CallUserCard = ({ hold, number, timer, type, sessionId }) => {
    const dispatch = useDispatch();
    const { sessions, callDirection } = useSelector((state) => state.sipjs)
    const [startTimer, setstartTimer]=useState(true)
    const endCallHandler = () => {
        let session = sessions[sessionId];
        dispatch(hangup({ session, "callDirection": callDirection}))
    }
useEffect(()=>{
    if(getObjectCount(sessions)>1){
        setstartTimer(true)
    }else{
        setstartTimer(false)
    }
},[sessions])

    return (
        <>
            <div className={`call-info-box mx-4 mt-3 d-flex justify-content-center align-items-center position-relative flex-column ` + (hold ? ' hold-call ' : '')} >
                <div className="person">
                    <img src={TransferUserIcon} alt="DIALER" width="" height="" />
                    {hold && <svg className="hover-img attended-atx-onhold-user" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M42.4495 31.5901C39.3861 31.5901 36.4222 31.0818 33.6576 30.1669C33.2245 30.0171 32.7588 29.9949 32.3139 30.1027C31.869 30.2106 31.4629 30.4443 31.1421 30.7768L27.2318 35.7835C20.1834 32.3526 13.5832 25.8718 10.0714 18.4254L14.9282 14.2066C15.6006 13.495 15.7999 12.5038 15.5259 11.6143C14.6044 8.7933 14.1312 5.76897 14.1312 2.64299C14.1312 1.27061 13.0104 0.126953 11.6654 0.126953H3.04789C1.70295 0.126953 0.0840454 0.736901 0.0840454 2.64299C0.0840454 26.2531 19.3366 45.8731 42.4495 45.8731C44.2179 45.8731 44.9152 44.272 44.9152 42.8742V34.1062C44.9152 32.7338 43.7945 31.5901 42.4495 31.5901Z"
                            fill="white"
                        ></path>
                        <line x1="28.5892" y1="16.7816" x2="28.5892" y2="5.017" stroke="white" strokeWidth="5.47189" strokeLinecap="round"></line>
                        <line x1="42.6792" y1="5.01915" x2="42.6792" y2="16.7838" stroke="white" strokeWidth="5.47189" strokeLinecap="round"></line>
                    </svg>}
                </div>
                <h3 className="mb-0 mt-2" id="callConferenceSecondName">{number}</h3>
                <p id="callConferenceSecondNumber">{number}</p>

                {hold && <span className="call-status position-absolute">
                    <svg className="mx-2" width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M42.4495 31.5901C39.3861 31.5901 36.4222 31.0818 33.6576 30.1669C33.2245 30.0171 32.7588 29.9949 32.3139 30.1027C31.869 30.2106 31.4629 30.4443 31.1421 30.7768L27.2318 35.7835C20.1834 32.3526 13.5832 25.8718 10.0714 18.4254L14.9282 14.2066C15.6006 13.495 15.7999 12.5038 15.5259 11.6143C14.6044 8.7933 14.1312 5.76897 14.1312 2.64299C14.1312 1.27061 13.0104 0.126953 11.6654 0.126953H3.04789C1.70295 0.126953 0.0840454 0.736901 0.0840454 2.64299C0.0840454 26.2531 19.3366 45.8731 42.4495 45.8731C44.2179 45.8731 44.9152 44.272 44.9152 42.8742V34.1062C44.9152 32.7338 43.7945 31.5901 42.4495 31.5901Z"
                            fill="white"
                        ></path>
                        <line x1="28.5892" y1="16.7816" x2="28.5892" y2="5.017" stroke="white" strokeWidth="5.47189" strokeLinecap="round"></line>
                        <line x1="42.6792" y1="5.01915" x2="42.6792" y2="16.7838" stroke="white" strokeWidth="5.47189" strokeLinecap="round"></line>
                    </svg>
                    OnHold
                </span>}


                {(!hold || type === 'conference') &&
                    <>
                        <span className="speaker-icon position-absolute">
                            <svg width="17" height="19" viewBox="0 0 17 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path
                                    d="M10.0941 2.15408V16.4895C10.0941 16.9282 9.91979 17.3489 9.60958 17.6591C9.29938 17.9693 8.87866 18.1436 8.43997 18.1436C8.00128 18.1436 7.58056 17.9693 7.27036 17.6591C6.96016 17.3489 6.78589 16.9282 6.78589 16.4895V2.15408C6.78589 1.71539 6.96016 1.29467 7.27036 0.984471C7.58056 0.67427 8.00128 0.5 8.43997 0.5C8.87866 0.5 9.29938 0.67427 9.60958 0.984471C9.91979 1.29467 10.0941 1.71539 10.0941 2.15408ZM16.7104 5.46225V13.1813C16.7104 13.62 16.5361 14.0407 16.2259 14.3509C15.9157 14.6611 15.495 14.8354 15.0563 14.8354C14.6176 14.8354 14.1969 14.6611 13.8867 14.3509C13.5765 14.0407 13.4022 13.62 13.4022 13.1813V5.46225C13.4022 5.02356 13.5765 4.60284 13.8867 4.29264C14.1969 3.98244 14.6176 3.80817 15.0563 3.80817C15.495 3.80817 15.9157 3.98244 16.2259 4.29264C16.5361 4.60284 16.7104 5.02356 16.7104 5.46225ZM3.47772 6.56497V12.0786C3.47772 12.5173 3.30345 12.938 2.99325 13.2482C2.68305 13.5584 2.26233 13.7327 1.82364 13.7327C1.38495 13.7327 0.964226 13.5584 0.654025 13.2482C0.343824 12.938 0.169556 12.5173 0.169556 12.0786V6.56497C0.169556 6.12628 0.343824 5.70556 0.654025 5.39536C0.964226 5.08516 1.38495 4.91089 1.82364 4.91089C2.26233 4.91089 2.68305 5.08516 2.99325 5.39536C3.30345 5.70556 3.47772 6.12628 3.47772 6.56497Z"
                                    fill="white"
                                ></path>
                            </svg>
                        </span>
                    </>
                }

                {(!hold && type != 'conference') && <span className="call-time position-absolute _timerAtx" >
                    <CallTimer start={startTimer} />
                </span>}

                {(!hold && type === 'conference') && <>
                    <div className="hover-content" id="hoverContentsecondconferencecall">
                        <div className="buttons">
                            <button id="secondBtncallConferenceDisconnect" className="end-button mx-3" type="button" onClick={endCallHandler}>
                                <svg width="69" height="27" viewBox="0 0 69 27" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path
                                        d="M12.6227 24.2714C14.942 21.9521 17.5866 20.1089 20.4009 18.7371C20.8469 18.5273 21.217 18.1923 21.4688 17.7704C21.7206 17.3485 21.8438 16.8569 21.8245 16.3518L20.8381 9.44456C28.8791 6.81295 38.9848 6.92491 47.5137 10.1364L47.1625 17.1391C47.2144 18.2092 47.8449 19.1414 48.7535 19.6352C51.675 21.1613 54.4174 23.1872 56.8817 25.6515C57.9636 26.7333 59.7136 26.7864 60.7319 25.7681L67.2561 19.2439C68.2743 18.2257 69.0192 16.5192 67.5166 15.0166C48.9043 -3.59566 18.8617 -4.48662 1.36319 13.0119C0.0244024 14.3507 0.758618 16.1409 1.86052 17.2428L8.77249 24.1547C9.85436 25.2366 11.6045 25.2896 12.6227 24.2714Z"
                                        fill="white"
                                    ></path>
                                </svg>
                            </button>
                        </div>
                    </div>
                </>
                }
            </div >

        </>
    )
}

export default CallUserCard