import React, { Suspense } from 'react';
import SettingItembutton from './SettingItembutton';
import { getConfigSettings } from '../../data/userSettings';

import { getSettingSidebarItem } from '../../data/SettingItems';




const SettingSidebarMenu = () => {
    const sms_service_status = getConfigSettings('sms.enablesms');
    const im_service_status = getConfigSettings('im.enableim');
    const settings = getSettingSidebarItem();

    return (
        <>
            {
                settings.map((element) => <React.Fragment key={element.transKey}>
                    {element.visible && <SettingItembutton element={element} />}

                </React.Fragment>)
            }
        </>
    )
}

export default SettingSidebarMenu