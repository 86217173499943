import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import useCallTimerDuration from "../../hooks/useCallTimerDuration";
import useCallHangup from "../../hooks/useCallHangup";
import { useState } from "react";
import useMuteUnmute from "../../hooks/useMuteUnmute";
import useHoldUnholdCall from "../../hooks/useHoldUnholdCall";
import MinimizeCallPopup from "./MinimizeCallPopup";
import CallTimer from "./CallTimer";
import CallingActionButton from "./CallingActionButton";
import CallInformation from "./CallInformation";
import { getObjectCount } from "../../utils/Utils";
import TransaferCall from "./TransaferCall";

const OnGoingCall = ({ calltimer }) => {
  const { sessions, callDirection, sessionId, sessionState, conferenceCallMerge, blindTransferCallBtn, attendantCalltransferBtn, isConferenceCallPressed } = useSelector(
    (state) => state.sipjs
  );


  const [showMuteIcon, toggleMuteUnmuteCall] = useMuteUnmute();
  const [showHoldButton, toggeCallHoldUnhold] = useHoldUnholdCall(true);
  const [timerCall, TimerAction] = useCallTimerDuration();
  const callHangUp = useCallHangup();

  let dialNumber = sessions[sessionId]?.remoteIdentity?.uri?.normal?.user;
  // let dialNumber = 45555;


  useEffect(() => {
    TimerAction('start')
  }, [])

  return (
    <>
      {/* <!-- ACTIVE CALL MAXIMIZE CONTAINER :: STARTS --> */}
      <div className="incoming-call-maximize-container active-call-container active-call-transfer position-relative active-call-attandant-transfer-container attandant-transfer-hold-container callingScreen">
        {/* <!-- Active call heading --> */}
        <div className="p-0 maximize-container-header attandant-header position-relative flex-wrap">
          {/* <!-- Ongoing call label --> */}
          <h5 className="modal-title" id="onGoingCallModalLabel">
            {getObjectCount(sessions) == 1 && "Ongoing call"}
            {getObjectCount(sessions) > 1 && !conferenceCallMerge && "Transfer call"}
            {getObjectCount(sessions) > 1 && conferenceCallMerge && "Conference call"}
          </h5>
          {/*  <!-- MINMIZE CALL POPUP :: STARTS --> */}
          {blindTransferCallBtn || attendantCalltransferBtn || isConferenceCallPressed && <MinimizeCallPopup dialNumber={dialNumber} calltimer={timerCall} />}

        </div>
        {/* <!--CALL CONNECTING INFO :: STARTS--> */}
        <div className="call-connecting-info" id="divCallConnectingInfo">

          {/* <!-- Ongoing call information --> */}
          {getObjectCount(sessions) === 1 && <CallInformation calltimer={timerCall} />}
          {/* <!-- Ongoing call action buttons --> */}

          {/*  ACTIVE CALL ATTANDANT CONTAINER :: STARTS */}
          {getObjectCount(sessions) > 1 && <TransaferCall calltimer={timerCall} />}
          {/*  ACTIVE CALL ATTANDANT CONTAINER :: END */}
        </div>
        <div className="call-connecting-info">
          <CallingActionButton />
        </div>
      </div>

    </>
  );
};

export default OnGoingCall;
