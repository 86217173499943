import React, { useEffect } from 'react'
import { RouterProvider } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import router from './Routes';
import { _changePlatformLanguage } from './utils/Locale';
import { useState } from 'react';
import { getTokenFCM, onMessageListener, sendNotification } from './firebase';
import { Toast } from 'react-bootstrap';
import TostContainerBootstarp from 'react-bootstrap/ToastContainer';

const App = () => {
  const [show, setShow] = useState(false);
  const [notification, setNotification] = useState({ title: '', body: '' });

  onMessageListener().then(payload => {
    setShow(true);
    setNotification({ title: payload.notification.title, body: payload.notification.body, icon: payload.notification.icon })
  }).catch(err => console.log('failed: ', 'dsgvfcv'));




  useEffect(() => {
    if ([null, undefined, ''].includes(localStorage.getItem('fcmtocken'))) {
      getTokenFCM();
    }
  }, [])

  useEffect(() => {
    _changePlatformLanguage();
  })
  return (
    <>
      {/* forground notification show */}
      <TostContainerBootstarp >
        <Toast show={show} onClose={() => setShow(false)} className='custom_toast' animation={true} autohide={true}>
          <Toast.Header className='text-dark'>
            <img src={notification.icon} width={'25px'} height={'25px'} className="rounded me-2" alt="" />
            <strong className="me-auto">{notification.title}</strong>
          </Toast.Header>
          <Toast.Body>{notification.body}</Toast.Body>
        </Toast>
      </TostContainerBootstarp>

      <RouterProvider
        router={router}
        fallbackElement={<>Loadding ..</>}
      />
      <ToastContainer />

    </>

  )
}

export default App