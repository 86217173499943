import React from "react";
import OnGoingCall from "./OnGoingCall";
import { useDispatch, useSelector } from "react-redux";
import VideoCallingScreen from "./VideoCallingScreen";
import TransaferCall from "./TransaferCall";
import { getObjectCount } from "../../utils/Utils";
import { useEffect } from "react";
import { updateSipProperties } from "../../store/sipjs/sipjsSlice";
import useCallHangup from "../../hooks/useCallHangup";
import VideoCallMinimize from "./VideoCallMinimize";

const CallingScreen = ({ calltimer }) => {
  const dispatch = useDispatch();
  const { sessionState, sessions, attendantCalltransferBtn, isConferenceCallPressed, conferenceCallHangup } = useSelector((state) => state.sipjs);
  const { videocall, toggleConnectingCall, minimizeVideoCall } = useSelector(
    (state) => state.theme
  );
  const callHangUp = useCallHangup();

  useEffect(() => {
    if (getObjectCount(sessions) == 1) {
      dispatch(updateSipProperties({ key: "isConferenceCallPressed", value: false }))
      dispatch(updateSipProperties({ key: "conferenceCallMerge", value: false }))
    }
  }, [sessions])
  return (
    <>
      {/* <VideoCallMinimize /> */}
      {/* show audio calling screen */}
      {((sessionState == 'Established' && getObjectCount(sessions) == 1) || getObjectCount(sessions) > 1) && !videocall && <OnGoingCall calltimer={calltimer} />}

      {/* show video calling screen */}
      {videocall && !minimizeVideoCall && <VideoCallingScreen />}
    </>
  );
};

export default CallingScreen;

