import React, { useEffect, useState } from 'react'
import Logo from '../../assets/app-icon/icon/logo.svg'
import { FaUser, FaLock, FaEyeSlash, FaEye } from 'react-icons/fa'
import { HiKey } from 'react-icons/hi'
import { BsQrCodeScan } from 'react-icons/bs'
import { Link, useNavigate } from 'react-router-dom'
import { useFormik } from 'formik'
import { useDispatch, useSelector } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import * as Yup from 'yup';
import { authLogin, getGlobalConfigData } from '../../store/Auth/authService'

const UserLogin = () => {
    const [showpass, setSowpass] = useState(false)
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { user, isLoading, message, isError, isSucess, tocken } = useSelector((state) => state.auth)
    const [showPass, setshowPass] = useState(false);


    const formik = useFormik({
        initialValues: {
            usr_username: '',
            usr_password: '',
        },
        validationSchema: Yup.object({
            usr_username: Yup.string().required("Username is Required"),
            usr_password: Yup.string().required("User Passowrd is Required"),
        }),
        onSubmit: values => {
            const device_token = uuidv4().replaceAll('-', '');
            const device_type = 'web';
            const form_data = {
                ...values,
                "device_token": device_token,
                "device_type": device_type,
                "device_model": navigator.product,
                "device_os": navigator.platform,
                "instance_id": 'dddd' + device_token
            }
            dispatch(authLogin(form_data));

        },
    });

    useEffect(() => {
        if (user) {
            return navigate('/');
        }
    }, [user, tocken]);
    useEffect(() => {
        dispatch(getGlobalConfigData());
    }, [])


    return (
        <>
            <div className="login-left-section">
                <div className="logo">
                    <img src={Logo} alt="LOGO" />
                </div>
                <div className="back hide" id="loginWithSso">
                    <svg width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M14 26L2 14L14 2" stroke="black" strokeWidth="3" strokeLinecap="round" strokeLinejoin="round"></path>
                    </svg>
                    Back
                </div>
                <div className="login-form">
                    <h3 className="heading">Login</h3>
                    <p className="sub-heading">Login with username and password</p>
                    <form onSubmit={formik.handleSubmit}>
                        {/* <!-- Username --> */}
                        <div className="col-lg-12 mb-3">
                            <div className="input-group m-0">
                                <div className="input-group-text border-end-0">
                                    <FaUser className='icon' />
                                </div>
                                <input type="text" className="form-control border-start-0"
                                    placeholder="Username"
                                    name="usr_username"
                                    {...formik.getFieldProps("usr_username")}
                                />
                            </div>
                            {formik.errors.usr_username && formik.touched.usr_username ? (
                                <div className='error_msg'>{formik.errors.usr_username}</div>
                            ) : null}
                        </div>
                        {/* <!-- User Password --> */}
                        <div className="col-lg-12">
                            <div className="input-group m-0">
                                <div className="input-group-text border-end-0">
                                    <FaLock className='icon' />
                                </div>
                                <input type={showpass ? 'text' : 'password'}
                                    name="usr_password"
                                    className="form-control border-end-0 border-start-0"
                                    placeholder="Password"
                                    {...formik.getFieldProps("usr_password")}
                                />
                                {showpass ? <FaEyeSlash className='icon hide-show-password' onClick={() => setSowpass(false)} /> : <FaEye className='icon hide-show-password' onClick={() => setSowpass(true)} />}
                            </div>
                            {formik.errors.usr_password && formik.touched.usr_password ? (
                                <div className='error_msg'>{formik.errors.usr_password}</div>
                            ) : null}
                            <span className="error hide" id="invalidLoginCredentials">This Username or Password is not exists in our records</span>
                        </div>
                        {/* <!-- Forgot password --> */}
                        <div className="col-12">
                            <p className=" forget-password" style={{ textAlign: 'right', marginTop: '10px' }}>Forgot Password? <Link to={'/reset-password'}>Click Here</Link></p>
                        </div>


                        {/* <!-- Login button --> */}
                        <div className="row align-items-center mb-2">
                            <div className="col-md-6 col-12">
                                <button type="submit" className="col-lg-12 col-md-12 col-sm-12 common-btn gradient-btn show" >Login</button>
                            </div>
                            {/* <!-- Login with OTP --> */}
                            <div className="col-md-6 col-12">
                                <Link to='/otp-login' type='button' className="common-btn login-with-qr   primary-btn show" >
                                    <span>
                                        <HiKey className="icon" style={{ color: "white" }} />
                                    </span>
                                    Login with OTP
                                </Link>
                            </div>
                        </div>





                        {/* <!-- Login with QR code --> */}
                        <div className="col-lg-12">
                            <button type="button" className="col-lg-12 col-md-12 col-sm-12 common-btn login-with-qr primary-btn show" id="loginWithQRCode">

                                <span>
                                    <BsQrCodeScan className='icon' style={{ color: "white" }} />
                                </span>
                                Login with QR Code
                            </button>
                        </div>

                        {/* <!-- Login with sso -->
                        <!-- <div className="col-lg-12">
                            <button type="button" className="col-lg-12 col-md-12 col-sm-12 common-btn login-with-qr primary-btn">
                                <span>
                                    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M5.8203 0.838135H2.39506C1.98277 0.840181 1.58795 1.00487 1.29641 1.29641C1.00487 1.58795 0.840181 1.98277 0.838135 2.39506V5.8203C0.840181 6.23259 1.00487 6.62742 1.29641 6.91895C1.58795 7.21049 1.98277 7.37518 2.39506 7.37723H5.8203C6.23259 7.37518 6.62742 7.21049 6.91895 6.91895C7.21049 6.62742 7.37518 6.23259 7.37723 5.8203V2.39506C7.37518 1.98277 7.21049 1.58795 6.91895 1.29641C6.62742 1.00487 6.23259 0.840181 5.8203 0.838135ZM5.50892 5.50892H2.70645V2.70645H5.50892V5.50892ZM5.8203 8.62277H2.39506C1.98277 8.62482 1.58795 8.78951 1.29641 9.08105C1.00487 9.37258 0.840181 9.76741 0.838135 10.1797V13.6049C0.840181 14.0172 1.00487 14.4121 1.29641 14.7036C1.58795 14.9951 1.98277 15.1598 2.39506 15.1619H5.8203C6.23259 15.1598 6.62742 14.9951 6.91895 14.7036C7.21049 14.4121 7.37518 14.0172 7.37723 13.6049V10.1797C7.37518 9.76741 7.21049 9.37258 6.91895 9.08105C6.62742 8.78951 6.23259 8.62482 5.8203 8.62277ZM5.50892 13.2936H2.70645V10.4911H5.50892V13.2936ZM13.6049 0.838135H10.1797C9.76741 0.840181 9.37258 1.00487 9.08105 1.29641C8.78951 1.58795 8.62482 1.98277 8.62277 2.39506V5.8203C8.62482 6.23259 8.78951 6.62742 9.08105 6.91895C9.37258 7.21049 9.76741 7.37518 10.1797 7.37723H13.6049C14.0172 7.37518 14.4121 7.21049 14.7036 6.91895C14.9951 6.62742 15.1598 6.23259 15.1619 5.8203V2.39506C15.1598 1.98277 14.9951 1.58795 14.7036 1.29641C14.4121 1.00487 14.0172 0.840181 13.6049 0.838135ZM13.2936 5.50892H10.4911V2.70645H13.2936V5.50892ZM9.55693 12.3594C9.80468 12.3594 10.0423 12.261 10.2175 12.0858C10.3927 11.9106 10.4911 11.673 10.4911 11.4252V9.55693C10.4911 9.30917 10.3927 9.07157 10.2175 8.89638C10.0423 8.72119 9.80468 8.62277 9.55693 8.62277C9.30917 8.62277 9.07157 8.72119 8.89638 8.89638C8.72119 9.07157 8.62277 9.30917 8.62277 9.55693V11.4252C8.62277 11.673 8.72119 11.9106 8.89638 12.0858C9.07157 12.261 9.30917 12.3594 9.55693 12.3594Z" fill="white" />
                                    </svg>
                                </span>
                                Login with SSO
                            </button>
                        </div> --> */}
                    </form>
                    {/* <!-- Signup --> */}
                    {/* <!-- <div className="col-lg-12 hide">
                        <button type="button" className="col-lg-12 col-md-12 col-sm-12 common-btn secondary-btn signup-button"> Sign Up </button>
                    </div> --> */}
                </div>
            </div>
        </>
    )

}

export default UserLogin