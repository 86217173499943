import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import DialPadeInputNumber from './DialPadeInputNumber'
import DialPadeButtonList from './DialPadeButtonList';
import DialPadeActionButton from './DialPadeActionButton';
import { useSelector } from 'react-redux';

const DialPadeModal = (props) => {
    const [number, setNumber] = useState('');
    const { Sidebar, openDialpad } = useSelector((state) => state.theme);
    useEffect(() => {
        if (!openDialpad) {
            setNumber('')
        }
    }, [openDialpad])
    const typeNumber = (number) => {
        setNumber((prev) => prev.concat(number))
    }
    return (
        <>
            <Modal
                {...props}
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <Modal.Header closeButton>
                    <h5 className="modal-title" id="dialPademodalLabel">New Call</h5>
                    <button type="button" className="btn-close" id="dialPademodalClose" data-bs-dismiss="modal" aria-label="Close">
                        <svg width="22" height="23" viewBox="0 0 22 23" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M11.3328 9.59625L2.41425 0.677734L0.646484 2.4455L9.565 11.364L0.646675 20.2823L2.41444 22.0501L11.3328 13.1318L19.6438 21.4428L21.4115 19.675L13.1005 11.364L21.4117 3.05281L19.644 1.28505L11.3328 9.59625Z" fill="white"></path>
                        </svg>
                    </button>
                    <DialPadeInputNumber dialNum={setNumber} numVal={number} />
                </Modal.Header>
                <Modal.Body>
                    <div className="col-lg-10 col-md-10 col-sm-12 keypad-div">
                        <div className="row digits_box">
                            <DialPadeButtonList typeNumber={typeNumber} />
                            <DialPadeActionButton number={number} setNumber={setNumber} />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default DialPadeModal