import React from 'react'
import Menu from '../Menu'
import { nanoid } from '@reduxjs/toolkit'

const MobileMenu = () => {
    return (
        <>
            <div className='mobile_menu d-block d-md-none'>
                <Menu key={nanoid()} mobileMenu={true} />
            </div>
        </>
    )
}

export default MobileMenu